import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

//Account Redux states
import {
  REGISTER_USER,
  REGISTER_VERIFY_EMAIL,
  RESPONDENT_INVITE_VALIDATE,
  RESPONDENT_REGISTER_USER,
} from './actionTypes';
import {
  registerUserSuccessful,
  registerUserFailed,
  registerVerifyEmailSuccessful,
  registerVerifyEmailFailed,
  respondentInviteValidateSuccessful,
  respondentInviteValidateFailed,
} from './actions';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
//import { postFakeRegister } from '../../../helpers/fakebackend_helper';
import {
  postJwtRegister,
  postVerifyEmail,
  respondentInviteValidate as respondentInviteValidateApi,
  respondentRegisterUser as respondentRegisterUserApi,
} from '../../../helpers/backend_helper';

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* registerUser({
  payload: {
    user: { user, history },
  },
}) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(
        fireBaseBackend.registerUser,
        user.email,
        user.password
      );
      yield put(registerUserSuccessful(response));
      history.push('/login');
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      try {
        const response = yield call(postJwtRegister, user);
        console.log(response);
        yield put(registerUserSuccessful(response.detail));
        history.push('/login');
      } catch (error) {
        console.log(error);
        yield put(registerUserFailed(error));
      }
    }  //  else if (process.env.REACT_APP_API_URL) {
    //   const response = yield call(postFakeRegister, user);
    //   console.log({ response });
    //   if (response.status === 201) {
    //     yield put(registerUserSuccessful(response));
    //     history.push('/login');
    //   } else {
    //     yield put(registerUserFailed(response));
    //   }
    // }
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

function* respondentRegisterUser({ payload }) {
  console.log({ payload });
  try {
    const response = yield call(respondentRegisterUserApi, payload);
    console.log(response);
    yield put(registerUserSuccessful(response.detail));
    payload?.history.push('/login');
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

// Is user register successfull then direct plot user in redux.
function* registerVerifyEmail({ payload: { key } }) {
  try {
    const response = yield call(postVerifyEmail, key);
    console.log(response);
    yield put(registerUserSuccessful(response.detail));
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

function* respondentInviteValidate({ payload }) {
  try {
    const response = yield call(respondentInviteValidateApi, payload);
    yield put(respondentInviteValidateSuccessful());
  } catch (error) {
    yield put(respondentInviteValidateFailed());
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
  yield takeEvery(RESPONDENT_REGISTER_USER, respondentRegisterUser);
  yield takeEvery(REGISTER_VERIFY_EMAIL, registerVerifyEmail);
  yield takeEvery(RESPONDENT_INVITE_VALIDATE, respondentInviteValidate);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
