import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  RESET_LOGIN_FLAG,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_MAX_TRADE_DATE,
  GET_MAX_TRADE_DATE_SUCCESS,
  AUTH_USER_SUCCESS,
  RESET_LOGIN_FLAG_ERROR,
} from "./actionTypes";

// CHAD AUTH
export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user, 
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const authUserSuccess = (user) => {
  return {
    type: AUTH_USER_SUCCESS,
    payload: user,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  };
};

export const resetLoginFlag = () => {
  return {
    type: RESET_LOGIN_FLAG,
  };
};

export const resetLoginFlagError = () => {
  return {
    type: RESET_LOGIN_FLAG_ERROR,
  };
};

export const getCountryList = (params) => {
  return {
    type: GET_COUNTRY_LIST,
    payload: params,
  };
};

export const getCountryListSuccess = (countries) => {
  return {
    type: GET_COUNTRY_LIST_SUCCESS,
    payload: countries,
  };
};

export const getMaxTradeDate = (params) => {
  return {
    type: GET_MAX_TRADE_DATE,
    payload: params,
  };
};

export const getMaxTradeDateSuccess = (date) => {
  return {
    type: GET_MAX_TRADE_DATE_SUCCESS,
    payload: parseInt(date),
  };
};
