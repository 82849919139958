import {
  GET_TRADE_LIST,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_TRADE_SUCCESS,
  ADD_TRADE_FAIL,
  UPDATE_TRADE_SUCCESS,
  UPDATE_TRADE_FAIL,
  DELETE_TRADE_SUCCESS,
  DELETE_TRADE_FAIL,
} from './actionType';

const INIT_STATE = {
  tradeList: [],
  tradeNext: null,
  tradePrev: null,
  tradeListCount: null,
  isTradeSuccess: false,
  tradeListNumPages: null,
  tradesLoading: false,
};

const Trade = (state = INIT_STATE, action) => {
  //console.log(action.payload);
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_TRADE_LIST:
          console.log('action get_trade_list', action.payload);
          return {
            ...state,
            tradeList: action.payload.data.results,
            tradeNext: parseInt(
              action.payload.data.next?.split('page=')[1]?.split('&')[0]
            ),
            tradePrev: parseInt(
              action.payload.data.previous?.split('page=')[1]?.split('&')[0]
            ),
            tradeListCount: action.payload.data.count,
            tradeListNumPages: action.payload.data.num_pages,
            isTradeCreated: false,
            isTradeDelete: false,
            isTradeSuccess: true,
            tradesLoading: false,
          };

        default:
          return { ...state };
      }

    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_TRADE_LIST:
          return {
            ...state,
            error: action.payload.error,
            isTradeCreated: false,
            isTradeDelete: false,
            isTradeSuccess: false,
          };

        default:
          return { ...state };
      }

    case GET_TRADE_LIST: {
      
      return {
        ...state,
        isTradeCreated: false,
        isTradeDelete: false,
        tradesLoading: true,
        
      };
    }

    case ADD_TRADE_SUCCESS:
      return {
        ...state,
        isTradeCreated: true,
        tradeList: [...state.tradeList, action.payload],
        isTradeAdd: true,
        isTradeAddFail: false,
      };

    case ADD_TRADE_FAIL:
      return {
        ...state,
        error: action.payload,
        isTradeAdd: false,
        isTradeAddFail: true,
      };

    case UPDATE_TRADE_SUCCESS:
      return {
        ...state,
        tradeList: state.tradeList.map((trade) =>
          trade.trade_id.toString() === action.payload.trade_id.toString()
            ? { ...trade, ...action.payload }
            : trade
        ),
        isTradeUpdate: true,
        isTradeUpdateFail: false,
      };

    case UPDATE_TRADE_FAIL:
      return {
        ...state,
        error: action.payload,
        isTradeUpdate: false,
        isTradeUpdateFail: true,
      };

    case DELETE_TRADE_SUCCESS:
      console.log('action.payload', action.payload.trade.trade_id.toString());
      return {
        ...state,
        tradeList: state.tradeList.filter(
          (trade) =>
            trade.trade_id.toString() !==
            action.payload.trade.trade_id.toString()
        ),
        isTradeDelete: true,
        isTradeDeleteFail: false,
      };

    case DELETE_TRADE_FAIL:
      return {
        ...state,
        error: action.payload,
        isTradeDelete: false,
        isTradeDeleteFail: true,
      };

    default:
      return { ...state };
  }
};

export default Trade;
