import {
  GET_BUYER_LIST,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_BUYER_SUCCESS,
  ADD_BUYER_FAIL,
  UPDATE_BUYER_SUCCESS,
  UPDATE_BUYER_FAIL,
  DELETE_BUYER_SUCCESS,
  DELETE_BUYER_FAIL,
  GET_SECTOR_LIST
} from './actionType';

const INIT_STATE = {
  buyerList: [],
  buyerNext: null,
  buyerPrev: null,
  buyerListCount: null,
  buyerListNumPages: null,
  newBuyer: null,
  sectors: [],
};

const Buyer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_BUYER_LIST:
          console.log(action.payload);
          return {
            ...state,
            buyerList: action.payload.data.results,
            buyerNext: parseInt(
              action.payload.data.next?.split('page=')[1]?.split('&')[0]
            ),
            buyerPrev: parseInt(
              action.payload.data.previous?.split('page=')[1]?.split('&')[0]
            ),
            buyerListCount: action.payload.data.count,
            buyerListNumPages: action.payload.data.num_pages,
            isBuyerCreated: false,
            isBuyerSuccess: true,
          };

        case GET_SECTOR_LIST:
          return {
            ...state,
            sectors: action.payload.data.results,
          };

        default:
          return { ...state };
      }

    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_BUYER_LIST:
          return {
            ...state,
            error: action.payload.error,
            isBuyerCreated: false,
            isBuyerSuccess: false,
          };

        default:
          return { ...state };
      }

    case GET_BUYER_LIST: {
      return {
        ...state,
        isBuyerCreated: false,
      };
    }

    case ADD_BUYER_SUCCESS:
      return {
        ...state,
        isBuyerCreated: true,
        buyerList: [...state.buyerList, action.payload],
        newBuyer: action.payload,
        isBuyerAdd: true,
        isBuyerAddFail: false,
      };

    case ADD_BUYER_FAIL:
      return {
        ...state,
        error: action.payload,
        newBuyer: null,
        isBuyerAdd: false,
        isBuyerAddFail: true,
      };

    case UPDATE_BUYER_SUCCESS:
      return {
        ...state,
        buyerList: state.buyerList.map((buyer) =>
          buyer.buyer_id.toString() === action.payload.buyer_id.toString()
            ? { ...buyer, ...action.payload }
            : buyer
        ),
        isBuyerUpdate: true,
        isBuyerUpdateFail: false,
      };

    case UPDATE_BUYER_FAIL:
      return {
        ...state,
        error: action.payload,
        isBuyerUpdate: false,
        isBuyerUpdateFail: true,
      };

    case DELETE_BUYER_SUCCESS:
      return {
        ...state,
        buyerList: state.buyerList.filter(
          (buyer) =>
            buyer.buyer_id.toString() !==
            action.payload.buyer.buyer_id.toString()
        ),
        isBuyerDelete: true,
        isBuyerDeleteFail: false,
      };

    case DELETE_BUYER_FAIL:
      return {
        ...state,
        error: action.payload,
        isBuyerDelete: false,
        isBuyerDeleteFail: true,
      };

    case GET_SECTOR_LIST: {
        return {
          ...state,
        };
      }

    default:
      return { ...state };
  }
};

export default Buyer;
