import React from 'react';
import {
  Container,
  CardHeader,
  Row,
  Card,
  CardBody, 
} from 'reactstrap'; 
 











const AboutUsPublic = () => {


    
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
        
            <Row>
            <Card>
                <CardHeader className="align-items-center d-flex p-10">
                <h2>About Us</h2>
                </CardHeader>
                <CardHeader className="p-10"><h4>About EM</h4></CardHeader>
                <CardBody className="p-10" style={{maxWidth:'1385px'}}>
                

Ecosystem Marketplace (EM), a non-profit initiative of Forest Trends, is a leading global source of credible information on environmental finance, markets, and payments for ecosystem services. For nearly two decades, EM has run the world’s first and only globally recognized and standardized reporting and transparency platform for carbon market credit pricing data, news, and insights. In 2023, EM launched the Global Carbon Markets Hub, a market intelligence platform to track and analyze carbon markets data. EM is also a leading convener in the sector, bringing together experts to provide technical insights that cover emerging policy and key trends across environmental markets for decision- and policy-makers, market actors and observers.</CardBody>

<CardHeader className="p-10"><h4>About FT</h4></CardHeader>
<CardBody className="p-10" style={{maxWidth:'1385px'}}>
Forest Trends Association (FT) is a 501(c)(3) organization founded in 1999. Forest Trends works to conserve forests and other ecosystems through the creation and wide adoption of a broad range of environmental finance, markets, and other payment and incentive mechanisms. Forest Trends does so by 1) providing transparent information on ecosystem values, finance, and markets through knowledge acquisition, analysis, and dissemination; 2) convening diverse coalitions, partners, and communities of practice to promote environmental values and advance development of new markets and payment mechanisms; and 3) demonstrating successful tools, standards, and models of innovative finance for conservation.
                    
                </CardBody>
                
                
                
                
                
                
                
                </Card>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default AboutUsPublic;
