import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import logoLight from "../../assets/images/logo.svg";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { URL } from "../../constants/urls/urls";

import axios from "axios";


const ForgetPasswordPage = props => {
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      dispatch(userForgetPassword(values, props.history));
    }
  });

  const [email, setEmail] = useState()

  const sendEmail = async () => {
    let data = {"email": email}
    await axios.post(`${URL.forget_password}`, data)
    .then(r => {
      setResponseMessage("Instructions has been sent to your email.")
      setResponse(true)
    })
    .catch(e => { setError(true);
      setResponseError("Sorry for inconvenience, this user is not registered with us")}
      )
  }

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));
  const [response, setResponse] = useState(false)
  const [responseMessage, setResponseMessage] = useState()
  const [responseError, setResponseError] = useState()
  const [Error, setError] = useState(false)
  useEffect(() => {
    setTimeout(() =>{
      if (response == true){
        setResponse(false)
      }
      if(Error == true){
        setError(false)
      }
    },5000)
  }, [response, Error])
document.title ="Reset Password | Ecosystem Marketplace";

const AlertMessage = () =>{
    if(Error){
      return(
        <Alert className="alert-borderless alert-danger text-center mb-2 mx-2" role="alert">
        {responseError}
      </Alert>
      )
    }
    else if(response){
      return(
        <Alert className="alert-borderless alert-success text-center mb-2 mx-2" role="alert">
        {responseMessage} <br/><strong>Link will expire in 10min</strong>
      </Alert>
      )
    }
    else{
      return(
        <Alert className="alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
        Enter your email and instructions will be sent to you
      </Alert>
      )
    }
}



  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="100" />
                  </Link>
                </div> 
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">

                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Forgot Password?</h5>
                    <p className="text-muted">Reset your Ecosystem Marketplace password </p>

                    <lord-icon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      trigger="loop"
                      colors="primary:#0ab39c"
                      className="avatar-xl"
                      style={{ width: "120px", height: "120px" }}
                      >
                    </lord-icon>

                  </div>

                  {AlertMessage()}

                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          // onChange={validation.handleChange}
                          onChange={(e) => setEmail(e.target.value)}
                          onBlur={validation.handleBlur}
                          // value={validation.values.email || ""}
                          value = {email}
                          invalid={
                            validation.touched.email && validation.errors.email
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                        ) : null}
                      </div>

                      <div className="text-center mt-4">
                        <button className="btn btn-success w-100" onClick={sendEmail}>Send Reset Link</button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0">Wait, I remember my password... <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Click here </Link> </p>
              </div>

            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
