module.exports = {
  google: {
    API_KEY: '',
    CLIENT_ID: '',
    SECRET: '',
  },
  facebook: {
    APP_ID: '',
  },
  api: {
    API_URL: 'https://hub-api.ecosystemmarketplace.com',
    //API_URL: 'http://127.0.0.1:8000',
    //API_URL: 'https://staging-api.ecosystemmarketplace.com',
    // API_URL: 'http://localhost:3000',
  },
};
