import { call, put, takeEvery, all, fork } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Buyer Redux States
import {
  GET_BUYER_LIST,
  GET_SECTOR_LIST,
  ADD_NEW_BUYER,
  DELETE_BUYER,
  UPDATE_BUYER,
} from './actionType';
import {
  BuyerApiResponseSuccess,
  BuyerApiResponseError,
  addBuyerSuccess,
  addBuyerFail,
  updateBuyerSuccess,
  updateBuyerFail,
  deleteBuyerSuccess,
  deleteBuyerFail,
} from './action';

//Include Both Helper File with needed methods
import {
  getBuyerList as getBuyerListApi,
  addNewBuyer,
  updateBuyer,
  deleteBuyer,
  getSectorList as getSectorListApi,
} from '../../helpers/backend_helper';

function* getBuyerList(params) {
  try {
    //console.log(params.payload);
    const response = yield call(getBuyerListApi, params.payload);
    //console.log(response);
    yield put(BuyerApiResponseSuccess(GET_BUYER_LIST, response));
  } catch (error) {
    console.log(error);
    yield put(BuyerApiResponseError(GET_BUYER_LIST, error));
  }
}

function* getSectorList(params) {
  try {
    console.log(params.payload);
    const response = yield call(getSectorListApi, params.payload);
    yield put(BuyerApiResponseSuccess(GET_SECTOR_LIST, response));
  } catch (error) {
    console.log(error);
    yield put(BuyerApiResponseError(GET_SECTOR_LIST, error));
  }
}

function* onAddNewBuyer({ payload: buyer }) {
  try {
    const response = yield call(addNewBuyer, buyer);
    yield put(addBuyerSuccess(response));
    toast.success('Buyer Added Successfully', { autoClose: 3000 });
  } catch (error) {
    yield put(addBuyerFail(error.data));
    toast.error('Failed to add buyer.', { autoClose: 3000 });
  }
}

function* onDeleteBuyer({ payload: buyer }) {
  try {
    const response = yield call(deleteBuyer, buyer);
    yield put(deleteBuyerSuccess({ buyer }));
    toast.success('Buyer Delete Successfully', { autoClose: 3000 });
  } catch (error) {
    console.log(error.data.error);
    yield put(deleteBuyerFail(error));
    console.log(error);
    toast.error(error.data.error, { autoClose: 8000 });
  }
}

function* onUpdateBuyer({ payload: buyer }) {
  try {
    const response = yield call(updateBuyer, buyer);
    yield put(updateBuyerSuccess(response));
    toast.success('Buyer Updated Successfully', { autoClose: 3000 });
  } catch (error) {
    yield put(updateBuyerFail(error));
    toast.error('Buyer Updated Failed', { autoClose: 3000 });
  }
}

export function* watchGetBuyerList() {
  yield takeEvery(GET_BUYER_LIST, getBuyerList);
}

export function* watchGetSectorList() {
  yield takeEvery(GET_SECTOR_LIST, getSectorList);
}

export function* watchAddNewBuyer() {
  yield takeEvery(ADD_NEW_BUYER, onAddNewBuyer);
}

export function* watchUpdateBuyer() {
  yield takeEvery(UPDATE_BUYER, onUpdateBuyer);
}

export function* watchDeleteBuyer() {
  yield takeEvery(DELETE_BUYER, onDeleteBuyer);
}

function* buyerSaga() {
  yield all([
    fork(watchGetBuyerList),
    fork(watchGetSectorList),
    fork(watchAddNewBuyer),
    fork(watchUpdateBuyer),
    fork(watchDeleteBuyer),
  ]);
}

export default buyerSaga;
