export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const API_ERROR = "LOGIN_API_ERROR";

export const AUTH_USER = "AUTH_USER";
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";

export const SOCIAL_LOGIN = "SOCIAL_LOGIN";

export const RESET_LOGIN_FLAG = "RESET_LOGIN_FLAG";
export const RESET_LOGIN_FLAG_ERROR = "RESET_LOGIN_FLAG_ERROR";

export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS";

export const GET_MAX_TRADE_DATE = "GET_MAX_TRADE_DATE";
export const GET_MAX_TRADE_DATE_SUCCESS = "GET_MAX_TRADE_DATE_SUCCESS";