import React, { useEffect, useState } from 'react';
import { URL } from '../../../constants';
import axios from 'axios';
import { CardHeader, Col, Row } from 'reactstrap'; 
import Highcharts from 'highcharts'
import HighchartsMap from 'highcharts/modules/map'
import HighchartsReact from 'highcharts-react-official'
import topology from '@highcharts/map-collection/custom/world.geo.json'

HighchartsMap(Highcharts);
Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
});


const VolumeByCountryPrivate = () => {

    const [mapData, setMapData] = useState([]);
    const [dataReady, setDataReady] = useState(false);
    const [years, setYears] = useState([]);
    const [yearMin, setYearMin] = useState(0);
    const [yearMax, setYearMax] = useState(0);

    const categories = ['Agriculture', 'Chemical Proc. / Industrial Mfg.', 'Energy Efficiency / Fuel Switching', 
        'Forestry and Land Use',  'Household / Community Devices', 'Renewable Energy', 'Transportation', 'Waste Disposal', 'Other', 'Unspecified'];
    const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];


    let initialMapOptions = {
        chart: {
            map: topology,
            height: '600',
        },
        colors: colors,
        title: {
            text: '',
            align: 'left'
        },
        mapNavigation: {
            enabled: true,
            buttonOptions: {
                verticalAlign: 'bottom'
            }
        },
        // colorAxis: {
        //     min: 60
        // },
        colorAxis: {
            min: 1,
            max: 22000000,
            type: 'logarithmic',
            minColor: '#375F47',
            maxColor: '#C02A27'
        },
        // colorAxis: {
        //     dataClasses: [{
        //         to: 3,
        //         color: '#F4FBFD'
        //     }, {
        //         from: 3,
        //         to: 10,
        //         color: '#DEF1F7'
        //     }, {
        //         from: 10,
        //         to: 30,
        //         color: '#C2E8E0'
        //     }, {
        //         from: 30,
        //         to: 100,
        //         color: '#89D1BD'
        //     }, {
        //         from: 100,
        //         to: 300,
        //         color: '#59B894'
        //     }, {
        //         from: 300,
        //         to: 1000,
        //         color: '#37A163'
        //     }, {
        //         from: 1000,
        //         to: 3000,
        //         color: '#1F7A35'
        //     }, {
        //         from: 3000,
        //         to: 10000,
        //         color: '#0C5C21'
        //     }, {
        //         from: 10000,
        //         color: '#073516'
        //     }]
        // },
        legend: {
            align: 'center', 
            
            verticalAlign: 'top',
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,
            title: {
                'text': 'Volume of Credits Traded (tCO2e)',
            }

        },
        tooltip: {
            valueDecimals: 0,
            valueSuffix: '',
            pointFormat: "{point.y:,.0f}",
        },

        }

    const [mapOptions, setMapOptions] = useState({});

    const updateChart = (initialLoad = false, yearRangeUpdated = false) => {
        if( initialLoad ){
            axios.get(URL.get_resp_volume_country_total).then((response) => {
                
                let volumeByCountry = response.data;
                setMapData(response.data);

                let all_years = [];
                let countries = [];
                let volume_by_country = {};
                let iso3_to_country = {};
                let seriesData = [];
                let min_volume = 1000000;
                let max_volume = 0;

                
                volumeByCountry.forEach((d) => {
                    if (!all_years.includes(d.year_transact)) {
                        all_years.push(d.year_transact);
                    }
                    if (!countries.includes(d.iso3)) {
                        countries.push(d.iso3);
                    }
                    if( ! iso3_to_country[d.iso3] ){
                        iso3_to_country[d.iso3] = d.project_country;
                    }
                    if( ! volume_by_country[d.iso3] ){
                        volume_by_country[d.iso3] = d.total_volume;
                    } else {
                        volume_by_country[d.iso3] += d.total_volume;
                    }
                    if (d.total_volume < min_volume) {
                        min_volume = d.total_volume;
                    }
                    if (d.total_volume > max_volume) {
                        max_volume = d.total_volume;
                    }
                });

                all_years.sort();
                setYearMin(all_years[0]);
                setYearMax(all_years[all_years.length - 1]);
                setYears(all_years);

                countries.forEach((d) => {
                    seriesData.push({
                        code: d,
                        value: volume_by_country[d],
                        country: iso3_to_country[d],
                    });
                });

                let series = [{
                    accessibility: {
                        point: {
                            valueDescriptionFormat: '{xDescription}, {point.value} ' +
                                'volume tco2e'
                        }
                    },
                    animation: {
                        duration: 1000
                    },
                    data: seriesData,
                    joinBy: ['iso-a3', 'code'],
                    dataLabels: {
                        enabled: false,
                        color: '#FFFFFF',
                        format: '{point.country}'
                    },
                    name: 'Volume TCO2e',
                    tooltip: {
                        pointFormat: '<strong>{point.country} ('+yearMin+'-'+yearMax+')</strong><br>Total volume tCO2e: <strong>{point.value:,.0f}</strong>',
                    }
                }];

                
                //initialMapOptions = { ...initialMapOptions, 'colorAxis': { min: min_volume, max: max_volume, type: 'linear', minColor: '#ACCF62', maxColor: '#375F47' } };

                //initialMapOptions = { ...initialMapOptions, 'colorAxis': { min: min_volume, max: max_volume, type: 'linear', minColor: '#F4FBFD', maxColor: '#073516' } };

                initialMapOptions = { ...initialMapOptions, 'colorAxis': { min: min_volume, max: max_volume, type: 'linear', stops: [[0, '#F4FBFD'], [0.5, '#59B894'], [1, '#073516'] ] } };


                let all_map_options = { ...initialMapOptions, series: series };

                setMapOptions(all_map_options);
                setDataReady(true);
   
            });
        } else {

            let countries = [];
            let volume_by_country = {};
            let iso3_to_country = {};
            let seriesData = [];
            let min_volume = 1000000;
            let max_volume = 0;

            
            mapData.forEach((d) => {
                if( d.year_transact >= yearMin && d.year_transact <= yearMax ){
                    if (!countries.includes(d.iso3)) {
                        countries.push(d.iso3);
                    }
                    if( ! iso3_to_country[d.iso3] ){
                        iso3_to_country[d.iso3] = d.project_country;
                    }
                    if( ! volume_by_country[d.iso3] ){
                        volume_by_country[d.iso3] = d.total_volume;
                    } else {
                        volume_by_country[d.iso3] += d.total_volume;
                    }
                    if (d.total_volume < min_volume) {
                        min_volume = d.total_volume;
                    }
                    if (d.total_volume > max_volume) {
                        max_volume = d.total_volume;
                    }
                }
            });


            countries.forEach((d) => {
                seriesData.push({
                    code: d,
                    value: volume_by_country[d],
                    country: iso3_to_country[d],
                });
            });

            let series = [{
                accessibility: {
                    point: {
                        valueDescriptionFormat: '{xDescription}, {point.value} ' +
                            'volume tco2e'
                    }
                },
                animation: {
                    duration: 1000
                },
                data: seriesData,
                joinBy: ['iso-a3', 'code'],
                dataLabels: {
                    enabled: false,
                    color: '#FFFFFF',
                    format: '{point.country}'
                },
                name: 'Volume TCO2e',
                tooltip: {
                    pointFormat: '<strong>{point.country} ('+yearMin+'-'+yearMax+')</strong><br>Total volume tCO2e: <strong>{point.value:,.0f}</strong>',
                }
            }];

            
            //initialMapOptions = { ...initialMapOptions, 'colorAxis': { min: min_volume, max: max_volume, type: 'linear', minColor: '#ACCF62', maxColor: '#375F47' } };

            //initialMapOptions = { ...initialMapOptions, 'colorAxis': { min: min_volume, max: max_volume, type: 'linear', minColor: '#F4FBFD', maxColor: '#073516' } };

            initialMapOptions = { ...initialMapOptions, 'colorAxis': { min: min_volume, max: max_volume, type: 'linear', stops: [[0, '#F4FBFD'], [0.5, '#59B894'], [1, '#073516'] ] } };

            let all_map_options = { ...initialMapOptions, series: series };

            setMapOptions(all_map_options);
            
        }
    };

    useEffect(() => {
        updateChart(true);
    }, []);

    useEffect(() => {
        updateChart(false, true);
    }, [yearMin, yearMax]);

    useEffect(() => {
        
    }, []);

    

       
    

       

        
    

            



    return (
        <React.Fragment>

            
            
            
            <style dangerouslySetInnerHTML={{__html: `
            .highcharts-credits { display:none }
            
            
            `}} />
            <Row>
            <Col className="col-12 col-lg-12"><CardHeader><strong style={{fontSize : "1.4em"}}>Volume by Country {yearMin} - {yearMax}</strong></CardHeader></Col>
            </Row>
            { /* insert years dropdown selector here */ }
            <CardHeader>
                <Row>
                    <Col className="col-12 col-lg-12 dateRangeSelectors">
                        <strong>Date Range: <label style={{ textTransform:"none", marginLeft:"20px", fontWeight: "bold"}}>Year Min</label> </strong>
                        
                        <select id="yearMin" name="yearMin" onChange={(e) => {
                            let selectedYear = e.target.value;
                            console.log('selectedYear', selectedYear);
                            
                            setYearMin(selectedYear);
                        }}>
                            { years.map((year, index) => {
                                if( year <= yearMax ){
                                    return <option key={index} value={year} selected={year == yearMin}>{year}</option>
                                }
                            })}
                            
                        </select>

                        <strong>Year Max </strong>
                        
                        <select id="yearMax" name="yearMax" onChange={(e) => {
                            let selectedYear = e.target.value;
                            console.log('selectedYear', selectedYear);
                            setYearMax(selectedYear);
                        }}>
                            { years.reverse().map((year, index) => {
                                if( year >= yearMin ){
                                    return <option key={index} value={year} selected={year == yearMax}>{year}</option>
                                }
                            })}
                            {years.reverse()}
                            
                        </select>

                        { (yearMin != years[0] || yearMax != years[years.length - 1]) && <><input type="checkbox" id="resetYearRange" name="resetYearRange" value="resetYearRange" onChange={(e) => {
                            if( e.target.checked ){
                                setYearMin(years[0]);
                                setYearMax(years[years.length - 1]);
                                e.target.checked = false;
                            }
                        }} /> Reset Date Range</> }
                    </Col>
                </Row>    

            </CardHeader>

            <Row>
                <Col className="col-12 col-lg-12"><div id="map">
                {dataReady && <HighchartsReact
                    constructorType={'mapChart'}
                    highcharts={Highcharts}
                    options={mapOptions}
                />}</div>
                </Col>
            </Row>

            
        </React.Fragment>
    );


}

export default VolumeByCountryPrivate;