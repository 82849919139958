import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import TradeSaga from "./trades/saga";
import BuyerSaga from "./buyer/saga";
import ProjectSaga from "./project/saga";
import RespondentSaga from "./respondent/saga";
import respondentUserSaga from "./respondentUser/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(TradeSaga),
    fork(BuyerSaga),
    fork(ProjectSaga),
    fork(RespondentSaga),
    fork(respondentUserSaga),
  ]);
}
