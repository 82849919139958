function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '';
const ROOTS_HOME = '/';
const ROOTS_TRADE = '/trade';
const ROOTS_BUYER = '/buyer';
const ROOTS_PROJECT = '/project';
const ROOTS_RESPONDENT = '/respondent';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  resetPasswordConfirm: path(
    ROOTS_AUTH,
    '/password-reset-confirm/:uid/:token/'
  ),
  verify: path(ROOTS_AUTH, '/verify'),
  accountConfirm: path(ROOTS_AUTH, '/account-confirm-email/:key'),
};

export const PATH_HOME = {
  root: ROOTS_HOME,
  dashboard: path(ROOTS_HOME, ''),
};

export const PATH_TRADE = {
  root: ROOTS_TRADE,
  list: path(ROOTS_TRADE, '/list'),
};

export const PATH_BUYER = {
  root: ROOTS_BUYER,
  list: path(ROOTS_BUYER, '/list'),
};

export const PATH_PROJECT = {
  root: ROOTS_PROJECT,
  list: path(ROOTS_PROJECT, '/list'),
  import: path(ROOTS_PROJECT, '/import'),
};

export const PATH_RESPONDENT = {
  root: ROOTS_RESPONDENT,
  list: path(ROOTS_RESPONDENT, '/list'),
};

export const PATH_PROJECT_IMPORT = {
  root: ROOTS_PROJECT,
  list: path(ROOTS_PROJECT, '/import'),
};
