import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Buyer Redux States
import {
  GET_RESPONDENT_LIST,
} from "./actionType";
import {
  RespondentApiResponseSuccess,
  RespondentApiResponseError,
} from "./action";

//Include Both Helper File with needed methods
import {
  getRespondentList as getRespondentListApi,
} from "../../helpers/backend_helper";

function* getRespondentList(params) {
    try {
      console.log(params.payload);
      const response = yield call(getRespondentListApi, params.payload);
      console.log(response);
      yield put(RespondentApiResponseSuccess(GET_RESPONDENT_LIST, response));
    } catch (error) {
      console.log(error);
      yield put(RespondentApiResponseError(GET_RESPONDENT_LIST, error));
    }
  }

// function* getRespondentUsersList(params) {
//     try {
//       console.log(params.payload);
//       const response = yield call(getRespondentUsersListApi, params.payload);
//       console.log(response);
//       yield put(RespondentApiResponseSuccess(GET_RESPONDENT_USERS_LIST, response));
//     } catch (error) {
//       console.log(error);
//       yield put(RespondentApiResponseError(GET_RESPONDENT_USERS_LIST, error));
//     }
//   }

  export function* watchGetRespondentList() {
    yield takeEvery(GET_RESPONDENT_LIST, getRespondentList);
  }

  // export function* watchGetRespondentUsersList() {
  //   yield takeEvery(GET_RESPONDENT_USERS_LIST, getRespondentUsersList);
  // }

  function* respondentSaga() {
    yield all([
      fork(watchGetRespondentList),
      // fork(watchGetRespondentUsersList),
    ]);
  }
  
  export default respondentSaga;