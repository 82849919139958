// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_PROJECT_LIST = "GET_PROJECT_LIST";
export const GET_PROJECT = "GET_PROJECT";

/**
 * Add PROJECT
 */
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_FAIL = "ADD_PROJECT_FAIL";

/**
 * Edit PROJECT
 */
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL";

/**
 * Delete PROJECT
 */
export const DELETE_PROJECT = "DELETE_PROJECT";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL";

export const GET_TYPE_LIST = "GET_TYPE_LIST";
export const GET_STANDARD_LIST = "GET_STANDARD_LIST";
export const GET_COBENEFIT_LIST = "GET_COBENEFIT_LIST";
export const GET_SDG_LIST = "GET_SDG_LIST";

/**
 * Set PROJECT Loading
 */

export const SET_PROJECT_LOADING = "SET_PROJECT_LOADING";
