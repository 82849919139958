import React, { useState, useEffect, useMemo, useCallback } from 'react';
import TableContainer from '../../../Components/Common/TableContainer';
import DeleteModal from '../../../Components/Common/DeleteModal';

// Import Scroll Bar - SimpleBar
import SimpleBar from 'simplebar-react';

//Import Flatepicker
import Flatpickr from 'react-flatpickr';

//redux
import { useSelector, useDispatch } from 'react-redux';
import {
  Col,
  Modal,
  ModalBody,
  Row,
  Label,
  Input,
  Button,
  ModalHeader,
  FormFeedback,
  Form,
} from 'reactstrap';

import {
  addNewBuyer,
  getCountryList,
  updateBuyer,
  getSectorList,
} from '../../../store/actions';

// Formik
import * as Yup from 'yup';
import { useFormik } from 'formik';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { getRespondentList } from '../../../helpers/backend_helper';

const ProfitStatusOptions = [
  {
    label: 'For profit / Private sector',
    value: 'For profit / Private sector',
  },
  { label: 'Not-for-profit / NGO', value: 'Not-for-profit / NGO' },
  { label: 'Public sector / Government', value: 'Public sector / Government' },
  { label: 'University / Academic', value: 'University / Academic' },
  { label: 'Other', value: 'Other' },
];

const ProfitStatusDescriptionsOptions = [
  { label: 'Small to medium enterprise', value: 'Small to medium enterprise' },
  { label: 'Medium to Large enterprise', value: 'Medium to Large enterprise' },
  { label: 'Multinational corporation', value: 'Multinational corporation' },
  { label: 'Domestic corporation', value: 'Domestic corporation' },
  { label: 'Local/Municipal/County', value: 'Local/Municipal/County' },
  { label: 'State/Regional/Provincial', value: 'State/Regional/Provincial' },
  { label: 'Development multilateral', value: 'Development multilateral' },
  { label: 'Other', value: 'Other' },
];


const BuyerForm = ({
  isEdit,
  currentBuyer,
  modal,
  setModal,
  toggle,
  onClosed,
}) => {
  const dispatch = useDispatch();


  const { isBuyerCreated, isBuyerSuccess, error, countries, user, sectors, sector_ids, newBuyer } =
    useSelector((state) => ({
      user: state.Login.user,
      isBuyerCreated: state.Buyer.isBuyerCreated,
      isBuyerSuccess: state.Buyer.isBuyerSuccess,
      error: state.Buyer.error,
      countries: state.Login.countries,
      sectors:
      state.Buyer.sectors
        .map((el) => ({ value: el.sector_name, label: el.sector_name }))
        .sort((a, b) => (a.value > b.value ? 1 : -1)) || [],
      sector_ids:
      state.Buyer.sectors.map((sector) => ({ value: sector.sector_name, sector_id: sector.sector_id})) || [],
      newBuyer: state.Buyer.newBuyer,
    }));

  

  const [loading, setLoading] = useState(false);
  const [respondentData, setRespondentData] = useState([
    {
      label: user.respondent_name,
      value: user.respondent_id,
    },
  ]);

  useEffect(() => {
    if (user.is_superuser) {
      setLoading(true);
      getRespondentList({ limit: 100 })
        .then((res) => {
          const respondent = res.results.map(
            ({ respondent_name, respondent_id }) => ({
              label: respondent_name,
              value: respondent_id,
            })
          );
          setRespondentData(respondent);
          setLoading(false);
        })
        .catch((e) => {
          console.log('🔥🍊🍉 ERROR ', e);
        });
    }
  }, [user.is_superuser]);
  const BuyerSchema = Yup.object({
    // buyer_name: Yup.string().required('Please Enter Name'),
    // buyer_profit_status: Yup.string().required('Please Enter Creater Name'),
    // buyer_profit_status_description: Yup.string().required(
    //   'Please Enter Status'
    // ),
    // buyer_sector: Yup.string().required('Please Select Sector'),
    // buyer_sector_rel: Yup.number(),
    // country: Yup.number().required('Please Select Country'),
    buyer_name: Yup.string(),
    buyer_profit_status: Yup.string(),
    buyer_profit_status_description: Yup.string(),
    buyer_sector: Yup.string(),
    buyer_sector_rel: Yup.number(),
    country: Yup.number(),
  });

  const getSectorID = ( sectorName ) => {
    let sectorID = 0;
    if( sectorName ){
      sector_ids.forEach(  (sector) => { if(sector.value == sectorName) { sectorID = sector.sector_id } }  );
      //console.log('sectorid', sectorID);
      if( sectorID != 0 ){
        return sectorID;
      }
    }
    return null;
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      respondent_id: user.respondent_id,
      buyer_name: (currentBuyer && currentBuyer.buyer_name) || '',
      buyer_profit_status:
        (currentBuyer && currentBuyer.buyer_profit_status) || '',
      buyer_profit_status_description:
        (currentBuyer && currentBuyer.buyer_profit_status_description) || '',
      buyer_sector: currentBuyer?.buyer_sector || '',
      buyer_sector_rel: currentBuyer?.buyer_sector_rel_id || '',
      country: currentBuyer?.country || '',
    },

    validationSchema: BuyerSchema,

    onSubmit: (values) => {
      hideBuyerInfoMessage();
      //console.log(isEdit);
      if (isEdit) {
        //console.log('Updating..');
        const updatedBuyer = {
          respondent_id: values.respondent_id,
          buyer_id: currentBuyer ? currentBuyer.buyer_id : 0,
          buyer_name: values.buyer_name,
          country: values.country,
          buyer_profit_status: values.buyer_profit_status,
          buyer_profit_status_description:
            values.buyer_profit_status_description,
          buyer_sector: values.buyer_sector,
          buyer_sector_rel: getSectorID(values.buyer_sector) || '', 
        };
        // update customer
        dispatch(updateBuyer(updatedBuyer));
        setModal(false);
        onClosed();
        validation.resetForm();
      } else {
        if( values['buyer_name'] == '' && values['buyer_sector'] == '' && values['country'] == '' && values['buyer_profit_status'] == '' && values['buyer_profit_status_description'] == '' ){
          // if all fields are empty, do not add new buyer
          showBuyerInfoMessage();
        } else {
          const theNewBuyer = {
            respondent: values['respondent_id'],
            buyer_name: values['buyer_name'],
            country: values['country'],
            buyer_profit_status: values['buyer_profit_status'],
            buyer_profit_status_description:
              values['buyer_profit_status_description'],
            buyer_sector: values['buyer_sector'],
            buyer_sector_rel: getSectorID(values['buyer_sector']) || '',
          };

          // save new customer
          dispatch(addNewBuyer(theNewBuyer));
          setModal(false);
          onClosed();
          validation.resetForm();
          //onNewBuyer();
        }
      }
    },
  });

  // Node API
  // useEffect(() => {
  //   if (isBuyerCreated) {
  //     setBuyer(null);
  //     dispatch(getBuyerList());
  //   }
  // }, [
  //   dispatch,
  //   isBuyerCreated,
  // ]);

  useEffect(() => {
    // Passed the limit  9999 to get all countries
    dispatch(getCountryList({ limit: 9999 }));
    dispatch(getSectorList({ limit: 200 }));

  }, []);

  const onSelectChange = (e, name) => {
    validation.setFieldValue(name, e.value);
  };

  const selectValue = (option, options) => {
    return options.find((el) => el.value === option);
  };

  const showBuyerInfoMessage = () => {
    const buyerInfoMessage = document.getElementById('buyer-info-message');
    buyerInfoMessage.classList.remove('d-none');
  }

  const hideBuyerInfoMessage = () => {
    const buyerInfoMessage = document.getElementById('buyer-info-message');
    buyerInfoMessage.classList.add('d-none');
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size='lg'
        className='border-0'
        modalClassName='modal fade zoomIn'>
        <ModalHeader className='p-3 bg-soft-info' toggle={toggle}>
          {!!isEdit ? 'Edit Buyer' : 'Create Buyer'}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}>
          <ModalBody className='modal-body'>
            <Row className='g-3'>
              <Col lg={12}>
                <div>
                  <Label for='buyer_profit_status' className='form-label'>
                    Respondent
                  </Label>
                  <Select
                    isLoading={loading}
                    isDisabled={!user.is_superuser}
                    value={selectValue(
                      validation.values.respondent_id,
                      respondentData
                    )}
                    onChange={(e) => onSelectChange(e, 'respondent_id')}
                    options={respondentData}
                    name='respondent_id'
                    id='respondent_id'
                    classNamePrefix='select2-selection form-select'
                  />
                  {validation.touched.respondent_id &&
                  validation.errors.respondent_id ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.respondent_id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={12}>
                <Label for='buyerName-field' className='form-label'>
                  Buyer Name
                </Label>
                <Input
                  name='buyer_name'
                  id='buyerName-field'
                  className='form-control'
                  placeholder='Buyer name'
                  type='text'
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.buyer_name || ''}
                  invalid={
                    validation.touched.buyer_name &&
                    validation.errors.buyer_name
                  }
                />
                {validation.touched.buyer_name &&
                  validation.errors.buyer_name && (
                    <FormFeedback type='invalid'>
                      {validation.errors.buyer_name}
                    </FormFeedback>
                  )}
              </Col>
              <Col lg={12}>
                <div>
                  <Label for='buyer_profit_status' className='form-label'>
                    Profit Status
                  </Label>
                  <Select
                    value={selectValue(
                      validation.values.buyer_profit_status,
                      ProfitStatusOptions
                    )}
                    onChange={(e) => onSelectChange(e, 'buyer_profit_status')}
                    options={ProfitStatusOptions}
                    name='buyer_profit_status'
                    id='buyer_profit_status'
                    classNamePrefix='select2-selection form-select'
                  />
                  {validation.touched.buyer_profit_status &&
                  validation.errors.buyer_profit_status ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.buyer_profit_status}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={12}>
                <div>
                  <Label
                    for='buyer_profit_status_description'
                    className='form-label'>
                    Profit Status Description
                  </Label>
                  <Select
                    value={selectValue(
                      validation.values.buyer_profit_status_description,
                      ProfitStatusDescriptionsOptions
                    )}
                    onChange={(e) =>
                      onSelectChange(e, 'buyer_profit_status_description')
                    }
                    options={ProfitStatusDescriptionsOptions}
                    name='buyer_profit_status_description'
                    id='buyer_profit_status_description'
                    classNamePrefix='select2-selection form-select'
                  />
                  {validation.touched.buyer_profit_status_description &&
                    validation.errors.buyer_profit_status_description && (
                      <FormFeedback type='invalid'>
                        {validation.errors.buyer_profit_status_description}
                      </FormFeedback>
                    )}
                </div>
              </Col>
              <Col lg={12}>
                <div>
                  <Label for='sector-field' className='form-label'>
                      Sector
                  </Label>
                  <Select
                  value={selectValue(
                    validation.values.buyer_sector,
                    sectors ||
                      []
                  )}
                  onChange={(e) => onSelectChange(e, 'buyer_sector')}
                  options={sectors}
                  name='buyer_sector'
                  id='buyer_sector'
                  classNamePrefix='select2-selection form-select'
                />
                {validation.touched.buyer_sector && validation.errors.buyer_sector && (
                  <FormFeedback type='invalid'>
                    {validation.errors.buyer_sector}
                  </FormFeedback>
                )}



                  {/* <Label for='sector-field' className='form-label'>
                    Sector
                  </Label>
                  <Input
                    name='buyer_sector'
                    id='sector-field'
                    className='form-control'
                    placeholder='Profit Sector'
                    type='text'
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.buyer_sector || ''}
                    invalid={
                      validation.touched.buyer_sector &&
                      validation.errors.buyer_sector
                    }
                  />
                  {validation.touched.buyer_sector &&
                    validation.errors.buyer_sector && (
                      <FormFeedback type='invalid'>
                        {validation.errors.buyer_sector}
                      </FormFeedback>
                    )} */}
                </div>
              </Col>

              <Col lg={12}>
                <Label for='country-field' className='form-label'>
                  Country or Region
                </Label>
                <Select
                  value={selectValue(
                    validation.values.country,
                    countries.map((el) => ({ value: el.id, label: el.name })) ||
                      []
                  )}
                  onChange={(e) => onSelectChange(e, 'country')}
                  options={countries.map((el) => ({
                    value: el.id,
                    label: el.name,
                  }))}
                  name='country'
                  id='country'
                  classNamePrefix='select2-selection form-select'
                />
                {validation.touched.country && validation.errors.country && (
                  <FormFeedback type='invalid'>
                    {validation.errors.country}
                  </FormFeedback>
                )}
              </Col>
            </Row>
          </ModalBody>
          <div className='modal-footer'>
            <div className='hstack gap-2 justify-content-end'>
              <div id='buyer-info-message' className='flex-grow-1 d-none text-danger p-3 pt-0 pb-0'><strong>Please provide some information about this buyer.</strong></div>
              <Button
                type='button'
                onClick={() => {
                  setModal(false);
                }}
                className='btn-light'>
                Close
              </Button>
              <button type='submit' className='btn btn-success' id='add-btn'>
                {!!isEdit ? 'Update Buyer' : 'Add Buyer'}
              </button>
            </div>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default BuyerForm;
