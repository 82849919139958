import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  usePagination,
  useRowSelect,
  useSortBy,
  useFilters,
} from 'react-table';
import { Table, Row, Col, Button, Input, Spinner } from 'reactstrap';
import ReactColumnFilter from './ReactColumnFilter';

const ReactTable = ({
  total,
  tableStyles = {},
  columns,
  data,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  onItemClick,
  loading = false,
  withPagination = true,
  isNextPage,
  isPrevPage,
  nextHandler,
  prevHandler,
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: ReactColumnFilter,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        // sortBy: [
        //   {
        //     desc: true,
        //   },
        // ],
      },
    },

    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' ' : '') : '';
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Fragment>
      <div className={divClass}>
        <Table
          hover
          {...getTableProps()}
          className={tableClass}
          style={tableStyles}>
          <thead className={theadClass}>
            {headerGroups.map((headerGroup) => (
              <tr
                className={trClass}
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    className={thClass}
                    // {...column.getSortByToggleProps()}
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                      },
                    })}>
                    {column.render('Header')}
                    {/* {generateSortingIndicator(column)} */}
                    {column.canFilter ? column.render('Filter') : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {!loading && (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                                ...(cell.column.cellStyle || {}),
                              },
                            })}
                            onClick={() => onItemClick && onItemClick(row)}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          )}
        </Table>
        {loading && (
          <div className='w-100 d-flex justify-content-center'>
            <Spinner />
          </div>
        )}
      </div>

      {withPagination && (
        <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
          {data && <Col>Total Results : {data?.length} </Col>}
          <Col className='col-md-auto'>
            <div className='d-flex gap-1'>
              <Button
                color='primary'
                onClick={previousPage}
                disabled={!canPreviousPage}>
                {'<'}
              </Button>
            </div>
          </Col>
          <Col className='col-md-auto d-none d-md-block'>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>
          <Col className='col-md-auto'>
            <Input
              type='number'
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput}
            />
          </Col>
          <Col className='col-md-auto'>
            <div className='d-flex gap-1'>
              <Button
                color='primary'
                onClick={nextPage}
                disabled={!canNextPage}>
                {'>'}
              </Button>
            </div>
          </Col>
          {!isPrevPage || isPrevPage === null || isNaN(isPrevPage) ? null : (
            <Col className='col-md-auto'>
              <div className='d-flex gap-1'>
                <Button color='primary' onClick={prevHandler}>
                  Prev
                </Button>
              </div>
            </Col>
          )}
          {!isNextPage || isNextPage === null || isNaN(isNextPage) ? null : (
            <Col className='col-md-auto'>
              <div className='d-flex gap-1'>
                <Button color='primary' onClick={nextHandler}>
                  Next
                </Button>
              </div>
            </Col>
          )}
        </Row>
      )}
    </Fragment>
  );
};

ReactTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default ReactTable;
