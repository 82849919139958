import React, { useEffect, useState, useRef, useMemo } from 'react';
import Select from 'react-select';
import { multiply, validate } from 'js-big-decimal';
import { debounce } from 'lodash';
//redux
import { useSelector, useDispatch, batch } from 'react-redux';
import {
  Col,
  Modal,
  ModalBody,
  Row,
  Label,
  Input,
  Button,
  ModalHeader,
  FormFeedback,
  Form,
  Spinner,
} from 'reactstrap';

import Cleave from 'cleave.js/react';

import { 
  addNewTrade,
  updateTrade,
  getProjectList,
  getBuyerList,
  getCountryList,
  getTypeList,
  getStandardList,
  getCoBenefitList,
  getSDGList, 
} from '../../../store/actions';

// Formik
import * as Yup from 'yup';
import { Field, Formik, useFormik } from 'formik';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
//import { api } from '../../../config';
import axios from 'axios';

import { URL } from '../../../constants';
import { withRouter, useParams } from 'react-router-dom';
import { TRADE_API } from '../../../helpers/url_helper';
import { APIClient } from '../../../helpers/api_helper';
import { CSVLink, CSVDownload } from "react-csv";
import config from '../../../config';

const api_url = config.api.API_URL;

const api = new APIClient();



const MarketClassificationOptions = [
  { label: 'Voluntary Carbon Market', value: 'Voluntary Carbon Market' },
  { label: 'Alberta Emissions Offset System', value: 'Alberta Emissions Offset System' },
  { label: 'Alberta CCIR', value: 'Alberta CCIR' },
  { label: 'Australia Emissions Reduction Fund', value: 'Australia Emissions Reduction Fund' },
  { label: 'Beijing Forestry Offset Mechanism', value: 'Beijing Forestry Offset Mechanism' },
  { label: 'Beijing Parking Offset Crediting Mechanism', value: 'Beijing Parking Offset Crediting Mechanism' },
  { label: 'British Columbia Offset Program', value: 'British Columbia Offset Program' },
  { label: 'California CaT', value: 'California CaT' },
  { label: 'California Compliance Offset Program', value: 'California Compliance Offset Program' },
  { label: 'Clean Development Mechanism', value: 'Clean Development Mechanism' },
  { label: 'China GHG Voluntary Emission Reduction Program', value: 'China GHG Voluntary Emission Reduction Program' },
  { label: 'Chongqing Crediting Mechanism', value: 'Chongqing Crediting Mechanism' },
  { label: 'Colombia carbon tax', value: 'Colombia carbon tax' },
  { label: 'CORSIA', value: 'CORSIA' },
  { label: 'EU ETS', value: 'EU ETS' },
  { label: 'Fujian Forestry Offset Crediting Mechanism', value: 'Fujian Forestry Offset Crediting Mechanism' },
  { label: 'Guangdong Pu Hui Offset Crediting Mechanism', value: 'Guangdong Pu Hui Offset Crediting Mechanism' },
  { label: 'Indo-Pacific Carbon Offset Scheme', value: 'Indo-Pacific Carbon Offset Scheme' },
  { label: 'J-Credit Scheme', value: 'J-Credit Scheme' },
  { label: 'Joint Crediting Mechanism', value: 'Joint Crediting Mechanism' },
  { label: 'Kazakhstan Crediting Mechanism', value: 'Kazakhstan Crediting Mechanism' },
  { label: 'NZ ETS', value: 'NZ ETS' },
  { label: 'Québec Offset Crediting Mechanism', value: 'Québec Offset Crediting Mechanism' },
  { label: 'Republic of Korea Offset Credit Mechanism', value: 'Republic of Korea Offset Credit Mechanism' },
  { label: 'RGGI CO2 Offset Mechanism', value: 'RGGI CO2 Offset Mechanism' },
  { label: 'Saitama Forest Absorption Certification System', value: 'Saitama Forest Absorption Certification System' },
  { label: 'Saitama Target Setting Emissions Trading System', value: 'Saitama Target Setting Emissions Trading System' },
  { label: 'Saskatchewan GHG Offset Program', value: 'Saskatchewan GHG Offset Program' },
  { label: 'South African Carbon Tax', value: 'South African Carbon Tax' },
  { label: 'Spain FES-CO2 program', value: 'Spain FES-CO2 program' },
  { label: 'Spanish Carbon Fund for a Sustainable Economy', value: 'Spanish Carbon Fund for a Sustainable Economy' },
  { label: 'Switzerland CO2 Attestations Crediting Mechanism', value: 'Switzerland CO2 Attestations Crediting Mechanism' },
  { label: 'Taiwan GHG Offset Management Program', value: 'Taiwan GHG Offset Management Program' },
  { label: 'Thailand Voluntary Emission Reduction Program', value: 'Thailand Voluntary Emission Reduction Program' },
  { label: 'Tokyo Cap-and-Trade Program', value: 'Tokyo Cap-and-Trade Program' },
  { label: 'Compliance - Unspecified or Other', value: 'Compliance - Unspecified or Other' },
  { label: 'Confidential', value: 'Confidential' },

];

const EndUserOfIntermediaryOptions = [
  { label: 'End User', value: 'End User' },
  {
    label: 'Intermediary - does NOT take ownership',
    value: 'Intermediary - does NOT take ownership',
  },
  {
    label: 'Intermediary - takes ownership',
    value: 'Intermediary - takes ownership',
  },
  {
    label: 'Intermediary - unknown ownership',
    value: 'Intermediary - unknown ownership',
  },
  { label: 'Both', value: 'Both' },
  { label: 'Unknown', value: 'Unknown' },
];

const OffsetGoalsOptions = [
  { label: 'Net zero', value: 'Net zero' },
  { label: 'Voluntary net zero', value: 'Voluntary net zero' },
  { label: 'Carbon neutral', value: 'Carbon neutral' },
  {
    label: 'Voluntary carbon neutral goal',
    value: 'Voluntary carbon neutral goal',
  },
  { label: 'Voluntary goal unknown', value: 'Voluntary goal unknown' },
  {
    label: 'Climate neutral agricultural commodity',
    value: 'Climate neutral agricultural commodity',
  },
  { label: 'Not applicable', value: 'Not applicable' },
  { label: 'Other', value: 'Other' },
];

const ReasonNOIDOptions = [
  { label: 'Project ID was Unassigned at Transaction Date', value: 'Project ID was Unassigned at Transaction Date', futureDate: true },
  { label: 'Project ID is Confidential per Buyer Contract', value: 'Project ID is Confidential per Buyer Contract' },
  { label: 'Project ID is Unknown', value: 'Project ID is Unknown', futureDate: true },
  {
    label: 'Project ID does not Yet Exist, Project is Pending Registration',
    value: 'Project ID does not Yet Exist, Project is Pending Registration',
    futureDate: true
  },
  { label: 'Project ID does not Exist, Carbon Standard does not have a registry', 
    value: 'Project ID does not Exist, Carbon Standard does not have a registry' },
  {
    label: 'Project ID does not Exist, Project follows an Internal Methodology',
    value: 'Project ID does not Exist, Project follows an Internal Methodology',
  },
  { label: 'Other', value: 'Other' },
];

const AdditionalCertifications = [
  { label: 'American Tree Farm System', value: 'American Tree Farm System' },
  { label: 'Clean Development Mechanism (CDM)', value: 'Clean Development Mechanism (CDM)'},
  { label: 'Fairtrade Climate Standard', value: 'Fairtrade Climate Standard'},
  { label: 'Fairtrade (excluding Climate Standard)', value: 'Fairtrade (excluding Climate Standard)'},
  { label: 'Forest Stewardship Council', value: 'Forest Stewardship Council'},
  { label: 'Green-e Climate', value: 'Green-e Climate' },
  { label: 'iREC', value: 'iREC' },
  { label: 'ISAE 3000 by SGS', value: 'ISAE 3000 by SGS' },
  { label: 'ISO14064:2', value: 'ISO14064:2'},
  { label: 'ISO9001, ISO 14001, OHSAS18001', value: 'ISO9001, ISO 14001, OHSAS18001'},
  { label: 'Organic', value: 'Organic'},
  { label: 'Other', value: 'Other'},
  { label: 'Programme for the Endorsement of Forest Certification (PEFC)', value: 'Programme for the Endorsement of Forest Certification (PEFC)'},
  { label: 'Rainforest Alliance', value: 'Rainforest Alliance'},
  { label: 'SD Vista', value: 'SD Vista'},
  { label: 'Sustainable Forestry Initiative', value: 'Sustainable Forestry Initiative'},
  { label: 'UK Woodland Assurance Standard', value: 'UK Woodland Assurance Standard'},
];

const TradeTypes = [
  {label: 'Credit transaction', value: 'Credit transaction' },
  {label: 'Offtake Agreement in Project Financing', value: 'Offtake Agreement in Project Financing' },
  {label: 'Embedded in a product/good/service', value: 'Embedded in a product/good/service' },
]

const TransferOrRetireOptions = [
  {label: 'Transfer credits to buyer registry account', value: 'Transfer credits to buyer registry account' },
  {label: 'Retire credits on behalf of buyer', value: 'Retire credits on behalf of buyer' },
]

const CreditIssuanceOptions = [
  {label: 'Pre-/pending/projected issuance credits', value: 'Pre-/pending/projected issuance credits' },
  {label: 'Verified/issued credits', value: 'Verified/issued credits' },
]

const monthOptions = [
  {label: 'Year only', value: 0 },
  {label: '1', value: 1 },
  {label: '2', value: 2 },
  {label: '3', value: 3 },
  {label: '4', value: 4 },
  {label: '5', value: 5 },
  {label: '6', value: 6 },
  {label: '7', value: 7 },
  {label: '8', value: 8 },
  {label: '9', value: 9 },
  {label: '10', value: 10 },
  {label: '11', value: 11 },
  {label: '12', value: 12 },
]

let yearOptions = [];
const currentYear = new Date().getFullYear();
for (let i = currentYear; i >= 2000; i--) {
  yearOptions.push({ label: i, value: i });
}
let quarterOptions = [
  {label: '1', value: 1 },
  {label: '2', value: 2 },
  {label: '3', value: 3 },
  {label: '4', value: 4 },
];
let semesterOptions = [
  {label: '1', value: 1 },
  {label: '2', value: 2 },
];




const clearNumber = (number) => {
  if (typeof number === 'number') {
    return number;
  } else return number && Number(number?.replaceAll(',', ''));
};

const getFormFieldArray = (formValue) => {
  if( formValue === '' || formValue == undefined ){
    return [];
  } else {
    return formValue;
  }
};

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
}

const DownloadTrades = ({
  isEdit,
  currentTrade,
  modal,
  setModal,
  toggle,
  onClosed,
  setCreateUserModal,
  newBuyer
}) => {
  const dispatch = useDispatch();
  const [projectSearch, setProjectSearch] = useState('');

  const { user, Buyer, countries, typeList, standardList, coBenefitList, sdgList, standards } = useSelector((state) => ({
    user: state.Login.user,
    Buyer: state.Buyer,
    countries: state.Login.countries.map((el) => ({ value: el.id, label: el.name })) || [],
    typeList:
      state.Project.typeList.map((el) => ({
        id: el.typeid,
        name: el.typename,
      })).filter(onlyUnique) || [],
    standardList:
      state.Project.standardList
        .map((el) => ({ value: el.standardid, label: el.name }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)) || [],
    coBenefitList:
      state.Project.coBenefitList
        .map((el) => ({ value: el.cobenefitid, label: el.cobenefitname }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)) || [],
    sdgList:
      state.Project.sdgList
        .map((el) => ({ value: el.sdgcode, label: el.sdgcode+'. '+el.sdgname }))
        .sort((a, b) => (a.value > b.value ? 1 : -1)) || [],
    standards: state.Project.standardList,
  }));


  // const { projectLoading, isTradeCreated, isTradeDelete, isTradeSuccess, error } = useSelector(
  //   (state) => ({
  //     isTradeCreated: state.Trade.isTradeCreated,
  //     isTradeSuccess: state.Trade.isTradeSuccess,
  //     isTradeDelete: state.Trade.isTradeDelete,
  //     error: state.Trade.error,
  //     projectLoading: state.Project.projectLoading,
  //   })
  // ); 


  const [buyerLoading, setBuyerLoading] = useState(false);
  // const [projects, setProjects] = useState([]);

  // const [buyers, setBuyers] = useState([]);
  // const [buyerList, setBuyerList] = useState(Buyer.buyerList);
  // const [selectBuyerList, setSelectBuyerList] = useState([]);
  // const [projectList, setProjectList] = useState();
  // const [justCall, setJustCall] = useState();
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [tradeDate, setTradeDate] = useState('');
  const [tradeYear, setTradeYear] = useState(null);
  const [tradeMonth, setTradeMonth] = useState(null);
  const [tradeQuarter, setTradeQuarter] = useState(null);
  const [tradeSemester, setTradeSemester] = useState(null);
  const [hasFullDate, setHasFullDate] = useState(1);
  const [currencyRateList, setCurrencyRateList] = useState();
  const [projectidVisible, setProjectidVisible] = useState();
  const [currentlySelectedProject, setCurrentlySelectedProject] = useState( isEdit?.projectid ? isEdit.projectid.project_code : '' );
  const [selectableProjectsList, setSelectableProjectsList] = useState([]);
  const [showSelectableProjectsList, setShowSelectableProjectsList] = useState(false);
  
  const [tradesCSVData, setTradesCSVData] = useState([]);
  const [preparingTradeData, setPreparingTradeData] = useState(false);

  const respondent_id = user?.respondent_id;

  const getCurrency = () => {
    axios
      .get(URL.get_currency)
      .then((r) =>
        setCurrencyOptions(
          r.map((el) => ({
            label: `${el.code + '-' + el.name}`,
            value: `${el.code}`,
          }))
        )
      )
      .catch((e) => console.log(e));
  };

  
  const USDRates = (tradeDate, tradeYear, tradeMonth, tradeQuarter, tradeSemester) => {
    axios.get(URL.get_currency_conversion_values+'?trade_date='+tradeDate+'&trade_year='+tradeYear+'&trade_month='+tradeMonth+'&trade_quarter='+tradeQuarter+'&trade_semester='+tradeSemester+'&has_full_date='+hasFullDate)
    .then((response) => {
      setCurrencyRateList(response.data);
    })
  };

  const getCurrencyRates = (value) => {
    if( value ){
      let result;
      for (const key in currencyRateList) {
        if (key === value) {
          result = currencyRateList[key];
        }
      }
      if( result ){
        return result;
      }
    }
    return undefined
  };

  const buyerListIncludesNewBuyer = () => {
    if( Buyer.buyerList && newBuyer ){
      for (const key in Buyer.buyerList) {
        if (Buyer.buyerList[key].buyer_id == newBuyer.buyer_id) {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  useEffect(() => {
    getCurrency();
  }, [tradeDate, currentTrade?.date_transaction]);



  useEffect(() => {
    if( newBuyer ){
      if( ! buyerListIncludesNewBuyer() ){
        Buyer.buyerList.push( { buyer_id : newBuyer.buyer_id, buyer_name: newBuyer.buyer_name } ); 
      }
      validation.setFieldValue('buyer', newBuyer.buyer_id);
      newBuyer = null;
    }
  }, [Buyer.buyerList]);

  useEffect(() => {
    let data = tradeDate || currentTrade?.date_transaction;
    let year = tradeYear || currentTrade?.trade_year || 0;
    let month = tradeMonth || currentTrade?.trade_month || 0;
    let quarter = tradeQuarter || currentTrade?.trade_quarter || 0;
    let semester = tradeSemester || currentTrade?.trade_semester || 0;
    data = moment.utc(data).format('yyyy-MM-DD');
    if (data || year) USDRates(data, year, month, quarter, semester);
  }, [tradeDate, currentTrade?.date_transaction, tradeYear, currentTrade?.trade_year, tradeMonth, currentTrade?.trade_month, currentTrade?.trade_quarter, tradeQuarter, currentTrade?.trade_semester, tradeSemester]);


  const TradeSchema = Yup.object({
    volume_tco2e: Yup.number().required('Please enter the trade volume.').positive('The trade volume must be a positive number').typeError('Sorry, this must be a number.'),
    volume_w_price: Yup.string(),
    value_usd: Yup.string(),
    conversion: Yup.number(),
    price_local: Yup.number().required('Please enter the price per tonne.').positive('Please enter the price per tonne.').typeError('Sorry, this must be a number.'),
    price_usd_tcoe: Yup.string(),
    date_transaction: Yup.string().nullable().test('date', 'Please choose a date, or indicate that no specific date is available and provide general date information.', (value, validation) => validateDateTransaction(value, validation)),
    no_date_transaction: Yup.string(),
    trade_year: Yup.string().nullable().test('year', 'Please select a year.', (value, validation) => validateTradeYear(value, validation)),
    trade_month: Yup.string().nullable(),
    trade_quarter: Yup.string().nullable(),
    trade_semester: Yup.string().nullable(),
    vintage_year: Yup.string().matches(/^\d\d\d\d[-]\d\d\d\d$|^\d\d\d\d$/, 'Please enter a four digit year, or a range of years separated by a hyphen (YYYY or YYYY-YYYY)'),
    market_classification: Yup.string(),
    forwardcontract_options: Yup.string(),
    forwardcontract_maturitydate: Yup.string().nullable(),
    end_user_or_intermediary: Yup.string(),
    name_of_end_user: Yup.string(),
    buyeroffsetgoals: Yup.string(),
    buyerdepartment: Yup.string(),
    scopes: Yup.string(),
    scope_1: Yup.string(),
    scope_2: Yup.string(),
    scope_3: Yup.string(),
    scope3_activity: Yup.string(),
    buyer_internal_or_resell: Yup.string(),
    buyer_qty_compliance: Yup.string(),
    buyer_retire_or_store: Yup.string(),
    buyer_retirement_policy: Yup.string(),
    buyer_vintage_cutoff: Yup.string(),
    buyer_consumerproduct: Yup.string(),
    development_usdt: Yup.string(),
    projectfinance_details: Yup.string(),
    projectfinance_usedcredithistory: Yup.string(),
    buyerjurisdiction: Yup.string(),
    project_jurisdictional_redd: Yup.string(),
    currency: Yup.string().required('Please select a currency.'),
    projid_known: Yup.string(),
    market_isspot: Yup.string(),
    market_isprimary: Yup.string(),
    market_isforward: Yup.string(),
    market_issecondary: Yup.string(),
    projecdtID: Yup.number(),
    project_noid_reason: Yup.string(),
    project_noid_reason_other: Yup.string(),
    project_noid_date_register: Yup.string().nullable(),
    project_name: Yup.string(),
    project_country_id: Yup.string(),
    project_type: Yup.string(),
    project_3p_carbon_standard: Yup.string(),
    project_3p_carbon_standard_id: Yup.number(),
    project_methodology: Yup.string(),
    project_cobenefits: Yup.array(),
    project_sdgs: Yup.array(),
    project_additional_certification: Yup.string(),
    trade_type: Yup.string(),
    transfer_or_retire: Yup.string(),
    credit_issuance_status: Yup.string(),
    estimated_or_actual_delivery_date:Yup.string().nullable(),
    
  });



  const getValueUSD = ( currency, volume, pricePerTonne ) => {
    let valueUSD = Number(clearNumber(volume)) * Number(pricePerTonne);
    let conversionRate = getCurrencyRates(currency);
    if( conversionRate !== undefined && conversionRate != 0 ){
      valueUSD = Math.round(valueUSD / conversionRate * 100) / 100;
    } else {
      valueUSD = 0;
    }
    return valueUSD;
  }

  const getPriceUSD = (currency, price_local) => {
    let priceUSD = price_local;
    let conversionRate = getCurrencyRates(currency);
    if( conversionRate !== undefined && conversionRate != 0 ){
      priceUSD = priceUSD / conversionRate;
    } else {
      priceUSD = 0;
    }
    return priceUSD;
  }
  

  const returnDateOrNull = ( chosenDate ) => {
    if( chosenDate ){
      return new Date(chosenDate);
    } else {
      return null;
    }
  }

  const getDateTransaction = (date_transaction, trade_year, trade_month, trade_quarter, trade_semester, no_date_transaction) => {
    let dateTransaction = date_transaction;
    if( no_date_transaction && trade_year && trade_month ){
      trade_month = trade_month.toString().padStart(2, '0')
      dateTransaction = `${trade_month}/01/${trade_year}`;
    } else if( no_date_transaction && trade_year && trade_quarter ){
      trade_month = '01';
      if( trade_quarter == 2 ){
        trade_month = '04';
      } else if( trade_quarter == 3 ){
        trade_month = '07';
      } else if( trade_quarter == 4 ){
        trade_month = '10';
      }
      dateTransaction = `${trade_month}/${trade_month}/${trade_year}`;
    } else if( no_date_transaction && trade_year && trade_semester ){
      trade_month = '01';
      if( trade_semester == 2 ){
        trade_month = '07';
      } 
      dateTransaction = `${trade_month}/${trade_month}/${trade_year}`;
    } else if( no_date_transaction && trade_year ){
      dateTransaction = `01/01/${trade_year}`;
    }
    return new Date(dateTransaction)
  }

  const getStandardName = (standard_id) => {
    let standardName = '';
    for (const key in standards) {
      if (standards[key].standardid == standard_id) {
        standardName = standards[key].name;
      }
    }
    return standardName;
  }

  const isFourDigitNum = (num) => {
    return /^\d{4}$/.test(num);
  }
  
  const getVintageStart = (vintage_year) => {
    let vintageStart = null;
    if( isFourDigitNum(vintage_year) ){
      vintageStart = parseInt(vintage_year);
    } else if( vintage_year.split('-').length == 2 ){
      if( isFourDigitNum(vintage_year.split('-')[0]) ){
        vintageStart = parseInt(vintage_year.split('-')[0]);
      }
    }
    return vintageStart;
  }

  const getVintageEnd = (vintage_year) => {
    let vintageEnd = null;
    if( isFourDigitNum(vintage_year) ){
      vintageEnd = parseInt(vintage_year);
    } else if( vintage_year.split('-').length == 2 ){
      if( isFourDigitNum(vintage_year.split('-')[1]) ){
        vintageEnd = parseInt(vintage_year.split('-')[1]);
      }
    }
    return vintageEnd;
  }

  


  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      projectid: currentTrade?.projectID || null,
      buyer: currentTrade?.buyer || "",
      volume_tco2e: currentTrade?.volume_tco2e || 0,
      volume_w_price: currentTrade?.volume_w_price || 0,
      value_usd: currentTrade?.value_usd || 0,
      conversion: currentTrade?.conversion || 1,
      price_local: currentTrade?.price_local || 0,
      date_transaction: currentTrade?.date_transaction || null,
      trade_year: currentTrade?.trade_year || null,
      trade_month: currentTrade?.trade_month || null,
      trade_quarter: currentTrade?.trade_quarter || null,
      trade_semester: currentTrade?.trade_semester || null,
      no_date_transaction: currentTrade?.no_date_transaction || '',
      vintage_year: currentTrade?.vintage_year || '',
      market_classification: currentTrade?.market_classification || '',
      forwardcontract_options: currentTrade?.forwardcontract_options || '',
      forwardcontract_maturitydate: currentTrade?.forwardcontract_maturitydate || null,
      end_user_or_intermediary: currentTrade?.end_user_or_intermediary || '',
      name_of_end_user: currentTrade?.name_of_end_user || '',
      buyeroffsetgoals: currentTrade?.buyeroffsetgoals || '',
      buyerdepartment: currentTrade?.buyerdepartment || '',
      scopes: currentTrade?.scopes || '',
      scope_1: currentTrade?.scope_1 || 0,
      scope_2: currentTrade?.scope_2 || 0,
      scope_3: currentTrade?.scope_3 || 0,
      scope3_activity: currentTrade?.scope3_activity || '',
      buyer_internal_or_resell: currentTrade?.buyer_internal_or_resell || '',
      buyer_qty_compliance: currentTrade?.buyer_qty_compliance || '',
      buyer_retire_or_store: currentTrade?.buyer_retire_or_store || '',
      buyer_retirement_policy: currentTrade?.buyer_retirement_policy || '',
      buyer_vintage_cutoff: currentTrade?.buyer_vintage_cutoff || '',
      buyer_consumerproduct: currentTrade?.buyer_consumerproduct || '',
      development_usdt: currentTrade?.development_usdt || '',
      projectfinance_details: currentTrade?.projectfinance_details || '',
      projectfinance_usedcredithistory: currentTrade?.projectfinance_usedcredithistory || '',
      buyerjurisdiction: currentTrade?.buyerjurisdiction || '',
      project_jurisdictional_redd: currentTrade?.project_jurisdictional_redd || '',
      currency: currentTrade?.currency || '',
      projid_known: currentTrade?.projid_known || 0,
      market_isspot: currentTrade?.market_isspot || '',
      market_isprimary: currentTrade?.market_isprimary || '',
      market_isforward: currentTrade?.market_isforward || '',
      market_issecondary: currentTrade?.market_issecondary || '',
      project_noid_reason: currentTrade?.project_noid_reason || '',
      project_noid_reason_other: currentTrade?.project_noid_reason_other || '',
      project_noid_date_register: currentTrade?.project_noid_date_register || null,
      project_name: currentTrade?.project_name || '',
      project_country_id: currentTrade?.project_country || '',
      project_3p_carbon_standard: currentTrade?.project_3p_carbon_standard || '',
      project_3p_carbon_standard_id: currentTrade?.project_3p_carbon_standard_id || 0,
      project_type: currentTrade?.project_type || '',
      project_methodology: currentTrade?.project_methodology || '',
      project_cobenefits: !currentTrade?.project_cobenefits ? [] : currentTrade?.project_cobenefits.split(','),
      project_sdgs: !currentTrade?.project_sdgs ? [] : currentTrade?.project_sdgs.split(','),
      project_additional_certification: currentTrade?.project_additional_certification || '',
      trade_type: currentTrade?.trade_type || '',
      transfer_or_retire: currentTrade?.transfer_or_retire || '',
      credit_issuance_status: currentTrade?.credit_issuance_status || '',
      estimated_or_actual_delivery_date: currentTrade?.estimated_or_actual_delivery_date || null,
    },

    validationSchema: TradeSchema,
    onSubmit: (values) => {
      if (isEdit) {
        const updatedTrade = {
          trade_id: currentTrade ? currentTrade.trade_id : 0,
          respondent: values.respondent,
          buyer: values.buyer,
          projectid: values.projectID,
          volume_tco2e: clearNumber(values.volume_tco2e),
          volume_w_price: values.volume_w_price,
          value_usd: getValueUSD(values.currency, values.volume_tco2e, values.price_local),
          conversion: getCurrencyRates(values.currency)?.toString(),
          price_local: values.price_local,
          price_usd_tcoe: getPriceUSD(values.currency, values.price_local),
          date_transaction: getDateTransaction(values.date_transaction, values.trade_year, values.trade_month, values.trade_quarter, values.trade_semester, values.no_date_transaction),
          trade_year: values.trade_year,
          trade_month: values.trade_month,
          trade_quarter: values.trade_quarter,
          trade_semester: values.trade_semester,
          no_date_transaction: values.no_date_transaction,
          vintage_year: values.vintage_year,
          vintage_start: getVintageStart(values.vintage_year),
          vintage_end: getVintageEnd(values.vintage_year),
          market_classification: values.market_classification,
          forwardcontract_options: values.forwardcontract_options,
          forwardcontract_maturitydate: returnDateOrNull(values.forwardcontract_maturitydate),
          end_user_or_intermediary: values.end_user_or_intermediary,
          name_of_end_user: values.name_of_end_user,
          buyeroffsetgoals: values.buyeroffsetgoals,
          buyerdepartment: values.buyerdepartment,
          scopes: null,
          scope_1: clearNumber(values.scope_1),
          scope_2: clearNumber(values.scope_2),
          scope_3: clearNumber(values.scope_3),
          scope3_activity: values.scope3_activity,
          buyer_internal_or_resell: values.buyer_internal_or_resell,
          buyer_qty_compliance: values.buyer_qty_compliance,
          buyer_retire_or_store: values.buyer_retire_or_store,
          // buyer_retirement_policy: values.buyer_retirement_policy,
          buyer_retirement_policy: null,
          buyer_vintage_cutoff: values.buyer_vintage_cutoff,
          buyer_consumerproduct: values.buyer_consumerproduct,
          development_usdt: values.development_usdt,
          projectfinance_details: values.projectfinance_details,
          projectfinance_usedcredithistory: values.projectfinance_usedcredithistory,
          buyerjurisdiction: values.buyerjurisdiction,
          project_jurisdictional_redd: values.project_jurisdictional_redd,
          currency: values.currency,
          projid_known: values.projid_known,
          market_isspot: values.market_isspot,
          market_isprimary: values.market_isprimary,
          market_isforward: values.market_isforward,
          market_issecondary: values.market_issecondary,
          project_noid_reason: values.project_noid_reason,
          project_noid_reason_other: values.project_noid_reason_other,
          project_noid_date_register: returnDateOrNull(values.project_noid_date_register),
          project_name: values.project_name,
          project_country: values.project_country_id,
          project_type: values.project_type,
          project_3p_carbon_standard: values.project_3p_carbon_standard_id == 0 ? null : getStandardName(values.project_3p_carbon_standard_id),
          project_3p_carbon_standard_id: values.project_3p_carbon_standard_id == 0 ? null : values.project_3p_carbon_standard_id,
          project_methodology: values.project_methodology,
          project_cobenefits: values.project_cobenefits.length == 0 ? '' : values.project_cobenefits.join(','),
          project_cobenefits_rel: getFormFieldArray(values.project_cobenefits),
          project_sdgs: values.project_sdgs.length == 0 ? '' : values.project_sdgs.join(','),
          project_sdgs_rel: getFormFieldArray(values.project_sdgs),
          project_additional_certification: values.project_additional_certification,
          trade_type: values.trade_type,
          transfer_or_retire: values.transfer_or_retire,
          credit_issuance_status: values.credit_issuance_status,
          estimated_or_actual_delivery_date: returnDateOrNull(values.estimated_or_actual_delivery_date),
        };
        // update customer
        dispatch(updateTrade({ data: updatedTrade, callback: toggle }));
        validation.resetForm();
      } else {
        const newTrade = {
          respondent: user.respondent_id,
          buyer: values['buyer'],
          projectid: values['projectID'], 
          volume_tco2e: clearNumber(values['volume_tco2e']),
          volume_w_price: values['volume_w_price'],
          value_usd: getValueUSD(values['currency'], values['volume_tco2e'], values['price_local']),
          conversion: getCurrencyRates(values['currency'])?.toString(), 
          price_local: values['price_local'],
          price_usd_tcoe: getPriceUSD(values['currency'], values['price_local']),
          date_transaction: getDateTransaction(values['date_transaction'], values['trade_year'], values['trade_month'], values['trade_quarter'], values['trade_semester'], values['no_date_transaction']),
          trade_year: values['trade_year'],
          trade_month: values['trade_month'],
          trade_quarter: values['trade_quarter'],
          trade_semester: values['trade_semester'],
          no_date_transaction: values['no_date_transaction'],
          vintage_year: values['vintage_year'],
          vintage_start: getVintageStart(values['vintage_year']),
          vintage_end: getVintageEnd(values['vintage_year']),
          market_classification: values['market_classification'],
          forwardcontract_options: values['forwardcontract_options'],
          forwardcontract_maturitydate: returnDateOrNull(values['forwardcontract_maturitydate']),
          end_user_or_intermediary: values['end_user_or_intermediary'],
          name_of_end_user: values['name_of_end_user'],
          buyeroffsetgoals: values['buyeroffsetgoals'],
          buyerdepartment: values['buyerdepartment'],
          // scopes: values["scopes"],
          scopes: null,
          scope_1: clearNumber(values['scope_1']),
          scope_2: clearNumber(values['scope_2']),
          scope_3: clearNumber(values['scope_3']),
          scope3_activity: values['scope3_activity'],
          buyer_internal_or_resell: values['buyer_internal_or_resell'],
          buyer_qty_compliance: clearNumber(values['buyer_qty_compliance']),
          buyer_retire_or_store: values['buyer_retire_or_store'],
          // buyer_retirement_policy: values["buyer_retirement_policy"],
          buyer_retirement_policy: null,
          buyer_vintage_cutoff: values['buyer_vintage_cutoff'],
          buyer_consumerproduct: values['buyer_consumerproduct'],
          development_usdt: values['development_usdt'],
          projectfinance_details: values['projectfinance_details'],
          projectfinance_usedcredithistory:
            values['projectfinance_usedcredithistory'],
          buyerjurisdiction: values['buyerjurisdiction'],
          project_jurisdictional_redd: values['project_jurisdictional_redd'],
          currency: values['currency'],
          projid_known: values['projid_known'],
          market_isspot: values['market_isspot'],
          market_isprimary: values['market_isprimary'],
          market_isforward: values['market_isforward'],
          market_issecondary: values['market_issecondary'],
          project_name: values['project_name'],
          project_noid_reason: values['project_noid_reason'],
          project_noid_reason_other: values['project_noid_reason_other'],
          project_noid_date_register: returnDateOrNull(values['project_noid_date_register']),
          project_country: values['project_country_id'],
          project_type: values['project_type'],
          project_3p_carbon_standard: values['project_3p_carbon_standard_id'] == 0 ? null : getStandardName(values['project_3p_carbon_standard_id']),
          project_3p_carbon_standard_id: values['project_3p_carbon_standard_id'] == 0 ? null : values['project_3p_carbon_standard_id'],
          project_methodology: values['project_methodology'],
          project_cobenefits: values['project_cobenefits'] && values['project_cobenefits'].length ? values['project_cobenefits'].join(',') : '',
          project_cobenefits_rel: getFormFieldArray(values['project_cobenefits']),
          project_sdgs: values['project_sdgs'] && values['project_sdgs'].length ? values['project_sdgs'].join(',') : '',
          project_sdgs_rel: getFormFieldArray(values['project_sdgs']),
          project_additional_certification: values['project_additional_certification'],
          trade_type: values['trade_type'],
          transfer_or_retire: values['transfer_or_retire'],
          credit_issuance_status: values['credit_issuance_status'],
          estimated_or_actual_delivery_date: returnDateOrNull(values['estimated_or_actual_delivery_date']),
        };
        // save new customer
        dispatch(addNewTrade({ data: newTrade, callback: toggle, user_email: user.email, current_url: window.location.href, respondent_name: user.respondent_name }));
        validation.resetForm();
      }
    },
  }); 

  useEffect(() => {
    getProjectList({ limit: 10, search: projectSearch });
  }, [projectSearch]);

  useEffect(() => {
    batch(() => {
      dispatch(getTypeList({ limit: 9999 }));
      dispatch(getCountryList({ limit: 9999 }));
      dispatch(getStandardList({ limit: 9999 }));
      dispatch(getCoBenefitList({ limit: 9999 }));
      dispatch(getSDGList({ limit: 9999 }));
      dispatch(getBuyerList({ respondent_id : user.respondent_id }));
    });
    
  }, []);

  const onSelectChange = (e, name) => {
    validation.setFieldValue(name, e.value);
    validation.handleChange(e);
  };

  const onMultiSelectChange = (e, name) => {
    const value = e.map( selection => selection.value );
    validation.setFieldValue(name, value);
  };

  const selectValue = (option, options) => {
    let isnum = /^\d+$/.test(option)
    if( isnum ){
      option = parseInt(option);
    }
    return options.find((el) => el.value === option);
  };

  

  const multiSelectValue = (selectedOptions, options) => {
    if( selectedOptions ){
      const selectedOptionsArr = selectedOptions.map( selected => options.find((el) => el.value == selected) );
      return selectedOptionsArr;
    } else {
      return undefined;
    }
  };

  const validateDateTransaction = (value, validation) => {
    let isValid = true;
    // trying to set up this validation in a way such that if an update to formik results in the validation object no longer having the expected structure, 
    // the validation won't break the form.  Chad P. 2024-04-22
    try {
      let isValidTest = false;
      const date_transaction = validation?.from[0].value.date_transaction;
      const no_date_transaction = validation?.from[0].value.no_date_transaction;
      if( date_transaction || no_date_transaction ){
        isValidTest = true;
      } 
      isValid = isValidTest;
    } catch (e) {
      console.log(e);
    }
    return isValid;
  }

  const validateTradeYear = (value, validation) => {
    let isValid = true;

    // trying to set up this validation in a way such that if an update to formik results in the validation object no longer having the expected structure, 
    // the validation won't break the form.  Chad P. 2024-04-22
    try {

      let isValidTest = false;
    
      const date_transaction = validation?.from[0].value.date_transaction;
      if( date_transaction ){
        isValidTest = true;
      } else {
        const no_date_transaction = validation?.from[0].value.no_date_transaction;
        const trade_year = validation?.from[0].value.trade_year;
        // const trade_month = validation.from[0].value.trade_month;
        // const trade_quarter = validation.from[0].value.trade_quarter;
        // const trade_semester = validation.from[0].value.trade_semester;
        if( no_date_transaction && trade_year ){
            isValidTest = true;
            // if( validation.from[0].value.trade_month ){
            //   isValid = true;
            // } else if( validation.from[0].value.trade_quarter ){
            //   isValid = true;
            // } else if( validation.from[0].value.trade_semester ){
            //   isValid = true;
            // }
        }
      }

      isValid = isValidTest;

    } catch (e) {
      console.log(e);
    }
  
    return isValid;
  }


  const getTypeName = (type_id) => {
    let typeName = '';
    for (const key in typeList) {
        if (typeList[key].id == type_id) {
            typeName = typeList[key].name;
        }
    }
    return typeName;
  }

  const getCountryName = (country_id) => {
    let countryName = '';
    for (const key in countries) {
        if (countries[key].value == country_id) {
            countryName = countries[key].label;
        }
    }
    return countryName;
}

  const prepareCSVData = (trades) => {
    let csvData = [];
    
    trades.forEach((trade) => {
      let row = {};
      row['Trade ID'] = trade?.trade_id;
      row['Transaction Date'] = trade?.trade_month ? trade?.trade_month.toString().padStart(2,'0')+'/'+trade?.trade_year :
            trade?.trade_quarter ? 'Q'+trade?.trade_quarter.toString()+' '+trade?.trade_year :
            trade?.trade_semester ? 'SEM'+trade?.trade_semester.toString()+' '+trade?.trade_year :
            trade?.trade_year ? trade?.trade_year :
            trade?.date_transaction ? moment.utc(trade?.date_transaction).format('MM/DD/YYYY') : ''
      row['ProjectID']  = trade?.projectid?.project_code;
      row['Project Name'] = trade?.projectid !== null && trade?.projectid?.projectid == 0 && trade?.project_name != "" ?
        trade?.project_name : trade?.projectid?.project_name;
      row['Project Type'] = trade?.projectid?.typeid !== null ? getTypeName(trade?.projectid?.typeid) : trade?.project_type !== null ? trade.project_type : '';
      row['Project Country'] = trade?.projectid?.countryid !== null ? getCountryName(trade?.projectid?.countryid) : trade?.project_country_id !== null ? getCountryName(trade?.project_country_id) : '';
      row['Carbon Standard'] = trade?.project_3p_carbon_standard !== null ? trade?.project_3p_carbon_standard : '';
      row['Methodology'] = trade?.project_methodology !== null ? trade?.project_methodology : '';
      row['Cobenefits'] = trade?.project_cobenefits !== null ? trade?.project_cobenefits : '';
      row['SDGs Certified'] = trade?.project_sdgs !== null ? trade?.project_sdgs : '';
      row['Additional Certification'] = trade?.project_additional_certification !== null ? trade?.project_additional_certification : '';
      row['Market Classification'] = trade?.market_classification;
      row['Trade Type'] = trade?.trade_type;
      row['Transfer or Retire'] = trade?.transfer_or_retire;
      row['Volume (tCO2e)'] = trade?.volume_tco2e;
      row['Price (USD/tCO2e)'] = trade?.price_usd_tcoe ? Math.round(trade?.price_usd_tcoe * 100) / 100 : '';
      row['Currency'] = trade?.currency;
      
      row['Vintage Year'] = trade?.vintage_year;
      row['Credit Issuance Status'] = trade?.credit_issuance_status;
      row['Market: Primary?'] = trade?.market_isprimary;
      row['Cost per Tonne for Project Development (USD)'] = trade?.development_usdt;
      row['Market: Secondary?'] = trade?.market_issecondary;
      row['Market: Spot?'] = trade?.market_isspot;
      row['If Spot, Estimated or Actual Retirement Date'] = trade?.estimated_or_actual_delivery_date ? moment.utc(trade?.estimated_or_actual_delivery_date).format('MM/DD/YYYY') : '';
      row['Market: Forward?'] = trade?.market_isforward;
      row['Forward Contract Options'] = trade?.forwardcontract_options;
      row['Forward Contract Maturity Date'] = trade?.forwardcontract_maturitydate ? moment.utc(trade?.forwardcontract_maturitydate).format('MM/DD/YYYY') : '';
      row['Buyer'] = trade?.buyer?.buyer_name;
      row['End User or Intermediary'] = trade?.end_user_or_intermediary;
      row['Name of End User (if known)'] = trade?.name_of_end_user;
      row['Buyer Country'] = trade?.buyer?.country ? getCountryName(trade?.buyer?.country) : '';
      row['Buyer Profit-type'] = trade?.buyer?.buyer_profit_status;
      row['Buyer Sector'] = trade?.buyer?.buyer_sector;
      row['Buyer Voluntary Goals'] = trade?.buyeroffsetgoals;
      row['Buyer Department'] = trade?.buyerdepartment;
      row['Scope 1 GHGs'] = trade?.scope_1;
      row['Scope 2 GHGs'] = trade?.scope_2;
      row['Scope 3 GHGs'] = trade?.scope_3;
      row['Scope 3 Activity'] = trade?.scope3_activity;
      row['If end buyer, did they retire offsets immediately or hold for future retirement?'] = trade?.buyer_retire_or_store;
      row['If end buyer, do they have a min cutoff vintage of offsets to retire?'] = trade?.buyer_retirement_policy;
      row['If end buyer, will offsets integrate into consumer product offerings, incl. Point-of-Sale (POS)?'] = trade?.buyer_consumerproduct;
        
        
      //  row['Value (USD)'] = trade?.value_usd ? Math.round(trade?.value_usd * 100) / 100 : '';
        
      csvData.push(row);
    });
    return csvData;
  };

  const getTradesForDownload = () => {
    setPreparingTradeData(true);
    axios.get(`${api_url}/api/trade/?respondent_id=${respondent_id}`).then((response) => {
      let preparedCSVData = prepareCSVData(response.results);
      setTradesCSVData(preparedCSVData);
      setPreparingTradeData(false);
    });
  };

  const getYYYYMMDD = () => {
    const dateObj = new Date();
    return moment.utc(dateObj).format('YYYY-MM-DD');
  };

  const removeNonAlphanumeric = (inputString) => {
    return inputString.replace(/[^a-zA-Z0-9]/g, '');
  };

  

  return (
    <>
        
        {tradesCSVData.length > 0 ? <button type='button' className='btn btn-primary add-btn btn-success me-4'><CSVLink filename={removeNonAlphanumeric(user.respondent_name)+"-trades-"+getYYYYMMDD()+".csv"} style={{color:'#fff'}} data={tradesCSVData}><i className="ri-file-download-line" style={{position:'relative',bottom: '-2px'}}></i> Done! - Click to Download</CSVLink></button> : <button type='button' className='btn btn-primary add-btn me-4' onClick={ () => { getTradesForDownload(); }}>
            {!preparingTradeData && 'Download Your Trades'}{preparingTradeData && 'Preparing Download...'}
        </button>}
        {preparingTradeData && <div className="me-4"><Spinner /></div>}
    </>
  );
};

export default withRouter(DownloadTrades);
