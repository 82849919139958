import {
  GET_RESPONDENT_LIST,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_RESPONDENT_USERS_LIST,
} from './actionType';

const INIT_STATE = {
  respondentList: [],
  respondentNext: null,
  respondentPrev: null,
  respondentUsersListCount: null,
  respondentListUsersNumPages: null,
  // respondentUsersList: [],
  // respondentListCount: null,
  // respondentListNumPages: null,
};

const Respondent = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_RESPONDENT_LIST:
          console.log('action.payload');
          console.log(action.payload);
          return {
            ...state,
            respondentList: action.payload.data.results,
            respondentNext: parseInt(
              action.payload.data.next?.split('page=')[1]?.split('&')[0]
            ),
            respondentPrev: parseInt(
              action.payload.data.previous?.split('page=')[1]?.split('&')[0]
            ),
            respondentListCount: action.payload.data.count,
            respondentListNumPages: action.payload.data.num_pages,
            isRespondentCreated: false,
            isRespondentSuccess: true,
          };

        // case  GET_RESPONDENT_USERS_LIST:
        //     console.log(action.payload);
        //     return {
        //         ...state,
        //         respondentUsersList: action.payload.data.results,
        //         respondentUsersListCount: action.payload.data.count,
        //         respondentListUsersNumPages: action.payload.data.num_pages,
        //         isRespondentUsersSuccess: true,
        //     };

        default:
          return { ...state };
      }

    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_RESPONDENT_LIST:
          return {
            ...state,
            error: action.payload.error,
            isRespondentCreated: false,
            isRespondentSuccess: false,
          };

        // case GET_RESPONDENT_USERS_LIST:
        //   return {
        //       ...state,
        //       error: action.payload.error,
        //       isRespondentUsersSuccess: false,
        //   };

        default:
          return { ...state };
      }

    case GET_RESPONDENT_LIST: {
      return {
        ...state,
        isRespondentCreated: false,
      };
    }

    default:
      return { ...state };
  }
};

export default Respondent;
