import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { URL } from '../../../constants';
import axios from 'axios';
import { Col, Row } from 'reactstrap'; 
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const RespondentsValueUSDByCategory = () => {

    const [dataReady, setDataReady] = useState(false);
    const [categoryTableData, setCategoryTableData] = useState([]);

    const { user } = useSelector((state) => ({
        user: state.Login.user,
      }));
    
    const respondent_id = user?.respondent_id;
    const respondent_name = user?.respondent_name;

    const categories = ['Agriculture', 'Chemical Proc. / Industrial Mfg.', 'Energy Efficiency / Fuel Switching', 
        'Forestry and Land Use',  'Household / Community Devices', 'Renewable Energy', 'Transportation', 'Waste Disposal', 'Other', 'Unspecified'];
    const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];

    const categories_colors = {
        'Agriculture': '#accf62',
        'Chemical Proc. / Industrial Mfg.': '#602451',
        'Energy Efficiency / Fuel Switching': '#3a81c5',
        'Forestry and Land Use': '#375f47',
        'Household / Community Devices': '#c02a27',
        'Renewable Energy': '#c37224',
        'Transportation': '#599bb4',
        'Waste Disposal': '#86493c',
        'Other': '#5d666f',
        'Unspecified': '#bdc8d3'
    };



    let initialDonutChartOptions = {
        colors: colors,
        chart: {
            type: 'pie',
            height: '350',
        },
        title: {
            text: 'Total Value (USD) by Category',
            verticalAlign: 'bottom',
        },
        tooltip: {
            valuePrefix: '$'
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: [{
                    enabled: false,
                    distance: 20
                }, {
                    enabled: true,
                    distance: -40,
                    format: 'Total Value (USD): {point.valueUSD:.2f}%',
                    style: {
                        fontSize: '1.2em',
                        textOutline: 'none',
                        opacity: 0.7
                    },
                    filter: {
                        operator: '>',
                        property: 'valueUSD',
                        value: 100
                    }
                }]
            }
        },
        
        }

    const [donutChartOptions, setdonutChartOptions] = useState(initialDonutChartOptions);

    useEffect(() => {
        axios.get(URL.get_resp_vcm_categories+`/?respondent_id=${respondent_id}`).then((response) => {
            let respVCMCategories = response.data;
            console.log(respVCMCategories);

            const categories = ['Agriculture', 'Chemical Proc. / Industrial Mfg.', 'Energy Efficiency / Fuel Switching', 
                'Forestry and Land Use',  'Household / Community Devices', 'Renewable Energy', 'Transportation', 'Waste Disposal', 'Other', 'Unspecified'];
            const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];

            // let years = [];
            // respVCMCategories.forEach((d) => {
            //     if (!years.includes(d.year_transact)) {
            //         years.push(d.year_transact);
            //     }
            // });

            // years.sort();
            let usd_by_category = {};
            let categories_from_data = [];
            let tableDataObj = {};
            respVCMCategories.forEach((d) => {
                if (!categories_from_data.includes(d.project_category)) {
                    categories_from_data.push(d.project_category);
                    usd_by_category[d.project_category] = 0;
                }

                if( tableDataObj[d.project_category] === undefined ){
                    tableDataObj[d.project_category] = 0;
                }
            });

            
        
            categories_from_data.forEach((category) => {
                respVCMCategories.forEach((d) => {
                    if (d.project_category === category) {
                        usd_by_category[d.project_category] += d.value_USD;
                        tableDataObj[d.project_category] += d.value_USD;
                    }
                });
            });


            let sortedTableDataObj = Object.keys(tableDataObj).sort(function(a,b){return tableDataObj[b]-tableDataObj[a]}).reduce(function(acc, key){
                acc[key] = tableDataObj[key];
                return acc;
            }, {});

            // sort tableDataObj by value
            

            setCategoryTableData(sortedTableDataObj);

            let donutChartData = [];

            categories.forEach((category) => {
                let valueUSD_obj = {name: category, y: usd_by_category[category]};
                
                donutChartData.push(valueUSD_obj);
            });


            
            setdonutChartOptions({ ...donutChartOptions, series: [{ name: 'Total Value (USD)', colorByPoint: true, innerSize: '75%', data: donutChartData}] });
            setDataReady(true);
    
            
        });
    }, []);

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
    });


    return (
        <React.Fragment>
            
            <style dangerouslySetInnerHTML={{__html: `
            #volume_by_year_by_category_stacked_bar_chart, #vwap_by_year_by_category_line_chart { margin-top: 20px; }
            #volume_by_year_by_category_stacked_bar_chart > figure > div > span { margin-right: 20px; margin-bottom:10px; font-size:16px; }
            #volume_by_year_by_category_stacked_bar_chart > figure > div { max-width:100%; padding:20px 0; }
            #volume_by_year_by_category_stacked_bar_chart g[aria-label="y-axis label"] text { font-size: 24px!important; }
            
            `}} />

            <Row>
                <Col className="col-12 col-lg-12"><div id="sbc">
                {dataReady && <HighchartsReact
                    highcharts={Highcharts}
                    options={donutChartOptions}
                />}</div>
                <div id="category-table-data">
                    <table className="table table-bordered">
                        {/* <thead>
                            <tr>
                                <th>Category</th>
                                <th>Value USD</th>
                            </tr>
                        </thead> */}
                        <tbody>
                            {Object.keys(categoryTableData).map((category) => {
                                return (
                                    <tr key="category" style={{backgroundColor: categories_colors[category], color: '#fff', fontWeight: '500'}}>
                                        <td>{category}</td>
                                        <td>{USDollar.format(categoryTableData[category])}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                </Col>
            </Row>

        </React.Fragment>
    );


}

export default RespondentsValueUSDByCategory;