import React, { useEffect, useState } from 'react';
import Navbar from '../../Landing/navbar';
import Footer from '../../Landing/footer';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../../../Components/Common/Spinner';


import Sidebar from '../../../Layouts/Sidebar';
import ListOfRespondentsPublic from './ListOfRespondents-Public';

//import actions
import {
    changeLayoutMode,
} from "../../../store/actions";

import ReactGA from 'react-ga4';
ReactGA.initialize('G-P7N889SGDD');
ReactGA.send({ hitType: "pageview", page: "/About/ListOfRespondents", title: "List of Respondents Page" });


const Index = (props) => {
  const loading = useSelector((state) => state?.Login?.loading);

  
  const [headerClass, setHeaderClass] = useState("");
  const dispatch = useDispatch();
  const {
      layoutType,
      layoutModeType,
      
  } = useSelector(state => ({
      layoutType: state.Layout.layoutType,
      layoutModeType: state.Layout.layoutModeType,
  }));

  const onChangeLayoutMode = (value) => {
      if (changeLayoutMode) {
          dispatch(changeLayoutMode(value));
      }
  };
  document.title = 'List of Respondents | Ecosystem Marketplace';

  if (loading) return <Spinner />;

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" data-layout="horizontal">
        <Navbar />
        <Sidebar layoutType={layoutType} />

        <ListOfRespondentsPublic />

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Index;
