import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  RESET_REGISTER_FLAG,
  REGISTER_VERIFY_EMAIL,
  REGISTER_VERIFY_EMAIL_SUCCESSFUL,
  REGISTER_VERIFY_EMAIL_FAILED,
  RESPONDENT_INVITE_VALIDATE,
  RESPONDENT_INVITE_VALIDATE_SUCCESSFUL,
  RESPONDENT_INVITE_VALIDATE_FAILED,
  RESPONDENT_REGISTER_USER,
} from "./actionTypes";

export const registerUser = (user) => {
  return {
    type: REGISTER_USER,
    payload: { user },
  };
};

export const registerUserSuccessful = (user) => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  };
};

export const registerUserFailed = (user) => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  };
};

export const respondentRegisterUser = (user) => {
  return {
    type: RESPONDENT_REGISTER_USER,
    payload: user,
  };
};

export const resetRegisterFlag = () => {
  return {
    type: RESET_REGISTER_FLAG,
  };
};

export const registerVerifyEmail = (key) => {
  return {
    type: REGISTER_VERIFY_EMAIL,
    payload: { key },
  };
};

export const registerVerifyEmailSuccessful = () => {
  return {
    type: REGISTER_VERIFY_EMAIL_SUCCESSFUL,
  };
};

export const registerVerifyEmailFailed = (error) => {
  return {
    type: REGISTER_VERIFY_EMAIL_FAILED,
    payload: error,
  };
};

export const respondentInviteValidate = (token) => {
  return {
    type: RESPONDENT_INVITE_VALIDATE,
    payload:token
  };
};

export const respondentInviteValidateSuccessful = () => {
  return {
    type: RESPONDENT_INVITE_VALIDATE_SUCCESSFUL,
  };
};

export const respondentInviteValidateFailed = () => {
  return {
    type: RESPONDENT_INVITE_VALIDATE_FAILED,
  };
};
