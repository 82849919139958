import React, { useEffect, useState } from 'react';
import { URL } from '../../../constants';
import axios from 'axios';
import { Col, Row } from 'reactstrap'; 
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const VWAPByCategoryAndYearMultiLineChartPublic = () => {

    const [dataReady, setDataReady] = useState(false);

    const categories = ['Agriculture', 'Chemical Proc. / Industrial Mfg.', 'Energy Efficiency / Fuel Switching', 
        'Forestry and Land Use',  'Household / Community Devices', 'Renewable Energy', 'Transportation', 'Waste Disposal'];
    const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];

    let initialLineChartOptions = {
        chart: {
            marginTop: '80',
        },
        colors: colors,
        title: {
            text: 'Price by Category and Year (USD/tCO2e)',
            align: 'left'
        },   
        subtitle: {
            text: 'Hover over chart for more details by category. Click on categories in legend to show/hide on chart',
            align: 'left',
            style: {
                fontSize: '16px',
            },
        },
        yAxis: {
            title: {
                text: 'Price (USD/tCO2e)'
            }
        },
        xAxis: {
            allowDecimals: false
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
                pointStart: 2020,
                
            }
        },
        series: [],
        tooltip: {
            valuePrefix: '$', 
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    };

    const [lineChartOptions, setLineChartOptions] = useState(initialLineChartOptions);

    

    useEffect(() => {
        axios.get(URL.get_volume_and_vwap_by_category).then((response) => {
            let volumeAndVWAPByCategory = response.data;
            volumeAndVWAPByCategory = volumeAndVWAPByCategory.reverse();

            const categories = ['Agriculture', 'Chemical Proc. / Industrial Mfg.', 'Energy Efficiency / Fuel Switching', 
                'Forestry and Land Use',  'Household / Community Devices', 'Renewable Energy', 'Transportation', 'Waste Disposal'];
            const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];

            let years = [];
            volumeAndVWAPByCategory.forEach((d) => { 
                if (!years.includes(d.year_transact)) {
                    years.push(d.year_transact);
                }
            });

            let vwap_by_year_and_category = {};
            
        
            years.forEach((year) => {
                vwap_by_year_and_category[year] = {};
                
                categories.forEach((category) => {
                    vwap_by_year_and_category[year][category] = 0;
                    
                });
                volumeAndVWAPByCategory.forEach((d) => {
                    if (d.year_transact === year) {
                        vwap_by_year_and_category[year][d.project_category] += d.vwap;
                        
                    }
                });
            });

            let vwapLineData = [];
            

            categories.forEach((category) => {
                let vwap_obj = {name: category, data: []};
                
                years.forEach((year) => {
                    if( vwap_by_year_and_category[year][category] === 0){
                        vwap_obj.data.push(null);
                    } else {
                        vwap_obj.data.push(vwap_by_year_and_category[year][category]);
                    }
                    
                });
                vwapLineData.push(vwap_obj);
                
            });


            //setSeriesData(vwapLineData);
            setLineChartOptions({ ...lineChartOptions, series: vwapLineData });
            
            
            setDataReady(true);
            
            
            
        });
    }, []);

    
    
    
        
        
    

            



    return (
        <React.Fragment>
            <style dangerouslySetInnerHTML={{__html: `
            .highcharts-credits { display:none }
            
            
            `}} />
            <Row>
                <Col className="col-12 col-lg-12">
                {dataReady && <HighchartsReact
                    highcharts={Highcharts}
                    options={lineChartOptions}
                />}
                </Col>
            </Row>
        </React.Fragment>
    );


}

export default VWAPByCategoryAndYearMultiLineChartPublic;