import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

import { EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";



// Import Images
import img2 from "../../assets/images/landing/features/img-2.png";

const Home = () => {
    return (
        <React.Fragment>
            <section className="section pb-0 hero-section" id="hero">
                <div className="bg-overlay bg-overlay-pattern"></div>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8} sm={10}>
                            <div className="text-center mt-lg-5 pt-5 pb-4">
                            <img src={img2} alt="" className="img-fluid" />
                                <h1 className="display-6 fw-bold mb-3 lh-2">
                                    
                                    <span className="text-white">Global carbon markets data, intelligence and insights.</span>
                                    
                                </h1>
                                <h2 className="fs-4 mb-5 text-uppercase text-white opacity-50">
                                    The definitive source since 2007.
                                </h2>

                                
 
{/* <ul className="list-group list-group-horizontal-md justify-content-center">
<li className="list-group-item"><i className="ri-checkbox-circle-fill text-success"></i> 300+ Respondents</li>
<li className="list-group-item"><i className="ri-checkbox-circle-fill text-success"></i> 300+ Respondents</li>
<li className="list-group-item"><i className="ri-checkbox-circle-fill text-success"></i> Not for profit</li>
</ul> */}
                                
                                    {/* <i className="ri-checkbox-circle-fill text-success"></i> Since 2007
                                    <i className="ri-checkbox-circle-fill text-success ms-3"></i> Not for profit */}

                                <p className="lead lh-base text-white opacity-75">
                                    Ecosystem Marketplace is a not-for-profit initiative of Forest Trends. 
                                    If you're working for good, we're working for you.   
                                    {/* We value all things climate and ecosystems, from carbon to people, biodiversity, and water. */}
                                </p>
                                {/* <p className="lead text-muted lh-base">
                                    Because we're a not-for-profit, our incentives align with yours.                                                             
                                </p> */}
                                {/* <p className="fw-bold lh-base">
                                    Ecosystem Marketplace is a not-for-profit working for regenerative growth. 
                                    <br /> If your incentives align with  
                                        <i className="ri-earth-fill text-info"></i>
                                    and 
                                        <i className="ri-group-line text-info"></i>
                                    then we're on your side.
                                </p> */}

                                <div className="d-flex gap-2 justify-content-center mt-4 mb-5">
                                    {/* <Link to="/register" className="btn btn-primary">Get Started <i className="ri-arrow-right-line align-middle ms-1"></i></Link> */}
                                    <Link to="/requestaccess" className="btn btn-danger btn-lg">Request Access <i className="ri-arrow-right-line align-middle ms-1"></i></Link>
                                </div>
                            </div>

                            <div className='mt-4 mt-sm-5 pt-sm-5 mb-sm-n5 demo-carousel h-25'>
                                {/* <div className="demo-img-patten-top d-none d-sm-block">
                                    <img src={imgpattern} className="d-block img-fluid" alt="..." />
                                </div>
                                <div className="demo-img-patten-bottom d-none d-sm-block">
                                    <img src={imgpattern} className="d-block img-fluid" alt="..." />
                                </div> */}
                               
                            </div>
                        </Col>
                    </Row>
                </Container>

                <div className="position-absolute start-0 end-0 bottom-0 hero-shape-svg">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                        // xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 1440 120">
                        <g mask="url(&quot;#SvgjsMask1003&quot;)" fill="none">
                            <path d="M 0,118 C 288,98.6 1152,40.4 1440,21L1440 140L0 140z">
                            </path>
                        </g>
                    </svg>
                </div>

            </section>
        </React.Fragment>
    );
};

export default Home;