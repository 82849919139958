import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Alert } from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link, useParams, useHistory } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// actions
import { registerVerifyEmail, resetRegisterFlag } from "../../store/actions";

//import images
import logoLight from "../../assets/images/logo.svg";
import authOffline from "../../assets/images/auth-offline.gif";

// import paths
import { PATH_AUTH } from "../../Routes/paths";

const AccountConfirm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { key } = useParams();

  const { error, registrationError, success } = useSelector((state) => ({
    registrationError: state.Account.registrationError,
    success: state.Account.success,
    error: state.Account.error,
  }));

  useEffect(() => {
    console.log(key);
    dispatch(registerVerifyEmail({ key }));
  }, [key, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetRegisterFlag());

      if (success) {
        history.push(PATH_AUTH.login);
      }
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, success, error, history]);

  document.title = "Ecosystem Marketplace — Account Confirm";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="100" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">
                    Data Dashboard. Alpha — version 0.1
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">
                        We are activating your Ecosystem Marketplace account
                      </p>
                    </div>
                    {success && success ? (
                      <>
                        {toast("We are redirecting to the login", {
                          position: "top-right",
                          hideProgressBar: false,
                          className: "bg-success text-white",
                          progress: undefined,
                          toastId: "",
                        })}
                        <ToastContainer autoClose={2000} limit={1} />
                        <Alert color="success">
                          Your account has been activated successfully. You can
                          now login.
                        </Alert>
                      </>
                    ) : null}
                    {error && error ? (
                      <Alert color="danger"> {registrationError} </Alert>
                    ) : null}
                    <img src={authOffline} alt="activating..." />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(AccountConfirm);
