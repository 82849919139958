import axios from "axios";
import { api } from "../config";
import jwtDecode from "jwt-decode";

// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const token = JSON.parse(sessionStorage.getItem("authUser"))
  ? JSON.parse(sessionStorage.getItem("authUser")).access_token
  : null;
if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },

  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error?.response?.status || "NA") {
      case 403:
        // If the token is expired
        // window.location.reload();
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;

      case "NA":
        message = "Oops something went wrong";
        break;
      default:
        message = { ...error.response };
    }

    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const { exp } = jwtDecode(token);
  handleTokenExpired(exp);
};

const handleTokenExpired = (exp) => {
  let expiredTimer;
  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  expiredTimer = window.setTimeout(() => {
    console.log("expired");
    // You can do what ever you want here, like show a notification
    window.location.reload();
  }, timeLeft);
};

/**
 * Remove the default authorization on Logout
 * @param {*} token
 */
const removeAuthorization = () => {
  delete axios.defaults.headers.common["Authorization"];
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      console.log(params);
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = async (url, data) => {
      return await axios.post(url, data).then(function (response){
        return response;
      })
    };
  
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };
  /**
   * Delete
   */
  delete = async (url, data) => {
    return await axios.delete(url, data).then( function (response){
      return response;
    });
  };
}
const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user)["user"];
  }
};

const getAccessToken = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user)["access_token"];
  }
};

const isValidToken = (accessToken) => {
  if (
    !accessToken ||
    accessToken === "undefined" ||
    accessToken === undefined
  ) {
    return false;
  }
  // ----------------------------------------------------------------------

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export {
  APIClient,
  setAuthorization,
  removeAuthorization,
  getLoggedinUser,
  getAccessToken,
  isValidToken,
};
