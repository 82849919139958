import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  Button,
  Spinner,
} from 'reactstrap';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// action
import { registerUser, apiError, resetRegisterFlag } from '../../store/actions';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { Link, useHistory, withRouter } from 'react-router-dom';

//import images
import logoLight from '../../assets/images/logo.svg';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';
import FeatherIcon from 'feather-icons-react';

// import path
import { PATH_AUTH, PATH_HOME } from '../../Routes/paths';

const Register = (props) => {
  // TODO Need to create a route for register/respondent/<token>
  // TODO this will contain a register form without email, please send the token to the backend along with other fields
  // TODO call /create-respondent-user/ backend will create the respondent user
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      first_name: Yup.string().required('Please Enter Your First Name'),
      last_name: Yup.string().required('Please Enter Your Last Name'),
      password: Yup.string()
        .required('Password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        ),
    }),
    onSubmit: (values) => {
      let user = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password1: values.password,
        password2: values.password,
      };
      dispatch(registerUser({ user, history: props.history }));
    },
  });

  const { error, registrationError, success } = useSelector((state) => ({
    registrationError: state.Account.registrationError,
    success: state.Account.success,
    error: state.Account.error,
  }));

  useEffect(() => {
    dispatch(apiError(''));
  }, [dispatch]);

  useEffect(() => {
    validation.setSubmitting(false);
    if (success) {
      validation.resetForm({});
    }

    setTimeout(() => {
      dispatch(resetRegisterFlag());
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, success, error]);

  document.title = 'Ecosystem Marketplace - Sign Up';

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className='auth-page-content'>
          <Container>
            <Row>
              <Col lg={12}>
                <div className='text-center mt-sm-5 mb-4 text-white-50'>
                  <div>
                    <Link
                      to={PATH_HOME.root}
                      className='d-inline-block auth-logo'>
                      <img src={logoLight} alt='' height='100' />
                    </Link>
                  </div>
                  <p className='mt-3 fs-15 fw-medium'>
                    Data Dashboard. Alpha — version 0.1
                  </p>
                </div>
              </Col>
            </Row>

            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='mt-4'>
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <h5 className='text-primary'>Create New Account</h5>
                      <p className='text-muted'>
                        Get your Ecosystem Marketplace account now
                      </p>
                    </div>
                    <div className='p-2 mt-4'>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        className='needs-validation'
                        action='#'>
                        {success && success ? (
                          <>
                            {toast('Verification email sent...', {
                              position: 'top-right',
                              hideProgressBar: false,
                              className: 'bg-success text-white',
                              progress: undefined,
                              toastId: '',
                            })}
                            <ToastContainer autoClose={2000} limit={1} />
                            <Alert color='success'>
                              We have sent a confirmation email. please check
                              your inbox.
                            </Alert>
                          </>
                        ) : null}

                        {error && error ? (
                          <Alert color='danger'>
                            <div>{registrationError}</div>
                          </Alert>
                        ) : null}

                        <Row>
                          <Col>
                            <div className='mb-3'>
                              <Label
                                htmlFor='first_name'
                                className='form-label'>
                                First Name{' '}
                                <span className='text-danger'>*</span>
                              </Label>
                              <Input
                                name='first_name'
                                type='text'
                                placeholder='Enter First Name'
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.first_name || ''}
                                invalid={
                                  validation.touched.first_name &&
                                  validation.errors.first_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.first_name &&
                              validation.errors.first_name ? (
                                <FormFeedback type='invalid'>
                                  <div>{validation.errors.first_name}</div>
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col>
                            <div className='mb-3'>
                              <Label htmlFor='last_name' className='form-label'>
                                Last Name <span className='text-danger'>*</span>
                              </Label>
                              <Input
                                name='last_name'
                                type='text'
                                placeholder='Enter Last Name'
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.last_name || ''}
                                invalid={
                                  validation.touched.last_name &&
                                  validation.errors.last_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.last_name &&
                              validation.errors.last_name ? (
                                <FormFeedback type='invalid'>
                                  <div>{validation.errors.last_name}</div>
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>

                        <div className='mb-3'>
                          <Label htmlFor='useremail' className='form-label'>
                            Email <span className='text-danger'>*</span>
                          </Label>
                          <Input
                            id='email'
                            name='email'
                            className='form-control'
                            placeholder='Enter email address'
                            type='email'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ''}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type='invalid'>
                              <div>{validation.errors.email}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='mb-3'>
                          <Label htmlFor='userpassword' className='form-label'>
                            Password <span className='text-danger'>*</span>
                          </Label>
                          <div className='position-relative auth-pass-inputgroup mb-3'>
                            <Input
                              name='password'
                              type={showPassword ? 'text' : 'password'}
                              placeholder='Enter Password'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ''}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type='invalid'>
                                <div>{validation.errors.password}</div>
                              </FormFeedback>
                            ) : null}
                            <button
                              className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                              type='button'
                              id='password-addon'
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}>
                              {!showPassword ? (
                                <FeatherIcon icon='eye-off' size={14} />
                              ) : (
                                <FeatherIcon icon='eye' size={14} />
                              )}
                            </button>
                          </div>
                        </div>

                        <div className='mb-4'>
                          <p className='mb-0 fs-12 text-muted fst-italic'>
                            By registering you agree to the Ecosystem
                            Marketplace
                            <a
                              href='https://docs.ecosystemmarketplace.com/hub/legal/terms'
                              target='_blank'
                              className='text-primary text-decoration-underline fst-normal fw-medium ms-1'>
                              Terms of Use
                            </a>
                          </p>
                        </div>

                        <div className='mt-4'>
                          {validation.isSubmitting ? (
                            <Button color='success' className='w-100' disabled>
                              <Spinner size='sm'>Loading...</Spinner>
                              <span> Loading</span>
                            </Button>
                          ) : (
                            <button
                              className='btn btn-success w-100'
                              type='submit'>
                              Sign Up
                            </button>
                          )}
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className='mt-4 text-center'>
                  <p className='mb-0'>
                    Already have an account ?{' '}
                    <Link
                      to={PATH_AUTH.login}
                      className='fw-semibold text-primary text-decoration-underline'>
                      {' '}
                      Signin{' '}
                    </Link>{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Register);
