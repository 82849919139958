import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link, useHistory } from "react-router-dom";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import axios from "axios";
// actions
import {
  loginUser,
  resetLoginFlag,
  resetLoginFlagError,
} from "../../store/actions";

//import images
import logoLight from "../../assets/images/logo.svg";

// import paths
import { PATH_AUTH } from "../../Routes/paths";
import { URL } from "../../constants/urls/urls";

// CHADAUTH
const ResetPassword = (props) => {

  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => ({
    user: state.Account.user,
    loading: state.Login.loading,
  }));

  const [userLogin, setUserLogin] = useState([]);

  useEffect(() => {
    if (user && user) {
      setUserLogin({
        email: user.user.email,
        password: user.user.confirm_password,
      });
    }
  }, [user]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: userLogin.email || "" || "",
      password: userLogin.password || "" || "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Password"),
      confirm_password: Yup.string().required("Confirm Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
    },
  });
  const [password, setPassword] = useState()
  const [confirm_password, setConfirmPassword] = useState()
  const { error } = useSelector((state) => ({
    error: state.Login.error,
  }));
  const [validPage, setValidPage] = useState()
  const history = useHistory()

  const [responseError, setResponseError] = useState(false)
  const [responseMessage, setResponseMessage] = useState()
  const [passwordNotSame, setPasswordNotSame] = useState()
  const [passwordNotSameError, setPasswordNotSameError] = useState(false)
  const sendResetPassword = () =>{
    let data = {
        password:password,
        confirm_password: confirm_password,
        token: props.match.params.token
    }
    if (data.password != data.confirm_password){
        setPasswordNotSame("Password does not match")
        setPasswordNotSameError(true)
        console.log("Password Not Same")
    }else{
      axios.post(`${URL.reset_password}`, data)
      .then(r => history.push("/login"))
      .catch(e => {setResponseError(true); setResponseMessage("Link Expired Please Try Again")})
    }

  }

  const checkValidatity = () =>{
    let data = {
      token: props.match.params.token
    }
    axios.post(`${URL.check_validatity}`, data)
    .then(r => setValidPage(true))
    .catch(e => history.push("/page-not-found"))
  }
  


  useEffect(() =>{
    checkValidatity()
  }, validPage)

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetLoginFlag());
    }, 5000);

    setTimeout(() => {
    if (responseError == true){
      setResponseError(false)
    }
    if(passwordNotSameError == true){
      setPasswordNotSameError(false)
    }
    }, 5000)
  }, [dispatch, error, responseError, passwordNotSame]);

  useEffect(() => {
    dispatch(resetLoginFlagError());
  }, []);

  const AlertMessage = () =>{
    if(passwordNotSameError){
      return(
        <Alert className="alert-borderless alert-danger text-center mb-2 mx-2" role="alert">
          {passwordNotSame}
      </Alert>
      )
    }
    else if(responseError){
      return(
        <Alert className="alert-borderless alert-danger text-center mb-2 mx-2" role="alert">
          {responseMessage}
      </Alert>
      )
    }
  }

  document.title = "Ecosystem Marketplace — Login";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="100" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">
                    Data Dashboard. Alpha — version 0.1
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4 reset_wrapper">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">
                        Enter your new password to continue to Ecosystem Marketplace
                      </p>
                    </div>
                    {error && error ? (
                      <Alert color="danger"> {error} </Alert>
                    ) : null}
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >

                        {AlertMessage()}
                        <div className="mb-3">
                          <Label htmlFor="password" className="form-label">
                            New Password
                          </Label>
                          <Input
                            name="password"
                            className="form-control"
                            placeholder="Enter Password"
                            type="password"
                            // onChange={validation.handleChange}
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={validation.handleBlur}
                            // value={validation.values.email || ""}
                            value = {password}
                            
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                           Confirm Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="confirm_password"
                              value={confirm_password}
                              type="password"
                              className="form-control pe-5"
                              placeholder="Confirm Password"
                              //onChange={validation.handleChange}
                              onChange={(e) => setConfirmPassword(e.target.value)}

                              onBlur={validation.handleBlur}
                              
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="success"
                            className="btn btn-success w-100"
                            type="submit"
                            // disabled={password != confirm_password}
                            onClick = {sendResetPassword}
                          >
                            Reset {loading && <Spinner size={"sm"} />}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(ResetPassword);
