import React, { useEffect, useState } from 'react';
import {
  Col,
  Container,
  CardHeader,
  Button,
  Row,
  Card,
  CardBody, 
  Alert,
} from 'reactstrap'; 
 
import axios from 'axios';
import { api } from '../../config'; 
import { useSelector } from 'react-redux';

import { embedDashboard } from "@superset-ui/embedded-sdk";

const StandardsDashboard = () => {
  const [dashboardData, setDashboardData] = useState();
  const { user } = useSelector((state) => ({
    user: state.Login.user,
  }));

  

  let dashboardID = '12676ddd-f8d9-4ff4-8aea-e3a4f5f6a6cc';
  const username = user?.email;
  const respondent_id = user?.respondent_id;

  const getToken = async () => {
    let token = null;
    try {
        const response = await axios.post('https://www.ecosystemmarketplace.com/superset/superset_auth.php', {
            username: username,
            id: dashboardID,
        })
        token = await response;
    } catch (error) {
        console.log(error);
    }
    return token;
  }

  let iframeSRCinterval = null;

  useEffect(() => {
    axios.get(`/api/data/get_maxtradeyear?respondent_id=${respondent_id}`)
    .then((data) => {
      let maxYear = data.maxYear;
      if( maxYear == '2022' ){
        dashboardID = '73e4c7f2-f6bb-4046-812a-3aa1147e18a7';
      }
      if( parseInt(maxYear) > 2022 || user?.role == 3){
        dashboardID = '3d31d33e-83f1-4799-9257-ca97e59a8367';
      }
    
      
      let embed = async () => {
        await embedDashboard({
          id: dashboardID, 
          supersetDomain: "https://bi.ecosystemmarketplace.com",
          mountPoint: document.getElementById("data-display-container"), 
          fetchGuestToken: () => getToken(),
          dashboardUiConfig: {
            hideTitle: false,
            hideChartControls: true,
            hideTab: false,
            showFilters: false,
          },
        })
      }
      if (document.getElementById("data-display-container")) {
        embed();
        if( iframeSRCinterval ){
          clearInterval(iframeSRCinterval);
        }
        iframeSRCinterval = setInterval(() => {
          
          let src = document.getElementById("data-display-container").querySelector('iframe').getAttribute('src');
          if( src.indexOf('show_filters') === -1 ){
              src += '&standalone=2&show_filters=0&expand_filters=0';
              document.getElementById("data-display-container").querySelector('iframe').setAttribute('src', src);
              clearInterval(iframeSRCinterval);
          }
          
        }, 100);
      }
    })
    .catch((error) => {
        console.log(error);
    });

  }, []);

  

  const getDashboardData = async (respondent_id) => {
    if( respondent_id ){
      try {
        const response = await axios.post(
          `${api?.API_URL}/api/respondent-dashboard/`,
          { respondent_id }
        );
        setDashboardData(response);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!user?.is_superuser) getDashboardData(user?.respondent_id);
  }, []);

  document.title = 'Data: Standards | Ecosystem Marketplace';
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
       
          <Row>
          <Card>
            <CardHeader className="align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Standards</h4>
              <div className="flex-shrink-0"></div>
            </CardHeader>
            <CardBody className="p-0">

              <div id="data-display-container"></div>
              {/* <div className="p-3" style={{height: '100vh'}}>Sorry, our data visualizations are temporarily unavailable while we perform some system upgrades.  We'll have them back up as soon as possible.</div> */}
     
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default StandardsDashboard;
