import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

const Alt404 = () => {
  document.title = "Page not Found - 404";

  return (
    <React.Fragment>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row className="justify-content-center">
              <Col xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="p-4">
                    <div className="text-center">
                      <lord-icon
                        className="avatar-xl"
                        src="https://cdn.lordicon.com/spxnqpau.json"
                        trigger="loop"
                        colors="primary:#405189,secondary:#0ab39c"
                        style={{ width: "120px", height: "120px" }}
                      ></lord-icon>
                      <h1 className="text-primary mb-4">Logged out</h1>
                      <h4 className="text-uppercase">
                      You have been successfully logged out. 
                      </h4>
                      <p className="text-muted mb-4">
                      {/* You have been successfully logged out.  */}
                      </p>
                      <Link to="/" className="btn btn-success btn-lg">
                        <i className="mdi mdi-home me-1"></i> Go to the Homepage
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Alt404;
