import React from 'react';
import {
  Container,
  CardHeader,
  Row,
  Card,
  CardBody, 
} from 'reactstrap'; 

import supporters from "../../../assets/images/brands/EM-Supporters.jpg";
 











const OurSupportersPublic = () => {


    
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
        
            <Row>
            <Card>
            <CardHeader className="align-items-center d-flex"><h2 className="mb-10 flex-grow-1">Our Supporters</h2></CardHeader>
                <CardBody>
                <img src={supporters} alt="client-img" className="mx-auto img-fluid d-block" />
                    
                </CardBody>
                
                
                
                
                
                
                
                </Card>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default OurSupportersPublic;
