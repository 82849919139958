// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_BUYER_LIST = "GET_BUYER_LIST";

/**
 * Add BUYER
 */
export const ADD_NEW_BUYER = "ADD_NEW_BUYER";
export const ADD_BUYER_SUCCESS = "ADD_BUYER_SUCCESS";
export const ADD_BUYER_FAIL = "ADD_BUYER_FAIL";

/**
 * Edit BUYER
 */
export const UPDATE_BUYER = "UPDATE_BUYER";
export const UPDATE_BUYER_SUCCESS = "UPDATE_BUYER_SUCCESS";
export const UPDATE_BUYER_FAIL = "UPDATE_BUYER_FAIL";

/**
 * Delete BUYER
 */
export const DELETE_BUYER = "DELETE_BUYER";
export const DELETE_BUYER_SUCCESS = "DELETE_BUYER_SUCCESS";
export const DELETE_BUYER_FAIL = "DELETE_BUYER_FAIL";

export const GET_SECTOR_LIST = "GET_SECTOR_LIST";
