import {
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    GET_RESPONDENT_USERS_LIST, INVITE_RESPONDENT, API_RESPONSE_LOADING, TOGGLE_INVITE_RESPONDENT_MODAL,
} from "./actionType";
  
  const INIT_STATE = {
      respondentUsersListCount: null,
      respondentListUsersNumPages: null,
      respondentUsersList: [],
      isInviteRespondentModalOpened: false
  };
  
  const RespondentUser = (state = INIT_STATE, action) => {
    switch (action.type) {
      case API_RESPONSE_SUCCESS:
        switch (action.payload.actionType) {

            case  GET_RESPONDENT_USERS_LIST:
                console.log(action.payload);
                return {
                    ...state,
                    respondentUsersList: action.payload.data,
                    // respondentUsersListCount: action.payload.data.count,
                    // respondentListUsersNumPages: action.payload.data.num_pages,
                    isRespondentUsersSuccess: true,
                };
                case  INVITE_RESPONDENT:
                return {
                    ...state,
                    isInviteRespondentSuccess: true
                };
            default:
            return { ...state };
        }
  
      case API_RESPONSE_ERROR:
        switch (action.payload.actionType) {

          case GET_RESPONDENT_USERS_LIST:
            return {
                ...state,
                error: action.payload.error,
                isRespondentUsersSuccess: false,
            };
            case  INVITE_RESPONDENT:
                return {
                    ...state,
                    isInviteRespondentSuccess: true
                };
          default:
            return { ...state };
        }


        case API_RESPONSE_LOADING:
        switch (action.payload.actionType) {

          case INVITE_RESPONDENT:
            return {
                ...state,
                isRespondentUsersLoading: action.payload,
            };
          default:
            return { ...state };
        }
        case TOGGLE_INVITE_RESPONDENT_MODAL:
            return {
                ...state,
                isInviteRespondentModalOpened: action.payload,
            };
      default:
        return { ...state };
    }
  };
  
  export default RespondentUser;
  