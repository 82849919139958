const ReactColumnFilter = ({ column }) => {
  const { filterValue, setFilter, Header } = column;
  return (
    <div>
      <input
        className='form-control form-control-sm'
        value={filterValue || ''}
        placeholder={Header}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export default ReactColumnFilter;
