import React, { useEffect, useState, useRef } from 'react';
import {
  Container,
  CardHeader,
  Row,
  Card,
  CardBody, 
} from 'reactstrap'; 
 

import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from '../../store/actions';

import StandardsScatterWithJitter from './components/StandardsScatterWithJitter-Private';
import TotalVolumeBarChartPublic from './components/TotalVolume-BarChart-Public';
import TotalVolumeBarChartPrivate from './components/TotalVolume-BarChart-Private';
import VWAPLineChartPublic from './components/VWAP-Line-Chart-Public';
import VolumeByCategoryStackedBarChartPublic from './components/VolumeByCategory-StackedBarChart-Public';
import VWAPByCategoryAndYearMultiLineChartPublic from './components/VWAPByCategoryAndYear-MultiLineChart-Public';
import VolumeByCountryMapPublic from './components/VolumeByCountry-Map-Public';

import StandardsVolumeByCategory from './components/CategoriesStandards-StackedBarChart';
import RespondentsVolumeByCategory from './components/RespondentVolumeByCategory-StackedBarChart';
import RespondentsVolumeBarChart from './components/RespondentVolume-BarChart';
// import CategoryClustersPackedBubble from './components/CategoryClustersPackedBubble';
import StandardsVolumeTotals from './components/StandardsTotalVolume-BarChart';

import VolumeByCategory from './components/VolumeByCategory-StackedBarChart';
import RespondentVolumeByCountryMap from './components/RespondentVolumeByCountryMap';

import VolumeByCountryPrivate from './components/VolumeByCountryPrivate';







const NewCharts = () => {

    const { user } = useSelector((state) => ({
        user: state.Login.user,
    }));

    const dispatch = useDispatch();

    if( ! user?.is_superuser ){
        dispatch(logoutUser());
    } 

    

  
    document.title = 'New Chart Playground | Ecosystem Marketplace';
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
        
            <Row>
            <Card>
                <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Charts</h4>
                <div className="flex-shrink-0"></div>
                </CardHeader>
                <CardBody className="p-0">
                    <StandardsScatterWithJitter />
                </CardBody>
                {/* <CardBody className="p-0">
                    <CategoryClustersPackedBubble />
                </CardBody> */}
                <CardBody className="p-0">
                    <TotalVolumeBarChartPublic />
                </CardBody>
                <CardBody className="p-0">
                    <TotalVolumeBarChartPrivate />
                </CardBody>
                <CardBody className="p-0">
                    <VWAPLineChartPublic />
                </CardBody>
                <CardBody className="p-0">
                    <VolumeByCategoryStackedBarChartPublic />
                </CardBody>
                <CardBody className="p-0">
                    <VWAPByCategoryAndYearMultiLineChartPublic />
                </CardBody>
                <CardBody className="p-0">
                    <VolumeByCountryMapPublic />
                </CardBody>
                <CardBody className="p-0">
                    <VolumeByCountryPrivate />
                </CardBody>
                <CardBody className="p-0">
                    <RespondentVolumeByCountryMap />
                </CardBody>
                <CardBody className="p-0">
                    <RespondentsVolumeBarChart />
                </CardBody>
                <CardBody className="p-0">
                    <VolumeByCategory />
                </CardBody>
                <CardBody className="p-0">
                    <RespondentsVolumeByCategory />
                </CardBody>
                <CardBody className="p-0">
                    <StandardsVolumeTotals />
                </CardBody>
                <CardBody className="p-0">
                    <StandardsVolumeByCategory />
                </CardBody>
                
                
                
                
                
                </Card>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default NewCharts;
