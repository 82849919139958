import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="custom-footer bg-dark py-2 position-relative">
                <Container fluid>
                    
                    <Row className="text-center text-sm-start align-items-center mt-2">
                        <Col sm={6}>

                            <div>
                                <p className="copy-rights mb-0">
                                    {new Date().getFullYear()} © Ecosystem Marketplace
                                </p>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="text-sm-end mt-3 mt-sm-0">
                                <ul className="list-inline mb-0 footer-social-link">
                                    <li className="list-inline-item">
                                        <a href="https://twitter.com/EcoMarketplace" className="avatar-xs d-block" target="_blank">
                                            <div className="avatar-title rounded-circle">
                                                <i className="ri-twitter-fill"></i>
                                            </div>
                                        </a>
                                    </li> 
                                    <li className="list-inline-item">
                                        <a href="https://www.linkedin.com/company/ecosystem-marketplace/" className="avatar-xs d-block" target="_blank">
                                            <div className="avatar-title rounded-circle">
                                                <i className="ri-linkedin-fill"></i>
                                            </div>
                                        </a>
                                    </li> 
                                    <li className="list-inline-item">
                                        Version 0.50
                                    </li> 
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment >
        // <React.Fragment>
        //     <footer className="footer">
        //         <Container fluid>
        //             <Row>
        //                 <Col sm={6}>
        //                     {new Date().getFullYear()} © Ecosystem Marketplace
        //                 </Col>
        //                 <Col sm={6}>
        //                     <div className="text-sm-end d-none d-sm-block">
        //                         Version 0.10
        //                     </div>
        //                 </Col>
        //             </Row>
        //         </Container>
        //     </footer>
      
        // </React.Fragment>
    );
};

export default Footer;