import logo from '../../assets/images/logo-spinner.png';

const Spinner = () => {
  return (
    <>
    <div className='custom-loader' style={{ backgroundImage:logo }}></div>
    
    {/* <div className='spinnerOuter d-none'> 
      <div className='spinner'>
        <p>loading...</p>
        <div className='spinner-area spinner-first'></div>
        <div className='spinner-area spinner-second'></div>
        <div className='spinner-area spinner-third'></div>
      </div>
    </div> */}
    </>
  );
};

export default Spinner;
