import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Container,
  Table,
  Input,
  Alert,
  Label,
  Row,
  Spinner,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DeleteModal from '../../Components/Common/DeleteModal';
import Loader from '../../Components/Common/Loader';
import { deleteBuyer, getBuyerList } from '../../store/actions';
import BuyerForm from './components/BuyerForm';

import axios from 'axios';


import ReactGA from 'react-ga4';
ReactGA.initialize('G-P7N889SGDD');
ReactGA.send({ hitType: "pageview", page: "/buyer/list", title: "Respondent Buyers Page" });

const BuyerList = () => {
  const dispatch = useDispatch();
  const {
    buyers,
    Buyer,
    buyerNext,
    buyerPrev,
    buyerNumPages,
    error,
    isBuyerSuccess,
    isBuyerCreated,
    isBuyerDeleted,
    user,
    respondentName,
    respondentId,
  } = useSelector((state) => ({
    buyers: state.Buyer.buyerList,
    Buyer: state.Buyer,
    buyerNext: state.Buyer.buyerNext,
    buyerPrev: state.Buyer.buyerPrev,
    buyerNumPages: state.Buyer.buyerListNumPages,
    isBuyerSuccess: state.Buyer.isBuyerSuccess,
    isBuyerCreated: state.Buyer.isBuyerCreated,
    isBuyerDeleted: state.Buyer.isBuyerDeleted,
    error: state.Buyer.error,
    user: state.Login.user,
    respondentName: state.Login?.user?.respondent_name,
    respondentId: state.Login?.user?.respondent_id,
  }));

  const [buyerList, setBuyerList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [currentBuyer, setCurrentBuyer] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortBy, setSortBy] = useState('buyer_name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [buyerName, setBuyerName] = useState('');
  const [buyerProfitStatus, setBuyerProfitStatus] = useState('');
  const [buyerSector, setBuyerSector] = useState('');
  const [country, setCountry] = useState('');
  const [loading, setLoading] = useState(false);
  const [buyersPerPage, setBuyersPerPage] = useState(10);
  const [buyerCount, setBuyerCount] = useState(0);
  const [buyerListInitializing, setBuyerListInitializing] = useState(true);

  // Buyer Form Modal
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCurrentBuyer(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // useEffect(() => {
  //   // the buyerList is an array of objects
  //   // sort the buyerList array by the buyer_id key in descending order
  //   let sortedBuyerList = [];
  //   if( Buyer.buyerList && Buyer.buyerList.length > 0 ){
  //     sortedBuyerList = Buyer.buyerList ? Buyer.buyerList.sort((a, b) => b.buyer_id - a.buyer_id) : []; 
  //   }
  //   setBuyerList(sortedBuyerList);
  // }, [buyers, Buyer.buyerList]);

  // useEffect(() => {
  //   dispatch(getBuyerList());
  // }, [dispatch, isBuyerCreated, isBuyerDeleted]);

  // useEffect(() => {
  //   dispatch(
  //     getBuyerList({
  //       search: searchTerm,
  //       skip: 0,
  //     })
  //   );
  // }, [dispatch, searchTerm]);

  // axios.get(URL.get_buyerlistapi+'?respondent_id='+respondentId).then((response) => {
  //   
  // });


  const buildCurrentQueryURL = () => {
    let currentQuery = '/api/data/get_buyerlistapi?respondent_id='+respondentId+'&orderby='+sortBy+'&sort='+sortOrder;
    if (buyerName) {
      currentQuery += `&buyer_name=${buyerName}`;
    }
    if( buyerProfitStatus ){
      currentQuery += `&buyer_profit_status=${buyerProfitStatus}`;
    }
    if( buyerSector ){
      currentQuery += `&buyer_sector=${buyerSector}`;
    }
    if( country ){
      currentQuery += `&buyer_country_name=${country}`;
    }
    
    return currentQuery;
  };

  useEffect(() => {
    axios.get(buildCurrentQueryURL()).then((response) => {
      setBuyerCount(response.count);
      setBuyerList(response.data);
      setBuyerListInitializing(false);
      setCurrentPage(1);
    });
  }, []); 

  const paginationInput = useRef(null);

  const getPage = (page) => {
    if( Number.isInteger(parseInt(page)) && parseInt(page) > 0 ){
      if( paginationInput.current?.value ){
        paginationInput.current.value = parseInt(page);
      }
      let offset = (page - 1)*buyersPerPage;
      let currentQueryURL = buildCurrentQueryURL();
      if ((page - 1)*buyersPerPage < buyerCount) {
        setLoading(true);
        axios.get(`${currentQueryURL}&limit=10&offset=${offset}`).then((data) => {
          setLoading(false);
          setBuyerCount(data.count);
          setCurrentPage(page);
          setBuyerList(data.data); 
        });
      }
    }
  };


  // Update Data

  const handleCustomerClick = useCallback(
    (arg) => {
      const buyer = arg;
      setCurrentBuyer({
        buyer_id: buyer.buyer_id,
        buyer_name: buyer.buyer_name,
        buyer_profit_status: buyer.buyer_profit_status,
        buyer_profit_status_description: buyer.buyer_profit_status_description,
        buyer_sector: buyer.buyer_sector,
        country: buyer.country,
      });

      toggle();
    },
    [toggle]
  );

  // Delete Data
  const onClickDelete = (buyer) => {
    setCurrentBuyer(buyer);
    setDeleteModal(true);
  };

  const onClosed = () => {
    setCurrentBuyer(null);
    setTimeout(() => {
      getPage(currentPage);
    }, 1000);
  };

  // Delete Data
  const handleDeleteTask = () => {
    if (currentBuyer) {
      dispatch(deleteBuyer(currentBuyer)); 
      setDeleteModal(false);
      setCurrentBuyer(null);
      setTimeout(() => {
        getPage(currentPage);
      }, 1000);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getPage(1);
    }, 300)
    return () => clearTimeout(delayDebounceFn)
  }, [buyerName, buyerProfitStatus, buyerSector, country]);

  const handlePaginationInputChange = debounce( (input) => {
    let { value, max } = input;
    let page = parseInt(value);
    max = parseInt(max);
    if( page > max ){
      input.value = max;
      page = max;
    } else {
      input.value = page;
    }
    if( Number.isInteger(page) && page > 0 ){
      setCurrentPage(page);
      getPage(page);
    }
  }, 250);

  const setOrderAndSort = (order_by) => {
    if( order_by === sortBy ){
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder('asc');
      setSortBy(order_by);
    }
  };

  useEffect(() => {
    getPage(currentPage);
  }, [sortBy, sortOrder]);

  const respondent_name_possessive = respondentName.slice(-1) != 's' ? respondentName + "'s" : respondentName+"'";

  document.title = 'Ecosystem Marketplace - Buyer List';
  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTask}
        onCloseClick={() => setDeleteModal(false)}
      />
      <BuyerForm
        isEdit={currentBuyer}
        modal={modal}
        setModal={setModal}
        currentBuyer={currentBuyer}
        toggle={toggle}
        onClosed={onClosed}
      />
      <div className='page-content'>
        <Container fluid={true}>
          <BreadCrumb title={`${
                respondentName ? `${respondentName} Buyers` : 'Buyers'
              }`} pageTitle='Buyers' />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className='d-flex align-items-center'>
                    <h5 className='card-title mb-0 flex-grow-1'>Buyers</h5>
                    <div className='flex-shrink-0'>
                      <button
                        type='button'
                        className='btn btn-success add-btn'
                        id='create-btn'
                        onClick={() => setModal(true)}
                      >
                        <i className='ri-add-line align-bottom me-1'></i> Create
                        Buyer
                      </button>{' '}
                    </div>
                  </div>
                </CardHeader>
                
                  <CardBody className="p-0 card-border-info ">
                      <Alert color='info' className="border-0 rounded-top rounded-0 m-0 d-flex align-items-center">
                          <i className="ri-information-line me-2 fs-16"
                          />
                          <div className="flex-grow-1 text-truncate">
                          Privacy Note: this page and the buyers list contained herein is private per EM Respondent organization.                         
                          </div>
                      </Alert>
                  </CardBody>

                  <CardBody>
                    { loading && <div className="loadingOverlay"> 
                      <div className="loadingSpinner"><Spinner /></div>
                    </div> }
                    <div className='table-responsive table-card mb-1'>
                      <table role="table" className="align-middle table-nowrap table table-hover" style={{tableLayout: 'fixed'}}>
                        <thead className="table-success text-muted">
                            <tr role="row">
                              <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}}>
                                  Name <button onClick={() => {setOrderAndSort('buyer_name');}} className="btn sort-button" style={{paddingLeft: 0, fontSize: '1.2rem', lineHeight: 1}}><span className="sort-arrows-container">{ sortBy == 'buyer_name' && sortOrder == 'asc' ? <i className="ri-arrow-up-s-fill arrow-solid"></i> : <i className="ri-arrow-up-s-fill"></i>}{ sortBy == 'buyer_name' && sortOrder == 'desc' ? <i className="ri-arrow-down-s-fill arrow-solid"></i> : <i className="ri-arrow-down-s-fill"></i>}</span></button>
                                  <div><input id="top_buyer_name" className="form-control form-control-sm" placeholder="Buyer Name" value={buyerName}
                                    onChange={(e) => setBuyerName(e.target.value)} /></div>
                              </th>
                              <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}} >
                                  Profit Status <button onClick={() => {setOrderAndSort('buyer_profit_status');}} className="btn sort-button" style={{paddingLeft: 0, fontSize: '1.2rem', lineHeight: 1}}><span className="sort-arrows-container">{ sortBy == 'buyer_profit_status' && sortOrder == 'asc' ? <i className="ri-arrow-up-s-fill arrow-solid"></i> : <i className="ri-arrow-up-s-fill"></i>}{ sortBy == 'buyer_profit_status' && sortOrder == 'desc' ? <i className="ri-arrow-down-s-fill arrow-solid"></i> : <i className="ri-arrow-down-s-fill"></i>}</span></button>
                                  <div><input id="top_buyer_profit_status" className="form-control form-control-sm" placeholder="Name" value={buyerProfitStatus}
                                          onChange={(e) => setBuyerProfitStatus(e.target.value)} /></div>
                              </th>
                              <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}}>
                                  Sector <button onClick={() => {setOrderAndSort('buyer_sector');}} className="btn sort-button" style={{paddingLeft: 0, fontSize: '1.2rem', lineHeight: 1}}><span className="sort-arrows-container">{ sortBy == 'buyer_sector' && sortOrder == 'asc' ? <i className="ri-arrow-up-s-fill arrow-solid"></i> : <i className="ri-arrow-up-s-fill"></i>}{ sortBy == 'buyer_sector' && sortOrder == 'desc' ? <i className="ri-arrow-down-s-fill arrow-solid"></i> : <i className="ri-arrow-down-s-fill"></i>}</span></button>
                                  <div><input id="top_buyer_sector" className="form-control form-control-sm" placeholder="Type" value={buyerSector} 
                                      onChange={(e) => setBuyerSector(e.target.value)} /></div>
                              </th>
                              <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}}>
                                  Country <button onClick={() => {setOrderAndSort('country');}} className="btn sort-button" style={{paddingLeft: 0, fontSize: '1.2rem', lineHeight: 1}}><span className="sort-arrows-container">{ sortBy == 'country' && sortOrder == 'asc' ? <i className="ri-arrow-up-s-fill arrow-solid"></i> : <i className="ri-arrow-up-s-fill"></i>}{ sortBy == 'country' && sortOrder == 'desc' ? <i className="ri-arrow-down-s-fill arrow-solid"></i> : <i className="ri-arrow-down-s-fill"></i>}</span></button>
                                  <div><input id="top_buyer_country" className="form-control form-control-sm" placeholder="Country" value={country} 
                                      onChange={(e) => setCountry(e.target.value)} /></div>
                              </th>
                              
                              <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}}>action</th>
                            </tr>
                        </thead>

                        

                        { buyerList?.length ? (
                        <tbody id="buyer_table_body" role="rowgroup">
                          {buyerList.map((buyer) => (
                            
                            <tr key={buyer?.buyer_id}>
                              <td role="cell" style={{minWidth: '0px', width: '150px'}}>
                                <div className='fw-medium link-primary'>{buyer?.buyer_name}</div>
                              </td>
                              <td role="cell" style={{minWidth: '0px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                                <div className='fw-medium link-primary'>{buyer?.buyer_profit_status}</div> 
                              </td>
                              <td role="cell" style={{minWidth: '0px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis'}} >{buyer?.buyer_sector}</td>
                              <td role="cell" style={{minWidth: '0px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis'}} >{buyer?.country_name}</td>
                              <td role="cell" style={{minWidth: '0px', width: '150px'}}><ul className="list-inline hstack gap-2 mb-0"><li className="list-inline-item edit"><a onClick={() => handleCustomerClick(buyer)} className="text-primary d-inline-block edit-item-btn" href="#"><i className="ri-pencil-fill fs-16"></i></a></li><li className="list-inline-item"><a onClick={() => onClickDelete(buyer)} className="text-danger d-inline-block remove-item-btn" href="#"><i className="ri-delete-bin-5-fill fs-16"></i></a></li></ul></td>
                              
                            </tr>
                            
                          ))}    
                        </tbody>
                        ) : !error ? (
                          <div className={buyerListInitializing?'d-none':''} style={{padding:'20px'}}>No buyers found</div>
                        ) : (
                          <>
                          <Loader error={error} />
                          </>
                        )}
                        

                      </table>
                    </div>
                    {buyerCount ? (
                  <div className="justify-content-md-end justify-content-center align-items-center p-2 row">
                    <div className="col">Total Results : {buyerCount} </div>

                    {buyerCount > 10 && (
                    <>
                    <div className="col-md-auto col">
                      <div className="d-flex gap-1">
                        <button id="prev_page_btn" onClick={() => { getPage(currentPage - 1); }} type="button" disabled={currentPage == 1 && ('disabled')} className={currentPage == 1 ? 'disabled btn btn-primary' : 'btn btn-primary'}>&lt;</button>
                      </div>
                    </div>
                    <div className="col-md-auto d-none d-md-block col">Page <strong>{currentPage} of {Math.ceil(buyerCount/buyersPerPage)}</strong></div>
                    <div className="col-md-auto col">
                    <input ref={paginationInput} onChange={(e) => { handlePaginationInputChange(e.target); }}  type="number" className="form-control" defaultValue={1} max={Math.ceil(buyerCount/buyersPerPage)} style={{width: '70px'}} />
                    </div>
                    <div className="col-md-auto col">
                      <div className="d-flex gap-1">
                        <button id="next_page_btn" onClick={() => { getPage(currentPage + 1); }} type="button" disabled={currentPage == Math.ceil(buyerCount/buyersPerPage) && ('disabled')} className={currentPage == Math.ceil(buyerCount/buyersPerPage) ? "disabled btn btn-primary" : "btn btn-primary"}>&gt;</button>
                      </div>
                    </div>
                    </>
                    )}
                  </div>
                  ) : ''}
                  </CardBody>

                  
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(BuyerList);
