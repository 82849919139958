import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Buyer Redux States
import {
    GET_RESPONDENT_USERS_LIST, INVITE_RESPONDENT, INVITE_RESPONDENT_SUCCESS,
} from "./actionType";
import {
    RespondentUserApiResponseSuccess,
    RespondentUserApiResponseError, ResponseLoading, toggleInviteRespondentModal,
} from "./action";

//Include Both Helper File with needed methods
import {
    getRespondentUsersList as getRespondentUsersListApi, inviteRespondent as inviteRespondentApi,
} from "../../helpers/backend_helper";

function* getRespondentUsersList(params) {
    try {
      console.log(params.payload);
      const response = yield call(getRespondentUsersListApi, params.payload);
      console.log("respondent user", response);
      yield put(RespondentUserApiResponseSuccess(GET_RESPONDENT_USERS_LIST, response));
    } catch (error) {
      console.log(error);
      yield put(RespondentUserApiResponseError(GET_RESPONDENT_USERS_LIST, error));
    }
  }


function* inviteRespondent(params) {
    try {
        yield put(ResponseLoading(INVITE_RESPONDENT, true));
        yield put(toggleInviteRespondentModal(true));
        const response = yield call(inviteRespondentApi, params.payload);
        yield put(RespondentUserApiResponseSuccess(INVITE_RESPONDENT, response));
        yield put(ResponseLoading(INVITE_RESPONDENT, false));
        yield put(toggleInviteRespondentModal(false));
        toast.success("Invite Sent Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(RespondentUserApiResponseError(INVITE_RESPONDENT, error));
        yield put(toggleInviteRespondentModal(false));
        console.log(error.status)
        // if(error.status == 400){
        //   toast.error("User Already Exist", { autoClose: 3000 });
        // }
        // else{
        //   toast.error("Something went wrong", { autoClose: 3000 });
        // }
        error.status == 400 ? toast.error("User Already Exist", { autoClose: 3000 }) : toast.error("Something went wrong", { autoClose: 3000 });
        console.log(error);
    }
}

  export function* watchGetRespondentUsersList() {
    yield takeEvery(GET_RESPONDENT_USERS_LIST, getRespondentUsersList);
  }

  export function* watchInviteRespondent() {
    yield takeEvery(INVITE_RESPONDENT, inviteRespondent);
  }

  function* respondentUserSaga() {
    yield all([
      fork(watchGetRespondentUsersList),
      fork(watchInviteRespondent),
    ]);
  }
  
  export default respondentUserSaga;