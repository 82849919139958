import {
  GET_BUYER_LIST,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_BUYER,
  ADD_BUYER_SUCCESS,
  ADD_BUYER_FAIL,
  UPDATE_BUYER,
  UPDATE_BUYER_SUCCESS,
  UPDATE_BUYER_FAIL,
  DELETE_BUYER,
  DELETE_BUYER_SUCCESS,
  DELETE_BUYER_FAIL,
  GET_SECTOR_LIST,
} from "./actionType";

// common success
export const BuyerApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const BuyerApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getBuyerList = (params) => ({
  type: GET_BUYER_LIST,
  payload: params,
});

export const updateBuyer = (buyer) => ({
  type: UPDATE_BUYER,
  payload: buyer,
});

export const updateBuyerSuccess = (buyer) => ({
  type: UPDATE_BUYER_SUCCESS,
  payload: buyer,
});

export const updateBuyerFail = (error) => ({
  type: UPDATE_BUYER_FAIL,
  payload: error,
});

export const addNewBuyer = (buyer) => ({
  type: ADD_NEW_BUYER,
  payload: buyer,
});

export const addBuyerSuccess = (buyer) => ({
  type: ADD_BUYER_SUCCESS,
  payload: buyer,
});

export const addBuyerFail = (error) => ({
  type: ADD_BUYER_FAIL,
  payload: error,
});
export const deleteBuyer = (buyer) => ({
  type: DELETE_BUYER,
  payload: buyer,
});

export const deleteBuyerSuccess = (buyer) => ({
  type: DELETE_BUYER_SUCCESS,
  payload: buyer,
});

export const deleteBuyerFail = (error) => ({
  type: DELETE_BUYER_FAIL,
  payload: error,
});

export const getSectorList = (params) => {
  return {
    type: GET_SECTOR_LIST,
    payload: params,
  };
};

