import React from 'react';
import {
  Container,
  CardHeader,
  Row,
  Card,
  CardBody, 
} from 'reactstrap'; 
 











const RespondentMyDataPublic = () => {


    
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
        
            <Row>
            <Card>
            <CardHeader className="align-items-center d-flex">
                        <h4>Message to Non-Users</h4>
                        </CardHeader>


                        
                        <CardBody className="p-3">
                            <p>
                            Content here.
                            </p>
                        </CardBody>
                
                
                
                
                
                
                
                </Card>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default RespondentMyDataPublic;
