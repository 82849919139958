import React from 'react';

import { Col, Container, Row } from 'reactstrap';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Images
import vertis from "../../assets/images/clients/vertis.jpg";
import verra from "../../assets/images/clients/verra.jpg";
import skoll from "../../assets/images/clients/skoll.png";
import art from "../../assets/images/clients/ART.jpg";
import strive from "../../assets/images/clients/strive.png";
import radicle from "../../assets/images/clients/radicle.jpg";
import thenatureconservancy from "../../assets/images/clients/thenatureconservancy.jpg";
import goldstandard from "../../assets/images/clients/goldstandard.jpg";
import three from "../../assets/images/clients/3degrees.jpg";
import fsc from "../../assets/images/clients/fsc.jpg";
import biofilica from "../../assets/images/clients/biofilica.jpg";
import livelihood from "../../assets/images/clients/livelihood.jpg";
import everland from "../../assets/images/clients/everland.jpg";
import cquest from "../../assets/images/clients/cquest.jpg";
import acrregistry from "../../assets/images/clients/acrregistry.jpg";
import coolefect from "../../assets/images/clients/coolefect.jpg"; 
import arbordayfoundation from "../../assets/images/clients/arbordayfoundation.jpg"; 


const Client = () => {
    return (
        <React.Fragment>
            <div className="pt-3 mt-1">
                <Container>
                    <Row>
                        <Col lg={12}>

                            <div className="text-center mt-5">
                                <h5 className="fs-20 text-muted">Trusted by</h5> 
                                <Swiper
                                    slidesPerView={4}
                                    spaceBetween={1}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    breakpoints={{
                                        576: {
                                            slidesPerView: 2,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                        },
                                        1024: {
                                            slidesPerView: 4,
                                        },
                                    }}
                                    loop={true}
                                    autoplay={{ delay: 1000, disableOnInteraction: false }}
                                    modules={[Pagination, Autoplay]}
                                    className="mySwiper swiper trusted-client-slider mt-sm-5 mt-4 mb-sm-5 mb-4"
                                >
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={vertis} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={verra} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={skoll} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={art} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={strive} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={radicle} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={thenatureconservancy} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={goldstandard} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={three} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={everland} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={livelihood} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={biofilica} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={fsc} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={coolefect} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={cquest} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={acrregistry} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="client-images">
                                            <img src={arbordayfoundation} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </SwiperSlide>
                                </Swiper>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Client;