import React from 'react';
import {
  Container,
  CardHeader,
  Row,
  Card,
  CardBody, 
} from 'reactstrap'; 
 











const ProjectsPublic = () => {


    
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
        
            <Row>
            <Card>
                <CardHeader className="align-items-center d-flex">
                <h4>Do we really want to make our projects list public? </h4>
                </CardHeader>
                
                <CardBody className="p-3">
                    <p>
                    Maybe we put some project data charts here instead?  <br />And a teaser about what registered users can see?
                    </p>
                </CardBody>

                
                
                
                
                
                
                
                </Card>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default ProjectsPublic;
