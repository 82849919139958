import {
  GET_TRADE_LIST,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_TRADE,
  ADD_TRADE_SUCCESS,
  ADD_TRADE_FAIL,
  UPDATE_TRADE,
  UPDATE_TRADE_SUCCESS,
  UPDATE_TRADE_FAIL,
  DELETE_TRADE,
  DELETE_TRADE_SUCCESS,
  DELETE_TRADE_FAIL,
  BACKFILL_CURRENCY_CONVERSION,
} from "./actionType";

// common success
export const TradeApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const TradeApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getTradeList = (params) => ({
  type: GET_TRADE_LIST,
  payload: params,
});

export const updateTrade = (trade) => ({
  type: UPDATE_TRADE,
  payload: trade,
});

export const updateTradeSuccess = (trade) => ({
  type: UPDATE_TRADE_SUCCESS,
  payload: trade,
});

export const updateTradeFail = (error) => ({
  type: UPDATE_TRADE_FAIL,
  payload: error,
});

export const addNewTrade = (trade) => ({
  type: ADD_NEW_TRADE,
  payload: trade,
});

export const addTradeSuccess = (trade) => ({
  type: ADD_TRADE_SUCCESS,
  payload: trade,
});

export const addTradeFail = (error) => ({
  type: ADD_TRADE_FAIL,
  payload: error,
});
export const deleteTrade = (trade) => ({
  type: DELETE_TRADE,
  payload: trade,
});

export const deleteTradeSuccess = (trade) => ({
  type: DELETE_TRADE_SUCCESS,
  payload: trade,
});

export const deleteTradeFail = (error) => ({
  type: DELETE_TRADE_FAIL,
  payload: error,
});

export const backFillCurrencyConversionValues = (params) => {
  return {
    type: BACKFILL_CURRENCY_CONVERSION,
    payload: params,
  };
};
