import {
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    GET_RESPONDENT_LIST,
} from "./actionType";

// common success
export const RespondentApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  });
  // common error
  export const RespondentApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
  });

  export const getRespondentList = (params) => ({
    type: GET_RESPONDENT_LIST,
    payload: params,
  });

  // export const getRespondentUsersList = ( params) => ({
  //   type: GET_RESPONDENT_USERS_LIST,
  //   payload: params,
  // });