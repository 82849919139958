import {
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    GET_RESPONDENT_USERS_LIST,
    INVITE_RESPONDENT, API_RESPONSE_LOADING, TOGGLE_INVITE_RESPONDENT_MODAL,
} from "./actionType";

//common loading
export const ResponseLoading = (actionType, data) => ({
    type: API_RESPONSE_LOADING,
    payload: { actionType, data },
});
// common success
export const RespondentUserApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  });
  // common error
  export const RespondentUserApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
  });

  export const getRespondentUsersList = ( params) => ({
    type: GET_RESPONDENT_USERS_LIST,
    payload: params,
  });

  export const inviteRespondent = (data) => ({
    type: INVITE_RESPONDENT,
    payload: data,
  });

  export const toggleInviteRespondentModal = (data) => ({
    type: TOGGLE_INVITE_RESPONDENT_MODAL,
    payload: data,
  });