// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_TRADE_LIST = "GET_TRADE_LIST";

/**
 * Add TRADE
 */
export const ADD_NEW_TRADE = "ADD_NEW_TRADE";
export const ADD_TRADE_SUCCESS = "ADD_TRADE_SUCCESS";
export const ADD_TRADE_FAIL = "ADD_TRADE_FAIL";

/**
 * Edit TRADE
 */
export const UPDATE_TRADE = "UPDATE_TRADE";
export const UPDATE_TRADE_SUCCESS = "UPDATE_TRADE_SUCCESS";
export const UPDATE_TRADE_FAIL = "UPDATE_TRADE_FAIL";

/**
 * Delete TRADE
 */
export const DELETE_TRADE = "DELETE_TRADE";
export const DELETE_TRADE_SUCCESS = "DELETE_TRADE_SUCCESS";
export const DELETE_TRADE_FAIL = "DELETE_TRADE_FAIL";

/** 
 * Backfill Currenty Conversion Values
 * 
 */
export const BACKFILL_CURRENCY_CONVERSION = "BACKFILL_CURRENCY_CONVERSION";
