import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Alert,
  Col,
  Container,
  CardHeader,
  Row,
  Card,
  CardBody,
} from 'reactstrap';

import axios from 'axios';
import { api } from '../../config';
import { useSelector } from 'react-redux';

 
import TotalVolumeBarChartPrivate from '../Charts/components/TotalVolume-BarChart-Private';
import VWAPLineChartPrivate from '../Charts/components/VWAP-Line-Chart-Private';
import VolumeByCountryPrivate from '../Charts/components/VolumeByCountryPrivate'; 

import TotalVolumeBarChartPublic from '../Charts/components/TotalVolume-BarChart-Public';
import VWAPLineChartPublic from '../Charts/components/VWAP-Line-Chart-Public';
import VolumeByCountryMapPublic from '../Charts/components/VolumeByCountry-Map-Public';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-P7N889SGDD');
ReactGA.send({ hitType: "pageview", page: "/dashboard", title: "Respondent Home Page" });


const RespondentLandingPage = () => {
  const [dashboardData, setDashboardData] = useState();
  const [timetable, setTimeTable] = useState();
  const { user } = useSelector((state) => ({
    user: state.Login.user,
  }));
  const { Login } = useSelector((state) => ({
    Login: state.Login,
  }));

  const getDashboardData = async (respondent_id) => {
    if( respondent_id ){
      try {
        const response = await axios.post(
          `${api?.API_URL}/api/respondent-dashboard/`,
          { respondent_id }
        );
        setDashboardData(response);
      } catch (error) {
        console.log(error);
      }
    }
  };

  

  const getDate = () => {
    let date = new Date(
      dashboardData?.recent_transactions_date
    ).toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    let d = new Date(
      dashboardData?.recent_transactions_date
    ).toLocaleDateString('en-US', { day: 'numeric' });
    let compeleteDate = { day: d, monthyear: date };
    setTimeTable(compeleteDate);
  };

  useEffect(() => {
    getDate();
  }, [dashboardData]);
  
  useEffect(() => {
    getDashboardData(user?.respondent_id);
  }, []);

  document.title = 'Dashboard | Ecosystem Marketplace';
  let isViewer = false;
  if( user?.role == 3 ){
    isViewer = true;
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
          <Row>
            <Col xl={12}>
              <Card className='bg-primary'>
                <CardBody className='p-0'>
                  <Row className='align-items-end'>
                    <Col sm={10} className=''>
                      <div className='p-3 ps-4'>
                        <h1 className='display-6 fw-bold text-white'>
                        Global Carbon Markets Hub
                        </h1>
                        <h2 className="text-white">By Ecosystem Marketplace</h2>
                        <p className='fs-16 lh-base text-white'>
                        EM's Global Carbon Markets Hub is the largest publicly available database of carbon market transactions providing graphics, key metrics, and information on the VCM to the public. Ecosystem Marketplace has earned the trust of carbon market stakeholders by tracking this data for almost 20 years - part of our commitment to creating a more transparent and accessible carbon market for those who want to enhance their understanding of carbon markets through data insights.
                        
                        </p>

                        { Login?.maxTradeDate == 0 && (
                          <Alert color="danger">
                        
                        <p className="fs-16 lh-base" style={{marginBottom: 0}}>
                          <i className='ri-information-line me-2 align-middle fs-16'></i>
                          After you submit trade data, you will have access to more recent market data, as well as additional charts and graphs.<br />
                          <Link to="/trade/list" className="text-white"><button type="button" className="btn btn-success add-btn mt-3" id="create-btn"><i className="ri-add-line align-bottom "></i> Submit Trade Data</button></Link>
                        </p>
                        
                        </Alert> 
                        )}
                      </div>
                    </Col> 
                  </Row>
                 
                </CardBody>
                
              </Card>
              
              <Card style={{boxShadow: 'none'}}>
                <CardHeader className="align-items-center d-flex" style={{borderBottom: 'none'}}><h2 className="mb-10 flex-grow-1">Market Overview</h2></CardHeader>
                

                <CardBody className="p-10"  style={{maxWidth:'1385px', paddingTop: '0'}}>
                
                Hover over the interactive graphs below to explore Ecosystem Marketplace's carbon credit volume and price data.
                </CardBody>
       
                
              </Card>
              
            </Col>
          </Row>

          { Login?.maxTradeDate == 0 ? (
            <>
              <Row>
                <Col>
                  <Card>
                    <CardBody className="p-0">
                      <TotalVolumeBarChartPublic />
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <CardBody className="p-0">
                      <VWAPLineChartPublic />
                  </CardBody>
                </Col>
              </Row>
              <Row>
                <Card>
                  <CardBody className="p-0">
                      <VolumeByCountryMapPublic />
                  </CardBody>
                </Card>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col>
                  <Card>
                    <CardBody className="p-0">
                      <TotalVolumeBarChartPrivate />
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <CardBody className="p-0">
                      <VWAPLineChartPrivate />
                  </CardBody>
                </Col>
              </Row>

              <Row>
                <Card>
                  <CardBody className="p-0">
                      <VolumeByCountryPrivate />
                  </CardBody>
                </Card>
              </Row>
            </>
          )}
          

          
          
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RespondentLandingPage;
