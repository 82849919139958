import React, { useState, useEffect } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { PortfolioCharts } from './DashboardCryptoCharts';

import axios from 'axios';

import { URL } from '../../constants';

const MyPortfolio = ({ dashboardData }) => {
  const [chartType, setChartType] = useState('usd');
  const [chartLabels, setchartLabels] = useState([]);
  const [chartData, setchartData] = useState([]);
  const [chartColour, setChartColour] = useState([]);
  const [chartVolumnData, setVolumnChartData] = useState([]);
  const [list, setList] = useState('');
  const [listVolumn, setListVolumn] = useState('');

  const [categoryData, setCategoryData] = useState([]);
  const [dataReady, setDataReady] = useState(false);

  const showCategoryColourList = (uniqueCategory, categoryList, catData) => {
    let content = [];
    console.log('categoryList', categoryList);
    console.log('catData', catData);
    console.log('uniqueCategory', uniqueCategory);
    for (const key in uniqueCategory) {
      // categoryList?.map((category) => {
      //   if (key?.toLowerCase() === 'null_category') {
      //     content?.push('#13220f');
      //   } else if (category?.category?.toLowerCase() === key?.toLowerCase()) {
      //     content?.push(category?.colour);
      //   }
      // });
      catData?.map((category) => {
        if (key?.toLowerCase() === 'null_category') {
          content?.push('#13220f');
        } else if (category?.categoryname?.toLowerCase() === key?.toLowerCase()) {
          content?.push(category?.color_med);
        }
      });
    }
    return [...new Set(content)];
  };

  const showCategorySeriesList = (uniqueCategory, categoryList) => {
    let content = [];
    for (const key in uniqueCategory) {
      for (var i = 0; i < categoryList.length; i++) {
        if (key?.toLowerCase() === 'null_category') {
          content?.push(parseInt(uniqueCategory[key]));
          break;
        } else if (
          categoryList[i]?.category?.toLowerCase() === key?.toLowerCase()
        ) {
          content?.push(parseInt(categoryList[i]?.total_value_usd));
          break;
        }
      }
    }
    return [...new Set(content)];
  };

  const showCategorySeriesVolumnList = (uniqueCategory, categoryList) => {
    let content = [];
    for (const key in uniqueCategory) {
      for (var i = 0; i < categoryList.length; i++) {
        if (key?.toLowerCase() === 'null_category') {
          content?.push(parseInt(uniqueCategory[key]));
          break;
        } else if (
          categoryList[i]?.category?.toLowerCase() === key?.toLowerCase()
        ) {
          content?.push(parseInt(categoryList[i]?.total_valume_sum));
          break;
        }
      }
    }
    return [...new Set(content)];
  };

  const showCategoryList = (category) => {
    let content = '';
    for (const key in category) {
      let label = key;
      if( label == 'null_category' ){
        label = 'Uncategorized';
      }
      content +=
        "<li class='list-group-item px-0'><div class='d-flex'><div class='flex-grow-1 ms-2'><h6 class='mb-1'>";
      content += label;
      content +=
        "</h6></div><div class='flex-shrink-0 text-end'><h6 class='mb-1'>";
      content += parseInt(category[key])?.toLocaleString();
      content += '</h6></div></div></li>';
    }
    return content;
  };

  const getUniqueLabels = (category) => {
    let content = [];
    for (const key in category) {
      let label = key;
      if( label == 'null_category' ){
        label = 'Uncategorized';
      }
      content.push(label);
    }
    return content;
  };

  useEffect(() => {
    if( dataReady ){
      setchartLabels(
        getUniqueLabels(dashboardData?.group_by_category?.unique_categories)
      );
      setchartData(
        showCategorySeriesList(
          dashboardData?.group_by_category?.unique_categories,
          dashboardData?.group_by_category?.data
        )
      );
      setVolumnChartData(
        showCategorySeriesVolumnList(
          dashboardData?.group_by_category?.unique_categories,
          dashboardData?.group_by_category?.data
        )
      );
      setChartColour(
        showCategoryColourList(
          dashboardData?.group_by_category?.unique_categories,
          dashboardData?.group_by_category?.data,
          categoryData
        )
      );

      dashboardData?.group_by_category?.unique_categories &&
        setList(
          showCategoryList(dashboardData?.group_by_category?.unique_categories)
        );
      dashboardData?.group_by_category?.unique_categories &&
        setListVolumn(
          showCategoryList(
            dashboardData?.group_by_category?.unique_categories_volume
          )
        );
    }
  }, [dashboardData, dataReady]);


  useEffect(() => {
    axios.get(URL.get_hub_categories).then((response) => {
        setCategoryData(response.data);
    });
  }, []);

  useEffect(() => {
    if( dashboardData && categoryData.length > 0){
      setDataReady(true);
    }
  }, [dashboardData, categoryData]);

  

  return (
    <React.Fragment>
      <div className=''>
        <div className='card card-height-100'>
          <div className='card-header border-0 align-items-center d-flex'>
            <h4 className='card-title mb-0 flex-grow-1'>Portfolio</h4>
            <div>
              <UncontrolledDropdown direction='start'>
                <DropdownToggle
                  tag='button'
                  className='btn btn-soft-primary btn-sm'
                >
                  <span className='text-uppercase'>
                    {chartType?.toUpperCase()}{' '}
                    <i className='mdi mdi-chevron-down align-middle ms-1'></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu dropdown-menu-end'>
                  <DropdownItem onClick={() => setChartType('TCO2e')}>
                    TCO2e
                  </DropdownItem>
                  <DropdownItem onClick={() => setChartType('usd')}>
                    USD
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
          <div className='card-body'>
            {chartType === 'usd' && (
              <PortfolioCharts
                series={chartData}
                chartLabels={chartLabels}
                dataColors={chartColour}
              />
            )}
            {chartType === 'TCO2e' && (
              <PortfolioCharts
                series={chartVolumnData}
                chartLabels={chartLabels}
                dataColors={chartColour}
              />
            )}
            <ul
              className='list-group list-group-flush border-dashed mb-0 mt-3 pt-2'
              dangerouslySetInnerHTML={{
                __html: chartType === 'TCO2e' ? listVolumn : list,
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MyPortfolio;
