import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { URL } from '../../constants';
import axios from 'axios';

const DeleteModal = ({
  show,
  onDeleteClick,
  onCloseClick,
  currentTrade,
}) => {
  const [isMail, setIsMail] = useState(false);
  const [message, setMessage] = useState('');
  const [isConfirmation, setIsConfirmation] = useState(false);
  const { Login } = useSelector((state) => state);
  //console.log({ currentTrade });

  const defaultMessage = '<br><br>Trade ID: '+currentTrade?.trade_id+'<br>Respondent: '+currentTrade?.respondent?.respondent_name;
  const email = Login?.user?.email;

  useEffect(() => {
    const diffTime = Math.abs(new Date() - new Date(currentTrade?.created_at));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 14) setIsMail(true);
  }, [currentTrade]);

  const sendTradeUpdateOrDeleteEmail = (message) => {
    if( ! message ){
      message = 'Please delete this trade.';
    }
    let messageToSend = 'User with email '+email+' has requested that we update or delete a trade.<br>';
    messageToSend += 'Here is the message they entered:<br><strong>'+message+'</strong><br><br>';
    messageToSend += 'And here is the trade and respondent info: '+defaultMessage;
    axios.get(URL.send_trade_update_or_delete_request+'?user_email='+email+'&current_url='+window.location.href+'&message='+messageToSend);
    
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className='py-3 px-5'>
        {!isMail ? (
          <>
            <div className='mt-2 text-center'>
              <div className='mt-4 pt-2 fs-15 mx-4 mx-sm-5'>
                <h4>Are you sure?</h4>
                <p className='text-muted mx-4 mb-0'>
                  Deletion is permanent. Are you 100% sure?
                </p>
              </div>
            </div>
            <div className='d-flex gap-2 justify-content-center mt-4 mb-2'>
              <button
                type='button'
                className='btn w-sm btn-light'
                data-bs-dismiss='modal'
                onClick={onCloseClick}>
                Close
              </button>
              <button
                type='button'
                className='btn w-sm btn-danger '
                id='delete-record'
                onClick={onDeleteClick}>
                Yes, Delete It!
              </button>
            </div>
          </>
        ) : isConfirmation ? (
          <div className='d-block w-full'>
            <p className='mb-3 text-center'>
              <h3>Submitted!</h3>  We will review your request and
              get back to you shortly.
            </p>
            <div className='d-flex gap-2 justify-content-center mt-4 mb-2'>
              <button
                type='button'
                className='btn w-sm btn-danger '
                data-bs-dismiss='modal'
                onClick={() => {
                  setMessage('');
                  onCloseClick();
                }}>
                Close
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className='d-block w-full'>
              <p className='mb-3'>
                To prevent accidental deletions, trades older than 14 days are
                not editable. If you would like to edit or delete this trade,
                please enter it below, and we'll be in touch.{' '}
              </p>
              <textarea
                placeholder='Message'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className='form-control mb-3'></textarea>
              <ul>
                <li>Trade ID: {currentTrade?.trade_id}</li>
                <li>Respondent: {currentTrade?.respondent?.respondent_name}</li>
              </ul>
              <div className='d-flex gap-2 justify-content-center mt-4 mb-2'>
                <button
                  type='button'
                  className='btn w-sm btn-light'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    setMessage('');
                    onCloseClick();
                  }}>
                  Close
                </button>
                <button
                  type='button'
                  className='btn w-sm btn-danger '
                  id='delete-record'
                  onClick={() => { setIsConfirmation(true); sendTradeUpdateOrDeleteEmail(message); 
                  }}>
                  Delete or Edit as Indicated!
                </button>
              </div>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default DeleteModal;
