import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Form advanced
import changeNumber from "./formAdvanced/reducer";

import Trade from "./trades/reducer";
import Buyer from "./buyer/reducer";
import Project from "./project/reducer";
import Respondent from "./respondent/reducer";
import RespondentUser from "./respondentUser/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  changeNumber,
  Trade,
  Buyer,
  Project,
  Respondent,
  RespondentUser,
});

export default rootReducer;
