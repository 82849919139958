import React, { useState, useMemo, useEffect, useCallback } from 'react';
import Select from 'react-select';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import { CSVBoxButton } from '@csvbox/react';
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  UncontrolledCollapse,
  Label,
  Spinner,
} from 'reactstrap';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import uploadIcon from '../../assets/images/icons/icon-upload.svg';

//Import Breadcrumb

import Loader from '../../Components/Common/Loader';
import { getTradeList, deleteTrade, logoutUser, setProjectApiLoading } from '../../store/actions';
import axios from 'axios';
import { api } from '../../config';
import { useProfile } from '../../Components/Hooks/UserHooks';
import { getRespondentList } from '../../helpers/backend_helper';

import { URL } from '../../constants';

const Utilities = () => {

  const { Login } = useSelector((state) => state);
  const { userProfile } = useProfile();
  const [respondentUser, setRespondentUser] = useState();
  const [respondentData, setRespondentData] = useState();
  const [loading, setLoading] = useState(true);
  const [isBulkloading, setIsBulkLoading] = useState(false);
  const [whatIsLoading, setWhatIsLoading] = useState('');

  const {
    
    respondentName,
  } = useSelector((state) => ({
    
    respondentName: state.Login?.user?.respondent_name,
    
  }));

  const dispatch = useDispatch();

  
  if( ! Login?.user?.is_superuser ){
    dispatch(logoutUser());
  } 

  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (Login?.user?.is_superuser) {
      setLoading(true);
      getRespondentList({ limit: 100 })
        .then((res) => {
          const respondent = res.results.map(
            ({ respondent_name, respondent_id }) => ({
              label: respondent_name,
              value: respondent_id,
            })
          );
          setRespondentData(respondent);
          setLoading(false);
        })
        .catch((e) => {
          console.log('🔥🍊🍉 ERROR ', e);
        });
    }
  }, [Login?.user?.is_superuser]);



 let respondent_id = Login?.user?.respondent_id

  


  
  

  

  const isNumeric = (num) => {
    return !isNaN(parseFloat(num)) && isFinite(num);
  }

  



  
  

document.title = 'Utilities';

  






let csvkey = 'eitTHGRmqW7aWOgsUJgza9s76f30ZN';
if( Login?.user?.is_superuser == true ){ 
  csvkey = 'pqu09lCmi09fxgiXAfevIDbHotGOnt';
} 

const fix_trades_with_zero_conversion_rate = () => {
  axios.get(URL.fix_trades_with_zero_conversion_rate)
    .then((response) => {
      console.log(response);
      const resultInfo = document.querySelector('.result-info');
      resultInfo.innerHTML += response.info;
      if( response.info != 'no trades found with zero conversion rate' ){
        fix_trades_with_zero_conversion_rate();
      } else {
        resultInfo.innerHTML += '<h1>Done</h1>';
      }
    });
};

const fix_trades_with_null_conversion_rate = () => {
  axios.get(URL.fix_trades_with_null_conversion_rate)
    .then((response) => {
      console.log(response);
      const resultInfo = document.querySelector('.result-info');
      resultInfo.innerHTML += response.info;
      if( response.info != 'no trades found with null conversion rate' ){
        fix_trades_with_null_conversion_rate();
      } else {
        resultInfo.innerHTML += '<h1>Done</h1>';
      }
    });
};

const reverse_conversion_rates = () => {
  axios.get(URL.reverse_conversion_rates)
    .then((response) => {
      console.log(response);
      const resultInfo = document.querySelector('.result-info');
      resultInfo.innerHTML += '<br><br>Trades remaining to process: '+response.count+'<br><br>';
      resultInfo.innerHTML += response.info;
      if( response.info != 'all trades processed' ){
        reverse_conversion_rates();
      } else {
        resultInfo.innerHTML += '<h1>Done</h1>';
      }
    });
};

const assign_project_ID_to_trade = () => {
  axios.get(URL.assign_project_id_to_trade)
    .then((response) => {
      console.log(response);
      const resultInfo = document.querySelector('.result-info');
      resultInfo.innerHTML += '<br><br>Trades remaining to process: '+response.count+'<br><br>';
      resultInfo.innerHTML += response.info;
      if( response.info != 'all trades processed' ){
        assign_project_ID_to_trade();
      } else {
        resultInfo.innerHTML += '<h1>Done</h1>';
      }
    });
}
 

  
  
  return (
    <>
    <h1>{document.title}</h1>

      

      
      <div className='page-content'>
        <div className='upload-progress-container d-none'>
          <div className='upload-progress-container-inner'>
            <div className='upload-progress'>
              <div className='upload-progress-header'><h5 className="modal-title">Processing Uploaded Data</h5></div>
              <div className='upload-progress-content'>
                <div>
                  <div className="col-8 offset-2 col-md-4 offset-md-4 text-center mt-5">
                      <h3 className="mb-4 font-weight-light">Updating {whatIsLoading} (<span className="current-trades">0</span>/<span className="total-trades"></span>)</h3>
                      <div className="progress mb-3">
                          <div className="progress-bar progress-bar-animated progress-bar-striped" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: 0}}></div>
                      </div>
                      
                      <div id="do-not-close-browser" className="mt-5 small text-muted">
                          <span data-i18n="donot_close_the_browser">The uploaded file is being processed now.<br />Do not close the browser window or navigate away from this page.</span>
                      </div>
                      <div className="col-8 offset-2 col-md-8 offset-md-2 text-center mb-0" id="resubmit-btn">
                          
                      </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        
      </div>


      <Row>
         <Col xl={12} lg={12}>
            <Card>
                <CardHeader>
                <div className='d-flex align-items-center'>
                    <h5 className='card-title mb-0 flex-grow-1'>Upload Trades in Bulk</h5>
                    
                    <div className='d-flex align-items-end flex-shrink-0'>
                    
                      <CSVBoxButton
                          licenseKey={csvkey}
                          user={{
                          user_id: 'default123',
                          }}
                          onImport={(result, data) => {

                          if (result) {
                            console.log('data', data);
                            console.log('result', result);

                              //setIsBulkLoading(true);
                              const totalTradesSpan = document.querySelector('.upload-progress .total-trades');
                              totalTradesSpan.innerHTML = data.row_count;
                              const currentTradeSpan = document.querySelector('.upload-progress .current-trades');
                              const progressDiv = document.querySelector('.upload-progress-container');
                              progressDiv.classList.remove('d-none');
                              const progressBar = document.querySelector('.upload-progress .progress-bar');
                              
                              let tradesWithoutProject = false;
                              let first_trade_id = 0;
                              let first_chunk = true;
                              
                              let upload_chunk = (offset, chunk_size, file, respondent_id, email) => {
                                  console.log('upload_chunk', offset, chunk_size, file, respondent_id, email);
                                  axios
                                  .post(`${api.API_URL}/api/bulk-trade-update/`, { 
                                  raw_link: file,
                                  respondent_id: respondent_id,
                                  user_email: email,
                                  offset: offset,
                                  chunk_size: chunk_size,
                                  })
                                  .then((result) => {
                                  console.log(result);
                                  if( first_chunk === true){
                                      console.log(result);
                                      first_chunk = false;
                                  }

                                  console.log('data', data);
                                  console.log('offset', offset);
                                  console.log('chunk_size', chunk_size);
                                  
                                  if( (offset + chunk_size) < data.row_count ){
                                      currentTradeSpan.innerHTML = offset + chunk_size;
                                      progressBar.style.width = `${((offset + chunk_size) / data.row_count) * 100}%`;
                                      upload_chunk(offset + chunk_size, chunk_size, file, respondent_id, email);
                                  } else {
                                      progressDiv.classList.add('d-none');
                                      
                                      toast(
                                          <div style={{fontSize:'125%'}}>
                                          <br />
                                          <br />
                                          Trades Updated!
                                          </div>,
                                          {
                                          autoClose: false,
                                          position: "top-center",
                                          hideProgressBar: false,
                                          className: "bg-success text-white",
                                          progress: undefined,
                                          toastId: "",
                                          }
                                      )
                                      
                                      
                                  }
                                  })
                                  .catch((error) => {
                                  toast.error(error.data.msg, {
                                      position: 'top-right',
                                      hideProgressBar: false,
                                      progress: undefined,
                                      toastId: '',
                                  });
                                  
                                  
                                  });
                              };

                              let offset = 0;
                              let chunk_size = 5;
                              let file = data.raw_file;
                              // let respondent_id = Login?.user?.is_superuser ? respondentUser ? respondentUser
                              //     : localStorage.getItem('respondentId')
                              //     : Login?.user?.respondent_id;
                              let email = Login?.user?.email;
                              setWhatIsLoading('Trades');
                              upload_chunk(offset, chunk_size, file, respondent_id, email);
                              
                              
                                  
                              
                          } else {
                              console.log('Trade Error');
                              console.log({ result, data });
                              console.log('fail');
                          }
                          }}
                          render={(launch) => {
                              return (
                                  <>
                                  
                                      <button
                                      data-csvbox
                                      
                                      onClick={() => {
                                          
                                          launch();
                                          
                                      }}
                                      className='btn btn-success add-btn me-2'
                                      >
                                      <img src={uploadIcon} width="20" /> &nbsp;&nbsp;Upload Bulk Trade Update File
                                      </button>
                              
                                  </>
                              );
                          }}
                      >
                        Import
                    </CSVBoxButton>
                    {' '}
                    </div>
                </div>
                <hr />
                <div className='d-flex align-items-center'>
                    <h5 className='card-title mb-0 flex-grow-1'>Upload Projects in Bulk</h5>
                    
                    <div className='d-flex align-items-end flex-shrink-0'>
                    
                    <CSVBoxButton
                        licenseKey='05CEDdNlofAmbCMTiyijWZaXIRDPGI'
                        user={{
                        user_id: 'default123',
                        }}
                        onImport={(result, data) => {
                          console.log(result);
                          console.log(data);

                          if (result) {

                              //setIsBulkLoading(true);
                              const totalTradesSpan = document.querySelector('.upload-progress .total-trades');
                              totalTradesSpan.innerHTML = data.row_count;
                              const currentTradeSpan = document.querySelector('.upload-progress .current-trades');
                              const progressDiv = document.querySelector('.upload-progress-container');
                              progressDiv.classList.remove('d-none');
                              const progressBar = document.querySelector('.upload-progress .progress-bar');
                              
                              
                              let first_chunk = true;
                              
                              
                              let upload_projects_chunk = (offset, chunk_size, file, email) => {
                                  console.log('upload_projects_chunk', offset, chunk_size, file, email);
                                  axios
                                  .post(`${api.API_URL}/api/bulk-project-update/`, { 
                                  raw_link: file,
                                  user_email: email,
                                  offset: offset,
                                  chunk_size: chunk_size,
                                  })
                                  .then((result) => {
                                  console.log(result);
                                  if( first_chunk === true){
                                      console.log(result);
                                      first_chunk = false;
                                  }
                                  
                                  if( (offset + chunk_size) < data.row_count ){
                                      currentTradeSpan.innerHTML = offset;
                                      progressBar.style.width = `${((offset + chunk_size) / data.row_count) * 100}%`;
                                      upload_projects_chunk(offset + chunk_size, chunk_size, file, email);
                                  } else {
                                      progressDiv.classList.add('d-none');
                                      
                                      toast(
                                          <div style={{fontSize:'125%'}}>
                                          <br />
                                          <br />
                                          Projects Updated!
                                          </div>,
                                          {
                                          autoClose: false,
                                          position: "top-center",
                                          hideProgressBar: false,
                                          className: "bg-success text-white",
                                          progress: undefined,
                                          toastId: "",
                                          }
                                      )
                                      
                                      
                                  }
                                  })
                                  .catch((error) => {
                                  toast.error(error.data.msg, {
                                      position: 'top-right',
                                      hideProgressBar: false,
                                      progress: undefined,
                                      toastId: '',
                                  });
                                  
                                  
                                  });
                              };

                              let offset = 0;
                              let chunk_size = 5;
                              let file = data.raw_file;
                              
                              let email = Login?.user?.email;
                              setWhatIsLoading('Projects');
                              upload_projects_chunk(offset, chunk_size, file, email);
                              
                              
                                  
                              
                          } else {
                              console.log('Project Error');
                              console.log({ result, data });
                              console.log('fail');
                          }
                        }}
                        render={(launch) => {
                            return (
                                <>
                                
                                    <button
                                    data-csvbox
                                    
                                    onClick={() => {
                                        
                                        launch();
                                        
                                    }}
                                    className='btn btn-success add-btn me-2'
                                    >
                                    <img src={uploadIcon} width="20" /> &nbsp;&nbsp;Upload Bulk Project Update File
                                    </button>
                            
                                </>
                            );
                        }}
                    >
                        Import
                    </CSVBoxButton>
                    {' '}
                    </div>
                </div>

                <hr />
                <div className='d-flex align-items-center'>
                    <h5 className='card-title mb-0 flex-grow-1'>Find Missing Projects for Trades in Bulk</h5>
                    
                    <div className='d-flex align-items-end flex-shrink-0'>
                    
                    <CSVBoxButton
                        licenseKey='2CabqPJIKmEU85kRVFNRK3VwHMtWRr'
                        user={{
                        user_id: 'default123',
                        }}
                        onImport={(result, data) => {
                          console.log(result);
                          console.log(data);

                          if (result) {

                              //setIsBulkLoading(true);
                              const totalTradesSpan = document.querySelector('.upload-progress .total-trades');
                              totalTradesSpan.innerHTML = data.row_count;
                              const currentTradeSpan = document.querySelector('.upload-progress .current-trades');
                              const progressDiv = document.querySelector('.upload-progress-container');
                              progressDiv.classList.remove('d-none');
                              const progressBar = document.querySelector('.upload-progress .progress-bar');
                              const resultInfo = document.querySelector('.result-info');
                              
                              
                              let first_chunk = true;
                              
                              
                              let upload_trade_projects_chunk = (offset, chunk_size, file, email) => {
                                  console.log('upload_trade_projects_chunk', offset, chunk_size, file, email);
                                  axios
                                  .post(`${api.API_URL}/api/bulk-trade-project-update/`, { 
                                  raw_link: file,
                                  user_email: email,
                                  offset: offset,
                                  chunk_size: chunk_size,
                                  })
                                  .then((result) => {
                                    console.log(result);
                                    if( first_chunk === true){
                                        console.log(result);
                                        first_chunk = false;
                                    }
                                    resultInfo.innerHTML += result.info;
                                    if( (offset + chunk_size) < data.row_count ){
                                        currentTradeSpan.innerHTML = offset;
                                        progressBar.style.width = `${(offset / data.row_count) * 100}%`;
                                        upload_trade_projects_chunk(offset + chunk_size, chunk_size, file, email);
                                    } else {
                                        progressDiv.classList.add('d-none');
                                        
                                        toast(
                                            <div style={{fontSize:'125%'}}>
                                            <br />
                                            <br />
                                            Trades Updated!
                                            </div>,
                                            {
                                            autoClose: false,
                                            position: "top-center",
                                            hideProgressBar: false,
                                            className: "bg-success text-white",
                                            progress: undefined,
                                            toastId: "",
                                            }
                                        )
                                        
                                        
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  toast.error(error.data, {
                                      position: 'top-right',
                                      hideProgressBar: false,
                                      progress: undefined,
                                      toastId: '',
                                  });
                                  
                                  
                                  });
                              };

                              let offset = 0;
                              let chunk_size = 5;
                              let file = data.raw_file;
                              
                              let email = Login?.user?.email;
                              setWhatIsLoading('Projects');
                              upload_trade_projects_chunk(offset, chunk_size, file, email);
                              
                              
                                  
                              
                          } else {
                              console.log('Project Error');
                              console.log({ result, data });
                              console.log('fail');
                          }
                        }}
                        render={(launch) => {
                            return (
                                <>
                                
                                    <button
                                    data-csvbox
                                    
                                    onClick={() => {
                                        
                                        launch();
                                        
                                    }}
                                    className='btn btn-success add-btn me-2'
                                    >
                                    <img src={uploadIcon} width="20" /> &nbsp;&nbsp;Upload Bulk Trade/Project Update File
                                    </button>
                            
                                </>
                            );
                        }}
                    >
                        Import
                    </CSVBoxButton>
                    {' '}
                    </div>
                </div>


                <hr />
                <div className='d-flex align-items-center d-none'>
                    <h5 className='card-title mb-0 flex-grow-1'>Fix Trades Where Conversion Rate is Zero</h5>
                    
                    <div className='d-flex align-items-end flex-shrink-0'>
                    
                    <button className='btn btn-success add-btn me-2' onClick={ fix_trades_with_zero_conversion_rate }>Go!</button>
                    {' '}
                    </div>
                </div>

                {/* <hr /> */}
                <div className='d-flex align-items-center d-none'>
                    <h5 className='card-title mb-0 flex-grow-1'>Fix Trades Where Conversion Rate is Null</h5>
                    
                    <div className='d-flex align-items-end flex-shrink-0'>
                    
                    <button className='btn btn-success add-btn me-2' onClick={ fix_trades_with_null_conversion_rate }>Go!</button>
                    {' '}
                    </div>
                </div>

                {/* <hr /> */}
                <div className='d-flex align-items-center d-none'>
                    <h5 className='card-title mb-0 flex-grow-1'>Assign the Project ID to trades where the project_name Matches Exactly</h5>
                    
                    <div className='d-flex align-items-end flex-shrink-0'>
                    
                    <button className='btn btn-success add-btn me-2' onClick={ assign_project_ID_to_trade }>Go!</button>
                    {' '}
                    </div>
                </div>

                {/* <hr /> */}
                <div className='d-flex align-items-center d-none'>
                    <h5 className='card-title mb-0 flex-grow-1'>Update Conversion Rates (local price divided by rate = USD instead of local price times rate = USD)</h5>
                    
                    <div className='d-flex align-items-end flex-shrink-0'>
                    
                    <button className='btn btn-success add-btn me-2' onClick={ reverse_conversion_rates }>Go!</button>
                    {' '}
                    </div>
                </div>
                </CardHeader>
            </Card>
        </Col>
    </Row>

    <Row>
      <Col>
        <Card>
          <div className="result-info"></div>
        </Card>
      </Col>
    </Row>





    </>
  );
  
};


export default withRouter(Utilities);
