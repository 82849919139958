import React from 'react';
import {
  Container,
  CardHeader,
  Row,
  Card,
  CardBody, 
} from 'reactstrap'; 
 



import VolumeByCategoryStackedBarChartPublic from '../../Charts/components/VolumeByCategory-StackedBarChart-Public';
import VWAPByCategoryAndYearMultiLineChartPublic from '../../Charts/components/VWAPByCategoryAndYear-MultiLineChart-Public';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-P7N889SGDD');
ReactGA.send({ hitType: "pageview", page: "/categories", title: "Public Categories Page" });






const CategoriesPublic = () => {


    
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
         
            <Row>
            <Card>
                <CardHeader className="align-items-center d-flex">
                <h2 className="mb-10 flex-grow-1">Category Data</h2>
                <div className="flex-shrink-0"></div>
                </CardHeader>
                <CardBody className="p-10"  style={{maxWidth:'1385px'}}>
                
                The graphs on this page break down the annual volume and average price of carbon credit trades by category from Ecosystem Marketplace’s  project taxonomy. For more information, see the <a href="/taxonomy">Taxonomy</a> page.
                </CardBody>
                
                <CardBody className="p-0">
                    <VolumeByCategoryStackedBarChartPublic />
                </CardBody>
                <CardBody className="p-0">
                    <VWAPByCategoryAndYearMultiLineChartPublic />
                </CardBody>
                
                
                
                
                
                
                </Card>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default CategoriesPublic;
