import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

// Login Redux States
import {
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_MAX_TRADE_DATE,
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
} from './actionTypes';
import {
  apiError,
  getCountryListSuccess,
  getMaxTradeDateSuccess,
  loginSuccess,
  logoutUserSuccess,
} from './actions';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
// import {
//   postFakeLogin,
//   postSocialLogin,
// } from '../../../helpers/fakebackend_helper';

import { postJwtLogin, getCountries, getMaxTradeDateAPI } from '../../../helpers/backend_helper';

import {
  setAuthorization,
  removeAuthorization,
} from '../../../helpers/api_helper';

const fireBaseBackend = getFirebaseBackend();

// CHAD AUTH
function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      );
      yield put(loginSuccess(response));
      if (history) history.push('/dashboard');
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      // EM Using this method
      try {
        const token = JSON.parse(sessionStorage.getItem("authUser"))
          ? JSON.parse(sessionStorage.getItem("authUser")).access_token
          : null;
        if ( ! token) {
          logoutUser();
          const response = yield call(postJwtLogin, {
            email: user.email,
            password: user.password,
          });
          sessionStorage.setItem('authUser', JSON.stringify(response));
          setAuthorization(response.access_token);
          yield put(loginSuccess(response));
          if (history) history.push('/dashboard');
        } else {
          setAuthorization(token);
          let response = JSON.parse(sessionStorage.getItem("authUser"));
          yield put(loginSuccess(response));
        }
      } catch (error) {
        yield put(apiError(error));
      }
    }  //  else if (process.env.REACT_APP_API_URL) {
    //   const response = yield call(postFakeLogin, {
    //     email: user.email,
    //     password: user.password,
    //   });
    //   sessionStorage.setItem('authUser', JSON.stringify(response));
    //   if (response.status === 'success') {
    //     yield put(loginSuccess(response));
    //     if (history) history.push('/dashboard');
    //   } else {
    //     yield put(apiError(response));
    //   }
    // }
  } catch (error) {
    yield put(apiError(error));
  }
}

// CHADAUTH
function* logoutUser() {
  try {
    sessionStorage.removeItem('authUser');
    localStorage.userLogout = true;
    
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(LOGOUT_USER, response));
    } else {
      removeAuthorization();
      yield put(logoutUserSuccess(LOGOUT_USER, true));
    }
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, data, type);
      sessionStorage.setItem('authUser', JSON.stringify(response));
      yield put(loginSuccess(response));
    }  //  else {
    //   const response = yield call(postSocialLogin, data);
    //   sessionStorage.setItem('authUser', JSON.stringify(response));
    //   yield put(loginSuccess(response));
    // }
    history.push('/dashboard');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getCountryList(params) {
  try {
    const response = yield call(getCountries, params.payload);
    yield put(getCountryListSuccess(response.results));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* getMaxTradeDate(params) {
  try {
    const response = yield call(getMaxTradeDateAPI, params.payload);
    yield put(getMaxTradeDateSuccess(response.maxYear));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(GET_COUNTRY_LIST, getCountryList);
  yield takeEvery(GET_MAX_TRADE_DATE, getMaxTradeDate);
}

export default authSaga;
