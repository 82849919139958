import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { URL } from '../../../constants';
import axios from 'axios';
import { Col, Row } from 'reactstrap'; 
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const RespondentsVolumeByCategory = () => {

    const [dataReady, setDataReady] = useState(false);

    const { user } = useSelector((state) => ({
        user: state.Login.user,
      }));
    
    const respondent_id = user?.respondent_id;
    const respondent_name = user?.respondent_name;

    const categories = ['Agriculture', 'Chemical Proc. / Industrial Mfg.', 'Energy Efficiency / Fuel Switching', 
        'Forestry and Land Use',  'Household / Community Devices', 'Renewable Energy', 'Transportation', 'Waste Disposal', 'Other', 'Unspecified'];
    const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];



    let initialBarChartOptions = {
        colors: colors,
        chart: {
            type: 'column',
            height: '600',
            marginTop: '80',
        },
        title: {
            text: respondent_name+ ' Volume (tCO2e) by Category and Year',
            align: 'left'
        },
        subtitle: {
            text: 'Click on categories to show/hide them',
            align: 'left'
        },
        xAxis: {
            categories: [2020, 2021, 2022, 2023]
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Volume (tCO2e)'
            },
            stackLabels: {
                enabled: true
            }
        },
        legend: {
            align: 'left',
            x: 70,
            verticalAlign: 'top',
            y: 40,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,

        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal:,.0f}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            }
        },
        series: [],
        }

    const [stackedBarChartOptions, setStackedBarChartOptions] = useState(initialBarChartOptions);

    useEffect(() => {
        axios.get(URL.get_resp_vcm_categories+`/?respondent_id=${respondent_id}`).then((response) => {
            let respVCMCategories = response.data;

            const categories = ['Agriculture', 'Chemical Proc. / Industrial Mfg.', 'Energy Efficiency / Fuel Switching', 
                'Forestry and Land Use',  'Household / Community Devices', 'Renewable Energy', 'Transportation', 'Waste Disposal', 'Other', 'Unspecified'];
            const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];

            let years = [];
            respVCMCategories.forEach((d) => {
                if (!years.includes(d.year_transact)) {
                    years.push(d.year_transact);
                }
            });

            years.sort();

            let volume_by_year_and_category = {};
        
            years.forEach((year) => {
                volume_by_year_and_category[year] = {};
                categories.forEach((category) => {
                    volume_by_year_and_category[year][category] = 0;
                });
                respVCMCategories.forEach((d) => {
                    if (d.year_transact === year) {
                        volume_by_year_and_category[year][d.project_category] += d.volume_tco2e;
                    }
                });
            });

            let volumeBarChartData = [];

            categories.forEach((category) => {
                let volume_obj = {name: category, data: []};
                years.forEach((year) => {
                    volume_obj.data.push(volume_by_year_and_category[year][category]);
                });
                volumeBarChartData.push(volume_obj);
            });


            let xAxisObj = {categories: years};

            
            setStackedBarChartOptions({ ...stackedBarChartOptions, series: volumeBarChartData, xAxis: xAxisObj});
            setDataReady(true);
    
            
        });
    }, []);


    return (
        <React.Fragment>
            
            <style dangerouslySetInnerHTML={{__html: `
            #volume_by_year_by_category_stacked_bar_chart, #vwap_by_year_by_category_line_chart { margin-top: 20px; }
            #volume_by_year_by_category_stacked_bar_chart > figure > div > span { margin-right: 20px; margin-bottom:10px; font-size:16px; }
            #volume_by_year_by_category_stacked_bar_chart > figure > div { max-width:100%; padding:20px 0; }
            #volume_by_year_by_category_stacked_bar_chart g[aria-label="y-axis label"] text { font-size: 24px!important; }
            
            `}} />

            <Row>
                <Col className="col-12 col-lg-12"><div id="sbc">
                {dataReady && <HighchartsReact
                    highcharts={Highcharts}
                    options={stackedBarChartOptions}
                />}</div>
                </Col>
            </Row>

        </React.Fragment>
    );


}

export default RespondentsVolumeByCategory;