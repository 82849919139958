export const REGISTER_USER = "REGISTER_USER";
export const RESPONDENT_REGISTER_USER = "RESPONDENT_REGISTER_USER";
export const REGISTER_USER_SUCCESSFUL = "REGISTER_USER_SUCCESSFUL";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";
export const RESET_REGISTER_FLAG = "RESET_REGISTER_FLAG";

export const REGISTER_VERIFY_EMAIL = "REGISTER_VERIFY_EMAIL";
export const REGISTER_VERIFY_EMAIL_SUCCESSFUL =
  "REGISTER_VERIFY_EMAIL_SUCCESSFUL";
export const REGISTER_VERIFY_EMAIL_FAILED = "REGISTER_VERIFY_EMAIL_FAILED";
export const RESPONDENT_INVITE_VALIDATE = "RESPONDENT_INVITE_VALIDATE_SUCCESSFUL";
export const RESPONDENT_INVITE_VALIDATE_SUCCESSFUL = "RESPONDENT_INVITE_VALIDATE_SUCCESSFUL";
export const RESPONDENT_INVITE_VALIDATE_FAILED = "RESPONDENT_INVITE_VALIDATE_FAILED";