import React, { useEffect, useState } from 'react';
import { URL } from '../../../constants';
import axios from 'axios';
import { Col, Row } from 'reactstrap'; 
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const VWAPLineChartPublic= () => {

    const [dataReady, setDataReady] = useState(false);

    const categories = ['Agriculture', 'Chemical Proc. / Industrial Mfg.', 'Energy Efficiency / Fuel Switching', 
        'Forestry and Land Use',  'Household / Community Devices', 'Renewable Energy', 'Transportation', 'Waste Disposal', 'Other', 'Unspecified'];
    const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];

    let initialLineChartOptions = {
        chart: {
            type: 'line',
            height: '500',
            marginTop: '80',
        },
        colors: colors,
        title: {
            text: 'Price by Year (USD/tCO2e)',
            align: 'left'
        },    
        
        yAxis: {
            title: {
                text: 'Price (USD)'
            }
        },
        xAxis: {
            allowDecimals: false
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },
        plotOptions: {
            line: {
                color: '#3a3b8b',
            },
            series: {
                label: {
                    connectorAllowed: false
                },
                pointStart: 2020,
                
            }
        },
        tooltip: {
            valuePrefix: '$', 
        },
        series: [],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    };

    const [lineChartOptions, setLineChartOptions] = useState(initialLineChartOptions);

    useEffect(() => {
        axios.get(URL.get_volume_and_vwap).then((response) => {
            let volumeAndVWAP = response.data;
            volumeAndVWAP = volumeAndVWAP.reverse();

            const categories = ['Agriculture', 'Chemical Proc. / Industrial Mfg.', 'Energy Efficiency / Fuel Switching', 
                'Forestry and Land Use',  'Household / Community Devices', 'Renewable Energy', 'Transportation', 'Waste Disposal', 'Other', 'Unspecified'];
            const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];

            let years = [];
            volumeAndVWAP.forEach((d) => {
                if (!years.includes(d.year_transact)) {
                    years.push(d.year_transact);
                }
            });

            years.sort();

            let vwap_by_year = {};
            
        
            years.forEach((year) => {
                vwap_by_year[year] = 0;
                
                
                volumeAndVWAP.forEach((d) => {
                    if (d.year_transact === year) {
                        vwap_by_year[year] = d.vwap;
                        
                    }
                });
            });

            let vwapLineData = [];
           
            
            let vwap_obj = {name: 'Average Price by Year', data: []};
                
            years.forEach((year) => {
                vwap_obj.data.push(vwap_by_year[year]);
                
            });
            vwapLineData.push(vwap_obj);
                
            

            
            setLineChartOptions({ ...lineChartOptions, series: vwapLineData });
            setDataReady(true);            
            
        });
    }, []);

    
    
    
        
        
    

            



    return (
        <React.Fragment>
            <Row>
                <Col className="col-12 col-lg-12">
                {dataReady && <HighchartsReact
                    highcharts={Highcharts}
                    options={lineChartOptions}
                />}
                </Col>
            </Row>
        </React.Fragment>
    );


}

export default VWAPLineChartPublic;