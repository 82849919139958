import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { getLoggedinUser, getAccessToken } from "../../helpers/api_helper";
import { authUserSuccess } from "../../store/actions";

const useProfile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({
    user: state.Login.user,
  }));

  const userProfileSession = getLoggedinUser();
  var token = getAccessToken();
  const [loading, setLoading] = useState(userProfileSession ? false : true);
  const [userProfile, setUserProfile] = useState(
    userProfileSession ? userProfileSession : null
  );

  useEffect(() => {
    const userProfileSession = getLoggedinUser();
    var token = getAccessToken();
    setUserProfile(userProfileSession ? userProfileSession : null);
    setLoading(token ? false : true);

    if (isEmpty(user).length) {
      // Set the auth user in redux store if lost state
      dispatch(authUserSuccess(userProfileSession));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { userProfile, loading, token };
};

export { useProfile };
