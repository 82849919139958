import React, { useEffect, useState } from 'react';
import { URL } from '../../../constants';
import axios from 'axios';
import { Col, Row, CardHeader } from 'reactstrap'; 
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from 'highcharts/highcharts-more';
HC_more(Highcharts);


const StandardsScatterWithJitterPrivate = () => { 

    const [seriesData, setSeriesData] = useState([]);
    const [dataReady, setDataReady] = useState(false);
    const [years, setYears] = useState([]);
    const [categories, setCategories] = useState([]);
    const [clusters, setClusters] = useState([]);
    const [yearMin, setYearMin] = useState(0);
    const [yearMax, setYearMax] = useState(0);
    const [catOrdinals, setCatOrdinals] = useState({});

    
    const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];
    const radius_multiplier = 10000;


    let initialBubbleChartOptions = {
        chart: {
            type: 'scatter',
        },

        
        
        colors,

        title: {
            text: '',
        },
        plotOptions: {
            scatter: {
                showInLegend: false,
                jitter: {
                    x: 0.4,
                    y: 0
                },
                marker: {
                    radius: 2,
                    symbol: 'circle'
                },
                tooltip: {
                    pointFormat: 'Measurement: {point.y:.3f}'
                }
            }
        },
        // tooltip: {
        //     useHTML: true,
        //     headerFormat: '<table>',
        //     pointFormat: '<tr><th colspan="2"><h4>{point.cluster}</h4></th></tr>' +
        //         '<tr><th>Category:</th><td>{point.category}</td></tr>' +
        //         '<tr><th>Average Price: </th><td> ${point.vwap}</td></tr>' +
        //         '<tr><th>Volume:</th><td> {point.volume:,.0f} tCO2e</td></tr>',
        //     footerFormat: '</table>',
        //     followPointer: true
        // },
        // colorByPoint: true,
        // colors: colors,

    };

    const [chartOptions, setChartOptions] = useState({});


    
    const updateChart = (initialLoad = false, yearRangeUpdated = false) => {
        if( initialLoad ){
            axios.get(URL.get_resp_category_clusters).then((response) => {
                let categoryClusters = response.data;
                //categoryClusters = tempdata;
                setSeriesData(categoryClusters);

                console.log('categoryClusters', categoryClusters);

                
                let all_years = [];
                let all_categories = [];
                let all_clusters = [];
                let cat_ordinals = {};
                
                let volume_max = 0;
                categoryClusters.forEach((d) => {
                    if (!all_years.includes(d.year_transact)) {
                        all_years.push(d.year_transact);
                    }
                    if (!all_categories.includes(d.project_category)) {
                        all_categories.push(d.project_category);
                        cat_ordinals[d.project_category] = d.cat_ordinal - 1;
                    }
                    if (!all_clusters.includes(d.project_cluster)) {
                        all_clusters.push(d.project_cluster);
                    }
                    if (d.volume_tco2e > volume_max) {
                        volume_max = d.volume_tco2e;
                    }
                    
                    
                });

                all_years.sort();
                all_categories.sort();
                
                setYears(all_years);
                setYearMin(all_years[0]);
                setYearMax(all_years[all_years.length - 1]);
                setCategories(all_categories);
                setClusters(all_clusters);
                setCatOrdinals(cat_ordinals);
                


                //setYears(all_years);
                console.log('years', all_years);

                

                
                let VWAPClusterDataObj = {};

                all_categories.forEach((category) => {
                    VWAPClusterDataObj[category] = {};  
                });

                all_categories.forEach((category) => {
                    all_clusters.forEach((cluster) => {
                        VWAPClusterDataObj[category][cluster] = {x:cat_ordinals[category], y:0, z:0, cluster: cluster, valueUSD: 0, visible: false};
                    });
                });

                console.log('VWAPClusterDataObj', VWAPClusterDataObj);
                
                categoryClusters.forEach((d) => {
                    if( d.vwap ){   
                        VWAPClusterDataObj[d.project_category][d.project_cluster].valueUSD += d.value_USD;
                        VWAPClusterDataObj[d.project_category][d.project_cluster].z += d.volume_tco2e;
                    }
                });

                all_categories.forEach((category) => {
                  all_clusters.forEach((cluster) => {
                    if( VWAPClusterDataObj[category][cluster].z > 0 ){
                        VWAPClusterDataObj[category][cluster].y = VWAPClusterDataObj[category][cluster].valueUSD / VWAPClusterDataObj[category][cluster].z;
                        let radius = Math.pow(VWAPClusterDataObj[category][cluster].z,0.5)/200;
                        if( VWAPClusterDataObj[category][cluster].z < 500000 ){
                            radius = 3.5355339059;
                        }
                      
                        VWAPClusterDataObj[category][cluster].visible = true;
                        VWAPClusterDataObj[category][cluster].marker = {radius: radius, symbol: 'circle'};
                    }
                    });
                });


                // categoryClusters.forEach((d) => {
                    
                //     // VWAPClusterDataObj[d.project_category]['data'].push({
                //     //     name: d.project_cluster,
                //     //     cluster: d.project_cluster,
                //     //     vwap: d.vwap,
                //     //     value: d.vwap,
                //     //     volume: d.volume_tco2e,
                //     //     category: d.project_category,
                //     // });
                //     if( d.vwap ){
                //         let radius = Math.pow(d.volume_tco2e,0.5)/200;
                //         if( d.volume_tco2e < 500000 ){
                //             radius = 3.5355339059;
                //         }
                //         VWAPClusterDataObj[d.project_category][d.project_cluster] = {x:cat_ordinals[d.project_category], y:d.vwap, z:d.volume_tco2e, cluster: d.project_cluster, visible: true, marker: {radius: radius, symbol: 'circle'}};
                //     }
                    
                // });

                
                console.log('VWAPClusterDataObj', VWAPClusterDataObj);

                let VWAPClusterData = [];
                all_categories.forEach((category) => {
                    let clusterData = [];
                    all_clusters.forEach((cluster) => {
                        clusterData.push(VWAPClusterDataObj[category][cluster]);
                    });
                    VWAPClusterData.push({
                        name: category,
                        data: clusterData,

                    });
                });
                
                

                let plot_options = {
                    
                    scatter: {
                        showInLegend: false,
                        jitter: {
                            x: 0.4,
                            y: 0
                        },
                        marker: {
                            radius: 5,
                            symbol: 'circle',
                        },
                        tooltip: {
                            pointFormat: '<strong>{point.cluster}</strong><br />Price: ${point.y:.2f}<br />Volume: {point.z:,.0f} tCO2e'
                        }
                    }
                };

                console.log('VWAPClusterData', VWAPClusterData);
                setChartOptions({ ...initialBubbleChartOptions, xAxis: { categories: categories }, yAxis: { title: { text: 'Price (USD/tCO2e)' } }, series: VWAPClusterData, plotOptions: plot_options, });
                setDataReady(true);
                
                

            });
        } else {
            let VWAPClusterDataObj = {};

            categories.forEach((category) => {
                VWAPClusterDataObj[category] = {};  
            });

            categories.forEach((category) => {
                clusters.forEach((cluster) => {
                    VWAPClusterDataObj[category][cluster] = { x:catOrdinals[category], y:0, z:0, valueUSD:0, cluster: cluster, visible:false};
                });
            });

            console.log('VWAPClusterDataObj', VWAPClusterDataObj);
            
            
            // let volume_max = 0;
            // seriesData.forEach((d) => {
            //     if( d.year_transact >= yearMin && d.year_transact <= yearMax ) {
            //         if( d.volume_tco2e > volume_max ){
            //             volume_max = d.volume_tco2e;
            //         }
            //     }
            // });

            seriesData.forEach((d) => {
              if( d.year_transact >= yearMin && d.year_transact <= yearMax ) {
                if( d.vwap ){   
                    VWAPClusterDataObj[d.project_category][d.project_cluster].valueUSD += d.value_USD;
                    VWAPClusterDataObj[d.project_category][d.project_cluster].z += d.volume_tco2e;
                }
              }
            });
            categories.forEach((category) => {
              clusters.forEach((cluster) => {
                if( VWAPClusterDataObj[category][cluster].z > 0 ){
                    VWAPClusterDataObj[category][cluster].y = VWAPClusterDataObj[category][cluster].valueUSD / VWAPClusterDataObj[category][cluster].z;
                    let radius = Math.pow(VWAPClusterDataObj[category][cluster].z,0.5)/200;
                    if( VWAPClusterDataObj[category][cluster].z < 500000 ){
                        radius = 3.5355339059;
                    }
                  
                    VWAPClusterDataObj[category][cluster].visible = true;
                    VWAPClusterDataObj[category][cluster].marker = {radius: radius, symbol: 'circle'};
                }
                });
            });
            // seriesData.forEach((d) => {
            //     if( d.year_transact >= yearMin && d.year_transact <= yearMax ) {
            //         // VWAPClusterDataObj[d.project_category]['data'].push({
            //         //     name: d.project_cluster,
            //         //     cluster: d.project_cluster,
            //         //     vwap: d.vwap,
            //         //     value: d.vwap,
            //         //     volume: d.volume_tco2e,
            //         //     category: d.project_category,
            //         // });
            //         if( d.vwap ){
            //             let radius = Math.pow(d.volume_tco2e,0.5)/200;
            //             if( d.volume_tco2e < 500000 ){
            //                 radius = 3.5355339059;
            //             }
            //             VWAPClusterDataObj[d.project_category][d.project_cluster] = { x : catOrdinals[d.project_category], y:d.vwap, z:d.volume_tco2e, cluster: d.project_cluster, visible:true, marker: {radius: radius, symbol: 'circle'}};    
            //         }
            //     }
            // });

            

          // all_categories.forEach((category) => {
          //   all_clusters.forEach((cluster) => {
          //     if( VWAPClusterDataObj[category][cluster].z > 0 ){
          //         VWAPClusterDataObj[category][cluster].y = VWAPClusterDataObj[category][cluster].valueUSD / VWAPClusterDataObj[category][cluster].z;
          //         let radius = Math.pow(VWAPClusterDataObj[category][cluster].z,0.5)/200;
          //         if( VWAPClusterDataObj[category][cluster].z < 500000 ){
          //             radius = 3.5355339059;
          //         }
                
          //         VWAPClusterDataObj[category][cluster].visible = true;
          //         VWAPClusterDataObj[category][cluster].marker = {radius: radius, symbol: 'circle'};
          //     }
          //     });
          // });  


            
            console.log('VWAPClusterDataObj', VWAPClusterDataObj);

            let VWAPClusterData = [];
            categories.forEach((category) => {
                let clusterData = [];
                clusters.forEach((cluster) => {
                    clusterData.push(VWAPClusterDataObj[category][cluster]);
                });
                VWAPClusterData.push({
                    name: category,
                    data: clusterData,

                });
            });
            
            

            let plot_options = {
                
                scatter: {
                    showInLegend: false,
                    jitter: {
                        x: 0.4,
                        y: 0
                    },
                    marker: {
                        radius: 2,
                        symbol: 'circle',
                        
                        
                    },
                    tooltip: {
                        pointFormat: '<strong>{point.cluster}</strong><br />Price: ${point.y:.2f}<br />Volume: {point.z:,.0f} tCO2e'
                    }
                }
            };

            console.log('categories', categories);

            console.log('VWAPClusterData2', VWAPClusterData);
            setChartOptions({ ...initialBubbleChartOptions, xAxis: { categories: categories }, yAxis: { title: { text: 'Price (USD/tCO2e)' } }, series: VWAPClusterData, plotOptions: plot_options });

        }
    };

    useEffect(() => {
        updateChart(true);
    }, []);

    useEffect(() => {
        updateChart(false);
    }, [yearMin, yearMax]);

    

       
    

       

        
    

            



    return (
        <React.Fragment>

            
            
            
            <style dangerouslySetInnerHTML={{__html: `
            .highcharts-credits { display:none }
            table td, table th { font-size:14px; padding:4px 8px; }
            #category-and-cluster .highcharts-markers path { opacity:0.55;}
            
            `}} />

<Row>
            <Col className="col-12 col-lg-12"><CardHeader><strong style={{fontSize : "1.4em"}}>Price and Volume by Category and Cluster ({yearMin} - {yearMax})</strong></CardHeader></Col>
            </Row>
            { /* insert years dropdown selector here */ }
            <CardHeader>
                <Row>
                    <Col className="col-12 col-lg-12 dateRangeSelectors">
                        <strong>Date Range: <label style={{ textTransform:"none", marginLeft:"20px", fontWeight: "bold"}}>Year Min</label> </strong>
                        
                        <select id="yearMin" name="yearMin" onChange={(e) => {
                            let selectedYear = e.target.value;
                            console.log('selectedYear', selectedYear);
                            
                            setYearMin(selectedYear);
                        }}>
                            { years.map((year, index) => {
                                if( year <= yearMax ){
                                    return <option key={index} value={year} selected={year == yearMin}>{year}</option>
                                }
                            })}
                            
                        </select>

                        <strong>Year Max </strong>
                        
                        <select id="yearMax" name="yearMax" onChange={(e) => {
                            let selectedYear = e.target.value;
                            console.log('selectedYear', selectedYear);
                            setYearMax(selectedYear);
                        }}>
                            { years.reverse().map((year, index) => {
                                if( year >= yearMin ){
                                    return <option key={index} value={year} selected={year == yearMax}>{year}</option>
                                }
                            })}
                            {years.reverse()}
                            
                        </select>

                        { (yearMin != years[0] || yearMax != years[years.length - 1]) && <><input type="checkbox" id="resetYearRange" name="resetYearRange" value="resetYearRange" onChange={(e) => {
                            if( e.target.checked ){
                                setYearMin(years[0]);
                                setYearMax(years[years.length - 1]);
                                e.target.checked = false;
                            }
                        }} /> Reset Date Range</> }
                    </Col>
                </Row>    

            </CardHeader>

            <Row>
                <Col className="col-12 col-lg-12"><div id="category-and-cluster">
                {dataReady && <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                />}</div>
                </Col>
            </Row>

            
        </React.Fragment>
    );


}

export default StandardsScatterWithJitterPrivate;