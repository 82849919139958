import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { api } from '../../config';
import axios from 'axios';
import { URL } from '../../constants';

// Trade Redux States
import {
  GET_TRADE_LIST,
  ADD_NEW_TRADE,
  DELETE_TRADE,
  UPDATE_TRADE,
  BACKFILL_CURRENCY_CONVERSION,
} from "./actionType";
import {
  TradeApiResponseSuccess,
  TradeApiResponseError,
  addTradeSuccess,
  addTradeFail,
  updateTradeSuccess,
  updateTradeFail,
  deleteTradeSuccess,
  deleteTradeFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getTradeList as getTradeListApi,
  addNewTrade,
  updateTrade,
  deleteTrade,
  backFillCurrencyConversionValues as backFillCurrencyConversionValuesApi,
} from "../../helpers/backend_helper";

function* getTradeList(params) {
  try {
    const response = yield call(getTradeListApi, params.payload);
    console.log('response', response)
    if( response?.results ){
      yield put(TradeApiResponseSuccess(GET_TRADE_LIST, response));
    } else {
      yield put(TradeApiResponseError(GET_TRADE_LIST, response));
    }
  } catch (error) {
    console.log(error);
    yield put(TradeApiResponseError(GET_TRADE_LIST, error));
  }
}



function* onAddNewTrade({ payload }) {
  const trade = payload.data;
  const user_email = payload.user_email;
  const current_url = payload.current_url;
  const respondent_name = payload.respondent_name;

  try {
    const response = yield call(addNewTrade, trade);
    if (payload.callback) {
      payload.callback();
    }
    if( response ){
      console.log('add trade response', response);
      yield put(addTradeSuccess(response));
    } else {
      yield put(addTradeFail(response));
    }
    // TODO: send email notification
    //console.log(user_email, current_url, respondent_name);
    const params = {
        user_email: user_email,
        current_url: current_url,
        respondent_name: respondent_name,
    };
    axios.get(URL.send_new_trade_notification+'?user_email='+user_email+'&current_url='+current_url+'&respondent_name='+respondent_name);
    toast.success("Trade Added Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(addTradeFail(error));
    toast.error("Trade Added Failed", { autoClose: 3000 });
  }
}

function* onDeleteTrade({ payload: trade }) {
  try {
    const response = yield call(deleteTrade, trade);
    console.log('delete response', response);
    if( response.status == 204 ){
      console.log('trade', trade);
      yield put(deleteTradeSuccess({ trade }));
      toast.success("Trade Delete Successfully", { autoClose: 3000 });
    } else {
      yield put(deleteTradeFail(response));
      toast.error("Trade Delete Failed", { autoClose: 3000 });
    }
  } catch (error) {
    yield put(deleteTradeFail(error));
    toast.error("Trade Delete Failed", { autoClose: 3000 });
  }
}

function* onUpdateTrade({ payload }) {
  const trade = payload.data;
  try {
    const response = yield call(updateTrade, trade);
    if (payload.callback) {
      payload.callback();
    }
    yield put(updateTradeSuccess(response));
    toast.success("Trade Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(updateTradeFail(error));
    console.log('trade update error');
    console.log(error);
    toast.error("Trade Updated Failed ", { autoClose: 3000 });
  }
}

function* onBackFillCurrencyConversionValues({ payload }) {
  try {
    const response = yield call(backFillCurrencyConversionValuesApi, payload);

  } catch (error) {
    console.log('backfill currency conversion values error');
  }
}

export function* watchGetTradeList() {
  yield takeEvery(GET_TRADE_LIST, getTradeList);
}

export function* watchAddNewTrade() {
  yield takeEvery(ADD_NEW_TRADE, onAddNewTrade);
}

export function* watchUpdateTrade() {
  yield takeEvery(UPDATE_TRADE, onUpdateTrade);
}

export function* watchDeleteTrade() {
  yield takeEvery(DELETE_TRADE, onDeleteTrade);
}

export function* watchBackFillCurrencyConversionValues() {
  yield takeEvery(BACKFILL_CURRENCY_CONVERSION, onBackFillCurrencyConversionValues);
}

function* tradeSaga() {
  yield all([
    fork(watchGetTradeList),
    fork(watchAddNewTrade),
    fork(watchUpdateTrade),
    fork(watchDeleteTrade),
    fork(watchBackFillCurrencyConversionValues),
  ]);
}

export default tradeSaga;
