import React from 'react';
import {
  Container,
  CardHeader,
  Row,
  Card,
  CardBody, 
} from 'reactstrap'; 
 











const SupportersOnlyPublic = () => {


    
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
        
            <Row>
            <Card>
                <CardHeader className="align-items-center d-flex">
                <h4>Message to Non-Users</h4>
                </CardHeader>
                
                <CardBody className="p-3">
                    <p>
                    The Supporters-Only Data, as the name suggests, is only available to our supporters. 
                    </p>
                </CardBody>

                
                
                
                
                
                
                
                </Card>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default SupportersOnlyPublic;
