import { APIClient } from './api_helper';
import * as url from './url_helper';
import { AUTH_API, PROJECT_API } from './url_helper';

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem('user');
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postJwtRegister = (data) => {
  return api.create(url.AUTH_API.REGISTER, data).catch((err) => {
    console.log(err);
    var message;
    if (err && err.status) {
      switch (err.status) {
        case 404:
          message = 'Sorry! the page you are looking for could not be found';
          break;
        case 500:
          message =
            'Sorry! something went wrong, please contact our support team';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        case 400:
          message = 'Invalid credentials';
          if ('email' in err.data) {
            let [error] = err.data.email;
            message = error;
          }

          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
  });
};

// CHADAUTH
// Login Method
export const postJwtLogin = (data) =>
  api.create(url.AUTH_API.LOGIN, data).catch((error) => {
    let [message] = error?.data?.non_field_errors || [
      'Oops something went wrong',
    ];
    throw message;
  });

// Registration VerifyEmail Method
export const postVerifyEmail = (data) =>
  api.create(url.AUTH_API.VERIFY_EMAIL, data).catch((error) => {
    let [message] = error?.data?.non_field_errors || [
      'Oops something went wrong',
    ];
    throw message;
  });

// postForgetPwd
export const postJwtForgetPwd = (data) =>
  api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data) =>
  api.create(url.POST_EDIT_JWT_PROFILE, data);

// postSocialLogin
export const postSocialLogin = (data) => api.create(url.SOCIAL_LOGIN, data);

export const getAuthUser = () => api.get(url.AUTH_API.USER);

export const getCountries = (params) => {
  return api.get(url.AUTH_API.WORLD_COUNTRY, {
    limit: params?.limit || 10,
    offset: params?.skip || 0,
    search: params?.search || '',
  });
};

export const getMaxTradeDateAPI = (params) => {
  let obj = {
    respondent_id: params?.respondent_id || '',
  }
  return api.get(url.TRADE_API.GETMAXTRADEYEAR, obj);
};

// Trade Apps
// get Trade
export const getTradeList = (params) => {
  let obj = {
    limit: params?.limit || 10,
    offset: params?.offset || 0,
    respondent_id: params?.respondent_id || '',
    date_transaction: params?.date_transaction || '',
    buyer: params?.buyer || '',
    project_name: params?.project_name || '',
    project_code: params?.project_code || '',
    volume: params?.volume || '',
    value_usd: params?.value_usd || '',
    price_tonne: params?.price_tonne || '',
    orderby: params?.orderby || '',

    search: params?.search || '',
    value_usd__range: params?.value_usd__range || '',
    volume_tco2e__range: params?.volume_tco2e__range || '',
    price_usd_tcoe__range: params?.price_usd_tcoe__range || '',
  };
  //return await api.get(url.TRADE_API.TRADE, obj);
  return api.get(url.TRADE_API.GETTRADELIST, obj);
};

// add Trade
export const addNewTrade = async (trade) => { return await api.create(url.TRADE_API.TRADE, trade) };

// update Trade
export const updateTrade = (trade) =>
  api.update(`${url.TRADE_API.TRADE + trade.trade_id}/`, trade);

// delete Trade
export const deleteTrade = async (trade) => { 
  return await api.delete(`${url.TRADE_API.TRADE + trade.trade_id}/`);
};

// backfill currency conversion values
export const backFillCurrencyConversionValues = (params) => {
  return api.get(url.TRADE_API.BACKFILL_CURRENCY_CONVERSION, params);
};
  

// Buyer Apps
// get Buyer
export const getBuyerList = (params) => { 
  return api.get(url.TRADE_API.BUYER, {
    // limit: params?.limit || 10,
    offset: params?.skip || 0,
    search: params?.search || '',
    page: params?.page || 1,
    respondent_id: params?.respondent_id || '',
  });
};

// add Buyer
export const addNewBuyer = (buyer) => api.create(url.TRADE_API.BUYER, buyer);

// update Buyer
export const updateBuyer = async (buyer) => {
  return await api.update(`${url.TRADE_API.BUYER + buyer.buyer_id}/`, buyer);
};

// delete Buyer
export const deleteBuyer = async (buyer) => {
  return await api.delete(`${url.TRADE_API.BUYER + buyer.buyer_id}/`, buyer);
};

// Project Apps
// get Project
export const getProjectList = (params) =>
  api.get(url.TRADE_API.GETPROJECT, {
    limit: params?.limit || 20,
    offset: params?.skip || 0,
    projectSearch: params?.search || '',
    orderby: params?.orderby || '' 
  });

export const getProject = (id) => api.get(url.TRADE_API.PROJECT + id);

// get Type List
export const getTypeList = (params) =>
  api.get(url.TRADE_API.TYPE, {
    limit: params?.limit || 10,
    offset: params?.skip || 0,
    search: params?.search || '',
    page: params?.page || 1,
  });

// get Standard List
export const getStandardList = (params) =>
  api.get(url.PROJECT_API.STANDARD, {
    limit: params?.limit || 10,
    offset: params?.skip || 0,
    search: params?.search || '',
    page: params?.page || 1,
  });

// get CoBenefits List
export const getCoBenefitList = (params) =>
  api.get(url.PROJECT_API.COBENEFIT, {
    limit: params?.limit || 10,
    offset: params?.skip || 0,
    search: params?.search || '',
    page: params?.page || 1,
  });

// get SDG List
export const getSDGList = (params) =>
  api.get(url.PROJECT_API.SDG, {
    limit: params?.limit || 10,
    offset: params?.skip || 0,
    search: params?.search || '',
    page: params?.page || 1,
  });

  // get sector List
export const getSectorList = (params) =>
api.get(url.BUYER_API.SECTOR, {
  limit: params?.limit || 10,
  offset: params?.skip || 0,
  search: params?.search || '',
  page: params?.page || 1,
});

// add Project
export const addNewProject = async (project) => {
  return await api.create(url.TRADE_API.PROJECT, project);
}

// update Project
export const updateProject = (project) =>
  api.update(`${url.TRADE_API.PROJECT + project.projectid}/`, project);

// delete Project
export const deleteProject = (project) =>
  api.delete(`${url.TRADE_API.PROJECT + project.projectid}/`);

// get Type List Respondent
export const getRespondentList = (params) =>
  api.get(url.TRADE_API.RESPONDENT, {
    limit: params?.limit || 10,
    offset: params?.skip || 0,
    search: params?.search || '',
  });
// get Type List Respondent
export const getRespondentUsersList = (params) =>
  api.get(url.TRADE_API.RESPONDENT_USERS + params.id, {
    limit: params?.limit || 10,
    offset: params?.skip || 0,
    search: params?.search || '',
    page: params?.page || 1,
  });

export const inviteRespondent = (data) =>
  api.create(url.TRADE_API.INVITE_RESPONDENT, data);

export const respondentInviteValidate = (token) =>
  api.create(url.AUTH_API.RESPONDENT_INVITE_VALIDATE, { token });

export const respondentRegisterUser = (user) =>
  api.create(url.AUTH_API.RESPONDENT_REGISTER, user);
