import React, { useEffect, useState } from 'react';
import {
  Col,
  Container,
  CardHeader,
  Button,
  Row,
  Card,
  CardBody, 
  Alert, 
} from 'reactstrap'; 

import { logoutUser } from '../../store/actions';
 
import axios from 'axios';
import { api } from '../../config';
import { useDispatch, useSelector } from 'react-redux';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-P7N889SGDD');
ReactGA.send({ hitType: "pageview", page: "/data/data-dashboard", title: "Supporter Hub" });

 

const DashboardCrypto = () => {
  const [dashboardData, setDashboardData] = useState();
  const { user } = useSelector((state) => ({
    user: state.Login.user,
  }));
  const { Login } = useSelector((state) => state);

  const dispatch = useDispatch();

  
  if( ! (Login?.user?.respondent_has_special_data_access == true || Login?.user?.has_special_data_access == true) ){
    dispatch(logoutUser());
  } 

  const getDashboardData = async (respondent_id) => {
    try {
      const response = await axios.post(
        `${api?.API_URL}/api/respondent-dashboard/`,
        { respondent_id }
      );
      setDashboardData(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!user?.is_superuser) getDashboardData(user?.respondent_id);
  }, []);

  document.title = 'EM Data Intelligence Dashboard | Ecosystem Marketplace';
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
       
          <Row>
          <Card>
            <CardHeader className="align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">EM Data Intelligence Dashboard</h4>
              <div className="flex-shrink-0"></div>
            </CardHeader>
            <CardBody className="p-0">
       
            <iframe title="EM Data Intelligence Dashboard" 
                width="1667" 
                height="850" 
                src="https://app.powerbi.com/reportEmbed?reportId=a51d4b4b-347a-47a1-9cde-fbf268c61107&autoAuth=true&ctid=dc340a61-39bc-47e1-be05-2f0bd87190f4"
                frameBorder="0" 
                allowFullScreen="true">
                
            </iframe>
     
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardCrypto;
